import { Option } from '../types/common-types'

export const kzCity = [
  {
    value: 'АЛА',
    label: {
      eng: 'Almaty',
      rus: 'Алматы',
      kz: 'Алматы',
    },
  },
]
export const rfCity = [
  {
    value: 'СПБ',
    label: {
      eng: 'Saint Petersburg',
      rus: 'Санкт-Петербург',
      kz: 'Санкт-Петербург',
    },
  },
  {
    value: 'МСК',
    label: {
      eng: 'Moscow',
      rus: 'Москва',
      kz: 'Мәскеу',
    },
  },
]
