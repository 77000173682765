import React, { useState } from 'react'
import TextInput from '../../../../../components/Inputs/TextInput'
import DatePicker from '../../../../../components/Inputs/DatePicker'
import { UPLOADS_ENDPOINT } from '../../../../../server-parameters'
import { yearsAgo } from '../../../../../utils/helpers'
import {
  validateName,
  validateDate,
  passportDigits,
  passportText,
  smartValidateString,
} from '../../../../../validate/validators'
import { Country, ScreenProps } from '../../../../../types/common-types'
import { actions, RUSSIAN_PASSPORT } from '../../../../../state-actions-reducers'
import FileInput from '../../../../../components/Inputs/FileInput'

export default function UzbekistanForm({
  state,
  lang,
  invalidFields,
  dispatch,
  accountForm,
}: ScreenProps) {
  const [focus, setFocus] = useState('')
  return (
    <>
      <div className="col-2 col-xs-12">
        {/* PASSPORT SERIES / NUMBER */}
        <TextInput
          testName={'passportNumber'}
          testErrorName={'error-passportNumber'}
          disabled={!state.agreed}
          lang={lang}
          content={accountForm.passportNumber}
          onChange={passportNumber => {
            dispatch({
              type: actions.PASSPORT_NUMBER,
              data: passportNumber,
            })
          }}
          value={state.passportNumber}
          required
          valid={
            passportDigits(state.passportNumber) &&
            !invalidFields.includes('passportNumber')
          }
          onBlur={() => setFocus('')}
          mask={undefined}
        />
      </div>
      <div className="col-8 col-xs-12">
        {/* PASSPORT ISSUED BY */}
        <TextInput
          testName={'passportIssuedBy'}
          testErrorName={'error-passportIssuedBy'}
          disabled={!state.agreed}
          lang={lang}
          content={accountForm.issuedBy}
          onChange={passportIssuedBy =>
            dispatch({
              type: actions.PASSPORT_ISSUED_BY,
              data: passportIssuedBy.toLocaleUpperCase(),
            })
          }
          value={state.passportIssuedBy}
          required
          valid={
            state.passportIssuedBy.length > 0
              ? smartValidateString(state.passportIssuedBy) &&
                !invalidFields.includes('passportIssuedBy')
              : !invalidFields.includes('passportIssuedBy')
          }
          onBlur={() => setFocus('')}
        />
      </div>
      <div className="col-2 col-xs-12">
        {/* PASSPORT ISSUE DATE */}
        <DatePicker
          testName={'passportIAt'}
          testErrorName={'error-passportIAt'}
          disabled={!state.agreed}
          lang={lang}
          accountForm={accountForm}
          accountFormKey="passportIAt"
          onChange={data => dispatch({ type: actions.PASSPORT_IAT, data })}
          value={state.passportIAt}
          required
          valid={!invalidFields.includes('passportIAt')}
          onBlur={() => setFocus('')}
          curr={
            state.birthDate
              ? new Date(
                  new Date(state.birthDate).setFullYear(
                    new Date(state.birthDate).getFullYear() + 14
                  )
                )
              : new Date()
          }
          min={
            state.birthDate
              ? new Date(
                  new Date(state.birthDate).setFullYear(
                    new Date(state.birthDate).getFullYear() + 14
                  )
                )
              : new Date(new Date().setFullYear(new Date().getFullYear() - 67))
          }
          max={new Date()}
        />
      </div>
      <div className="col-2 col-xs-12">
        {/* PASSPORT EXPIRY DATE */}
        <DatePicker
          testName={'passportExpiryDate'}
          testErrorName={'error-passportExpiryDate'}
          disabled={!state.agreed}
          lang={lang}
          accountForm={accountForm}
          accountFormKey="passportExpiryDate"
          onChange={data =>
            dispatch({ type: actions.PASSPORT_EXPIRY_DATE, data })
          }
          value={state.passportExpiryDate}
          required
          valid={!invalidFields.includes('passportExpiryDate')}
          onBlur={() => setFocus('')}
          min={new Date()}
          max={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
        />
      </div>
      <div className="col-4 col-xs-12">
        <FileInput
          testName={'F_Scan_Directors_Passport'}
          testErrorName={'error-F_Scan_Directors_Passport'}
          disabled={!state.agreed}
          lang={lang}
          url={UPLOADS_ENDPOINT}
          autorization={state.formId}
          target={state.documentsImages}
          content={accountForm.mainImageFile}
          onChange={data => {
            dispatch({
              type: actions.F_SCAN_DIRECTORS_PASSPORT,
              data: data,
            })
          }}
          value={
            state.F_Scan_Directors_Passport[
              state.F_Scan_Directors_Passport.length - 1
            ]
          }
          valid={!invalidFields.includes('F_Scan_Directors_Passport')}
          documentType="Passport1"
        />
      </div>
      <div className="col-4 col-xs-12">
        <FileInput
          testName={'F_Scan_Directors_Passport_Address'}
          testErrorName={'error-F_Scan_Directors_Passport_Address'}
          disabled={!state.agreed}
          lang={lang}
          url={UPLOADS_ENDPOINT}
          autorization={state.formId}
          target={state.documentsImages}
          content={
            selectedCitizenship === 'Таджикистан' ||
            selectedCitizenship === 'Узбекистан'
              ? accountForm.addressImageFile
              : accountForm.vizaImageFile
          }
          onChange={data => {
            dispatch({
              type: actions.F_SCAN_DIRECTORS_PASSPORT_ADDRESS,
              data: data,
            })
          }}
          value={
            state.F_Scan_Directors_Passport_Address[
              state.F_Scan_Directors_Passport_Address.length - 1
            ]
          }
          valid={!invalidFields.includes('F_Scan_Directors_Passport_Address')}
          documentType="Passport2"
        />
      </div>
    </>
  )
}
