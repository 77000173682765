import React, { useState } from 'react'
import TextInput from '../../../../components/Inputs/TextInput'
import DatePicker from '../../../../components/Inputs/DatePicker'
import { UPLOADS_ENDPOINT } from '../../../../server-parameters'
import { yearsAgo } from '../../../../utils/helpers'
import {
  validateName,
  validateDate,
  passportDigits,
} from '../../../../validate/validators'
import { Country, ScreenProps } from '../../../../types/common-types'
import { actions } from '../../../../state-actions-reducers'
import FileInput from '../../../../components/Inputs/FileInput'
import { getPassportTypeTranslate } from '../../../../hooks/useGetPassportTypeTranslate'

export default function RomanianForm({
  state,
  lang,
  invalidFields,
  dispatch,
  accountForm,
}: ScreenProps) {
  const [focus, setFocus] = useState('')
  return (
    <>
      {getPassportTypeTranslate(+state.passportType) === 'Загран. паспорт' ? (
        <>
          <div className="col-3 col-xs-12">
            {/* PASSPORT INTERNETIONAL NUMBER */}
            <TextInput
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.passportInternationalNumber}
              onChange={passportInternationalNumber => {
                dispatch({
                  type: actions.PASSPORT_INTERNATIONAL_NUMBER,
                  data: passportInternationalNumber,
                })
              }}
              value={state.passportInternationalNumber}
              required
              valid={
                passportDigits(state.passportInternationalNumber) &&
                !invalidFields.includes('passportInternationalNumber')
              }
              onBlur={() => setFocus('')}
              mask={'99 9999999'}
            />
          </div>
          <div className="col-2 col-xs-12">
            {/* PASSPORT EXPIRY DATE */}
            <DatePicker
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportExpiryDate"
              onChange={data =>
                dispatch({ type: actions.PASSPORT_EXPIRY_DATE, data })
              }
              value={state.passportExpiryDate}
              required
              valid={
                !invalidFields.includes('passportExpiryDate') &&
                validateDate({
                  current: state.passportExpiryDate,
                  min: new Date(),
                  max: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 10)
                  ),
                })
              }
              onBlur={() => setFocus('')}
              min={new Date()}
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() + 10))
              }
            />
          </div>
          <div className="col-3 col-xs-12">
            {/* PASSPORT DATE BOARDING*/}
            <DatePicker
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportDateBoardCrossing"
              onChange={data =>
                dispatch({
                  type: actions.PASSPORT_DATE_BOARD_CROSSING,
                  data,
                })
              }
              value={state.passportDateBoardCrossing}
              required
              valid={
                !invalidFields.includes('passportDateBoardCrossing') &&
                validateDate({
                  current: state.passportDateBoardCrossing,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              max={new Date()}
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.passportImages}
              content={accountForm.internationalFile}
              onChange={mainImageFile => {
                dispatch({
                  type: actions.MAIN_IMAGE_FILE,
                  data: mainImageFile,
                })
              }}
              valid={!invalidFields.includes('mainImageFile')}
              value={state.mainImageFile[state.mainImageFile.length - 1]}
              documentType="Passport1"
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.passportImages}
              content={accountForm.vizaImageFile}
              onChange={addressImageFile => {
                dispatch({
                  type: actions.ADDRESS_IMAGE_FILE,
                  data: addressImageFile,
                })
              }}
              value={state.addressImageFile[state.addressImageFile.length - 1]}
              valid={!invalidFields.includes('addressImageFile')}
              documentType="Passport2"
            />
          </div>
        </>
      ) : getPassportTypeTranslate(+state.passportType) ===
        'Вид на жительство' ? (
        <>
          <div className="col-2 col-xs-12">
            {/* PASSPORT PERMANENT RESIDENCY DATE */}
            <DatePicker
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportDateIssuePermanentResidency"
              onChange={data =>
                dispatch({
                  type: actions.PASSPORT_DATE_ISSUE_PERMANENT_RESIDENCY,
                  data,
                })
              }
              value={state.passportDateIssuePermanentResidency}
              required
              valid={
                !invalidFields.includes(
                  'passportDateIssuePermanentResidency'
                ) &&
                validateDate({
                  current: state.passportDateIssuePermanentResidency,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              min={
                state.birthDate
                  ? new Date(
                      new Date(state.birthDate).setFullYear(
                        new Date(state.birthDate).getFullYear()
                      )
                    )
                  : new Date(
                      new Date().setFullYear(new Date().getFullYear() - 67)
                    )
              }
              max={new Date()}
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.passportImages}
              content={accountForm.residenceFile}
              onChange={residenceImageFile => {
                dispatch({
                  type: actions.RESIDENCE_IMAGE_FILE,
                  data: residenceImageFile,
                })
              }}
              value={
                state.residenceImageFile[state.residenceImageFile.length - 1]
              }
              valid={!invalidFields.includes('residenceImageFile')}
              documentType="Passport1"
            />
          </div>
          <div className="col-2 col-xs-12">
            {/* PASSPORT_PERSON_NUMBER */}
            <TextInput
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.passportIinNumber}
              onChange={passportPersonNumber => {
                dispatch({
                  type: actions.PASSPORT_PERSON_NUMBER,
                  data: passportPersonNumber,
                })
              }}
              value={state.passportPersonNumber}
              required
              valid={!invalidFields.includes('passportPersonNumber')}
              onBlur={() => setFocus('')}
              mask={'999999999999'}
            />
          </div>
        </>
      ) : null}
    </>
  )
}
