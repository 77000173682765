import React, { useRef, useState, useEffect, useMemo } from 'react'
import useDebounce from '../../../../utils/useDebounce'
import TextInput from '../../../../components/Inputs/TextInput'
import SelectInput from '../../../../components/Inputs/SelectInput'
import FileInput from '../../../../components/Inputs/FileInput'
import { UPLOADS_ENDPOINT } from '../../../../server-parameters'
import { yearsAgo } from '../../../../utils/helpers'
import {
  validateName,
  stringDigitsLength,
  validateString,
  validateDate,
  smartValidateString,
} from '../../../../validate/validators'
import { Country, ScreenProps } from '../../../../types/common-types'
import { actions } from '../../../../state-actions-reducers'
import warningImage from '../../../../images/warning.svg'
import DatePicker from '../../../../components/Inputs/DatePicker'
import { checkPhoneEmail } from '../../../../hooks/useCheckPhoneEmailCustomHook'
import styles from '../../FiscalScreenOne.module.scss'
import RussianForm from '../KzInstance/Citizenships/RussianForm'
import KyrgyzstanForm from '../KzInstance/Citizenships/KyrgyzstanForm'
import RomanianForm from '../KzInstance/Citizenships/RomanianForm'
import ChinaForm from '../KzInstance/Citizenships/ChinaForm'
import KazakhstanForm from '../KzInstance/Citizenships/KazakhstanForm'
import BelarusForm from '../KzInstance/Citizenships/BelarusForm'
import UzbekistanForm from '../KzInstance/Citizenships/UzbekistanForm'
import VietnamForm from '../KzInstance/Citizenships/VietnamForm'
import AddressInputs from '../../AddressInputs/AddressInputs'
import {
  passportTypeKz,
  passportTypeRfForKz,
  passportTypeRom,
  DocumentTypeValuesUdvAbroad,
  DocumentTypeValuesTypePersonalities,
  DocumentTypeValuesTypePassport,
} from '../../../../content/passportType'

interface Props extends ScreenProps {
  citizenshipCountriesList: Country[]
  allFiscalCountry: Country[]
  addressCountries: Country[]
  countriesList: Country[]
  regionCity: any
  regionCityRegistered: any
  regionCityFactual: any
}

/* REACT COMPONENT */
function IpScreenOne({
  lang,
  state,
  dispatch,
  accountForm,
  citizenshipCountriesList,
  allFiscalCountry,
  addressCountries,
  invalidFields,
  regionCityRegistered,
  regionCityFactual,
}: Props) {
  
  const getCitizenshipFormat = (citizenship: string) => {
    return citizenshipCountriesList.find((country: Country) => {
      return country.value === citizenship
    })?.label?.rus
  }
  const selectedCitizenship = useMemo(() => {
    return getCitizenshipFormat(state.citizenship)
  }, [state.citizenship])

  const [focus, setFocus] = useState('')

  const debouncedPhone = useDebounce(state.mobilePhoneNumbers[0], 1500)
  const debouncedMail = useDebounce(state.emails[0], 1500)

  async function phoneEmailMessage() {
    let response = await checkPhoneEmail(state, debouncedPhone, debouncedMail)
    if (response.message !== 'Invalid token') {
      dispatch({ type: actions.IS_PHONE_OK, data: response.isPhoneOk })
      dispatch({ type: actions.IS_EMAIL_OK, data: response.isEmailOk })
    } else {
      alert('Произошла ошибка, перезагрузите странницу!')
    }
  }
  useEffect(() => {
    if (debouncedPhone || debouncedMail) phoneEmailMessage()
  }, [debouncedPhone, debouncedMail])

  useEffect(() => {
    dispatch({
      type: actions.F_FiscalType,
      data: '1',
    })
  }, [state.countryRegistration])

  useEffect(() => {
    if (state.passportType.length === 0)
      dispatch({
        type: actions.PASSPORT_TYPE,
        data:
          selectedCitizenship === 'Казахстан'
            ? passportTypeKz[0].value
            : selectedCitizenship === 'Румыния'
            ? DocumentTypeValuesTypePersonalities[0].value
            : selectedCitizenship === 'Россия' ||
              selectedCitizenship === 'Кыргызстан'
            ? DocumentTypeValuesTypePassport[0].value
            : DocumentTypeValuesTypePassport[0].value,
      })
  }, [selectedCitizenship, state.F_FiscalType])

  return (
    <>
      <div>
        <div className="form-group">
          <div className="row">
            <div className="col-2 col-xs-12">
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.F_INN}
                onChange={F_INN => {
                  dispatch({
                    type: actions.F_INN,
                    data: F_INN.replace(/\D/gim, '').substr(0, 12),
                  })
                }}
                // onChange={F_INN =>
                //   dispatch({ type: actions.F_INN, data: F_INN })
                // }
                value={state.F_INN || ''}
                required
                valid={
                  stringDigitsLength(state.F_INN, 12, false) &&
                  !invalidFields.includes('F_INN')
                }
                mask={'999999999999'}
              />
            </div>
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_INN_Image}
                onChange={F_INN_Image => {
                  dispatch({
                    type: actions.F_INN_IMAGE_FILE,
                    data: F_INN_Image,
                  })
                }}
                value={state.F_INN_Image[state.F_INN_Image.length - 1]}
                valid={!invalidFields.includes('F_INN_Image')}
                documentType="INN"
              />
            </div>
            <div className="col-2 col-xs-12">
              {/* F_OGRN */}
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.F_OGRNIP}
                onChange={F_OGRN => {
                  dispatch({
                    type: actions.F_OGRN,
                    data: F_OGRN.replace(/\D/gim, '').substr(0, 15),
                  })
                }}
                // onChange={F_OGRN =>
                //   dispatch({ type: actions.F_OGRN, data: F_OGRN })
                // }
                value={state.F_OGRN || ''}
                valid={
                  stringDigitsLength(state.F_OGRN, 15, false) &&
                  !invalidFields.includes('F_OGRN')
                }
                mask={'999999999999999'}
              />
            </div>
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_OGRNIP_Image}
                onChange={F_OGRN_Image => {
                  dispatch({
                    type: actions.F_OGRN_IMAGE_FILE,
                    data: F_OGRN_Image,
                  })
                }}
                value={state.F_OGRN_Image[state.F_OGRN_Image.length - 1]}
                valid={!invalidFields.includes('F_OGRN_Image')}
                documentType="OGRN"
              />
            </div>
            <div className="col-2 col-xs-12">
              <DatePicker
                lang={lang}
                accountForm={accountForm}
                accountFormKey="registeredDate"
                onChange={data => {
                  dispatch({
                    type: actions.REGISTERED_DATE,
                    data,
                  })
                }}
                value={state.registeredDate}
                focus={focus === 'registeredDate' || undefined}
                onBlur={() => setFocus('')}
                valid={!invalidFields.includes('registeredDate')}
                required
              />
            </div>
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_EGRIP_Image}
                onChange={F_EGRUL_Image => {
                  dispatch({
                    type: actions.F_EGRUL_IMAGE_FILE,
                    data: F_EGRUL_Image,
                  })
                }}
                value={state.F_EGRUL_Image[state.F_EGRUL_Image.length - 1]}
                valid={!invalidFields.includes('F_EGRUL_Image')}
                documentType="EGRYUL"
              />
            </div>
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_Rent_Contract_Image}
                onChange={F_Rent_Contract_Image => {
                  dispatch({
                    type: actions.F_RENT_CONTRACT_FILE,
                    data: F_Rent_Contract_Image,
                  })
                }}
                value={
                  state.F_Rent_Contract_Image[
                    state.F_Rent_Contract_Image.length - 1
                  ]
                }
                valid={!invalidFields.includes('F_Rent_Contract_Image')}
                documentType="Lease"
              />
            </div>
            <div className={styles.inputBox + ' ' + 'col-2 col-xs-12'}>
              <div>
                <input
                  id="hideContractInput"
                  name="hideContractInput"
                  type="checkBox"
                  onChange={() =>
                    dispatch({
                      type: actions.NO_LEASE_AGREEMENT,
                      data: !state.noLeaseAgreement,
                    })
                  }
                  value={false}
                />
                <label htmlFor="hideContractInput">
                  {accountForm.noRentContractBtnDesc[lang]}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3>{accountForm.stepOneIdentification[lang]}</h3>
            </div>
            <div className="col-4 col-xs-12">
              {/* LAST NAME */}
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.lastName}
                onChange={headLastname =>
                  dispatch({ type: actions.HEAD_LASTNAME, data: headLastname })
                }
                value={state.headLastname || ''}
                required
                valid={
                  state.headLastname.length > 0
                    ? smartValidateString(state.headLastname) &&
                      !invalidFields.includes('headLastname')
                    : !invalidFields.includes('headLastname')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* FIRST NAME */}
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.firstName}
                onChange={headFirstname =>
                  dispatch({
                    type: actions.HEAD_FIRSTNAME,
                    data: headFirstname,
                  })
                }
                value={state.headFirstname || ''}
                required
                valid={
                  state.headFirstname.length > 0
                    ? smartValidateString(state.headFirstname) &&
                      !invalidFields.includes('headFirstname')
                    : !invalidFields.includes('headFirstname')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* PATRONYMIC */}
              <TextInput
                disabled={!state.agreed || state.noPatronymic}
                lang={lang}
                content={accountForm.patronymic}
                onChange={headPatronymic =>
                  dispatch({
                    type: actions.HEAD_PATRONYMIC,
                    data: headPatronymic,
                  })
                }
                checkLabel={accountForm.noPatronymic[lang]}
                checkValue={state.noPatronymic}
                checkChange={() => {
                  dispatch({
                    type: actions.NO_PATRONYMIC,
                    data: !state.noPatronymic,
                  })
                  dispatch({
                    type: actions.HEAD_PATRONYMIC,
                    data: '',
                  })
                }}
                required
                value={state.headPatronymic || ''}
                valid={
                  state.headPatronymic.length > 0
                    ? smartValidateString(state.headPatronymic) &&
                      !invalidFields.includes('headPatronymic')
                    : !invalidFields.includes('headPatronymic')
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-xs-12">
              {/* CITIZENSHIP */}
              <SelectInput
                testName={'citizenship'}
                testErrorName={'error-citizenship'}
                noOptionsMessage={accountForm.citizenshipInput.placeholder}
                lang={lang}
                disabled={!state.agreed}
                content={accountForm.citizenshipInput}
                options={citizenshipCountriesList}
                onChange={citizenship => {
                  dispatch({
                    type: actions.CITIZENSHIP,
                    data: citizenship,
                  })
                  dispatch({
                    type: actions.PASSPORT_TYPE,
                    data:
                      getCitizenshipFormat(citizenship) === 'Казахстан'
                        ? passportTypeKz[0].value
                        : getCitizenshipFormat(citizenship) === 'Румыния'
                        ? DocumentTypeValuesTypePersonalities[0].value
                        : getCitizenshipFormat(citizenship) === 'Россия' ||
                          getCitizenshipFormat(citizenship) === 'Кыргызстан'
                        ? DocumentTypeValuesTypePassport[0].value
                        : DocumentTypeValuesTypePassport[0].value,
                  })
                }}
                valid={!invalidFields.includes('citizenship')}
                value={state.citizenship}
                required
                prefix="Citizenships"
              />
            </div>
            <div className="col-3 col-xs-12">
              {/* BIRTH DATE */}
              <DatePicker
                disabled={!state.agreed}
                lang={lang}
                accountForm={accountForm}
                accountFormKey="birthDate"
                onChange={birthDate =>
                  dispatch({ type: actions.BIRTH_DATE, data: birthDate })
                }
                value={state.birthDate}
                valid={
                  !invalidFields.includes('birthDate') &&
                  validateDate({
                    current: state.birthDate,
                    min: yearsAgo(130),
                    max: yearsAgo(14),
                  })
                }
                curr={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                }
                min={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 85)
                  )
                }
                max={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                }
                required
              />
            </div>
            <div className="col-5 col-xs-12">
              {/* BIRTH PLACE */}
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.birthPlace}
                onChange={birthPlace => {
                  dispatch({
                    type: actions.BIRTH_PLACE,
                    data: birthPlace,
                  })
                }}
                value={state.birthPlace}
                required
                valid={
                  state.birthPlace.length > 0
                    ? smartValidateString(state.birthPlace) &&
                      !invalidFields.includes('birthPlace')
                    : !invalidFields.includes('birthPlace')
                }
                onBlur={() => setFocus('')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 col-xs-12">
              <SelectInput
                testName={'passportType'}
                testErrorName={'error-passportType'}
                lang={lang}
                noOptionsMessage={accountForm.passportTypeInput.placeholder}
                options={
                  selectedCitizenship === 'Казахстан'
                    ? passportTypeKz
                    : selectedCitizenship === 'Румыния'
                    ? passportTypeRom
                    : selectedCitizenship === 'Россия' ||
                      selectedCitizenship === 'Кыргызстан'
                    ? DocumentTypeValuesTypePassport
                    : DocumentTypeValuesTypePassport
                }
                content={accountForm.passportTypeInput}
                onChange={data => {
                  dispatch({
                    type: actions.PASSPORT_TYPE,
                    data,
                  })
                }}
                value={state.passportType}
                valid={
                  !invalidFields.includes('passportType') &&
                  validateString(state.passportType, 300, true)
                }
                required
              />
            </div>
            {selectedCitizenship === 'Россия' ? (
              <RussianForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Китай' ? (
              <ChinaForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Казахстан' ? (
              <KazakhstanForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Кыргызстан' ? (
              <KyrgyzstanForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Румыния' ? (
              <RomanianForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Бразилия' ||
            selectedCitizenship === 'Италия' ||
            selectedCitizenship === 'Беларусь' ? (
              <BelarusForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Австралия' ||
            selectedCitizenship === 'Азербайджан' ||
            selectedCitizenship === 'Армения' ||
            selectedCitizenship === 'Соединенное Королевство' ||
            selectedCitizenship === 'Германия' ||
            selectedCitizenship === 'Испания' ||
            selectedCitizenship === 'Сирийская Арабская Республика' ||
            selectedCitizenship === 'Соединенные Штаты' ||
            selectedCitizenship === 'Таджикистан' ||
            selectedCitizenship === 'Узбекистан' ||
            selectedCitizenship === 'Франция' ||
            selectedCitizenship === 'Швейцария' ? (
              <UzbekistanForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Болгария' ||
            selectedCitizenship === 'Израиль' ||
            selectedCitizenship === 'Исландия' ||
            selectedCitizenship === 'Молдова, Республика' ||
            selectedCitizenship === 'Нидерланды' ||
            selectedCitizenship === 'Норвегия' ||
            selectedCitizenship === 'Швеция' ||
            selectedCitizenship === 'Южная Африка' ||
            selectedCitizenship === 'Вьетнам' ||
            selectedCitizenship === 'Грузия' ||
            selectedCitizenship === 'Индонезия' ||
            selectedCitizenship === 'Индия' ||
            selectedCitizenship === 'Корея, Республика' ||
            selectedCitizenship === 'Финляндия' ? (
              <VietnamForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            <div className="col-12 col-xs-12">
              <p className={styles.warningMessage}>
                <img src={warningImage} alt="warningImage"></img>
                {accountForm.Fiscal_Warning_Scan_Directors_Passport_KZT[lang]}
              </p>
            </div>
          </div>
        </div>
        <AddressInputs
          lang={lang}
          initialState={state}
          state={state}
          dispatch={dispatch}
          accountForm={accountForm}
          invalidFields={invalidFields}
          addressCountries={addressCountries}
          regionCityRegistered={regionCityRegistered}
          regionCityFactual={regionCityFactual}
        />
      </div>
    </>
  )
}

export default IpScreenOne
