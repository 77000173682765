import React, { useState, useReducer, useEffect, useMemo } from 'react'
import { navigate } from 'gatsby-link'
import TopSide from './Head/TopSide'
import accountForm from '../content/account-form'
import {
  citizenshipsKz,
  citizenshipsRf,
  allFiscalCountriesKz,
  allFiscalCountriesRf,
  allFiscalCitizenshipCountries,
  addressCountries,
  addressFiscalCountriesKz,
  addressFiscalCountriesRf,
} from '../content/countries'
import RegionCityList from '../content/region-city'
import RegionCityListEn from '../content/region-city_en'
import RegionCityListKz from '../content/region-city_kz'
import KzRegionCityList from '../content/kz_region-city'
import KzRegionCityListEn from '../content/kz_region-city_eng'
import KzRegionCityListKz from '../content/kz_region-city_kz'
import AcceptConditions from './AcceptConditions'
import HollowButton from './Buttons/HollowButton'
import BlueButton from './Buttons/BlueButton'
import RedButton from './Buttons/RedButton'
import BlackButton from './Buttons/BlackButton'
import ScreenOneRus from '../Layouts/Physical/RusInstance/ScreenOne'
import ScreenOneKzt from '../Layouts/Physical/KztInstance/ScreenOne'
import ScreenTwo from '../Layouts/Physical/ScreenTwo'
import ScreenThree from '../Layouts/Physical/ScreenThree'
import ScreenThreeCart from '../Layouts/Physical/ScreenThreeCart'
import ScreenFour from '../Layouts/Physical/ScreenFour'

import FiscalScreenOne from '../Layouts/Fiscal/FiscalScreenOne'
import FiscalScreenTwo from '../Layouts/Fiscal/FiscalScreenTwo'
import FiscalScreenThree from '../Layouts/Fiscal/FiscalScreenThree'
import FiscalScreenFour from '../Layouts/Fiscal/FiscalScreenFour'

import ConfirmRemember from '../Layouts/ConfirmRemember'

import {
  getInitialState,
  actions,
  reducer,
  INITIAL_STATE,
} from '../state-actions-reducers'
import { saveState, resetLocalStorage, saveCart } from '../local-storage'
import { iframeResizer } from 'iframe-resizer'
import { useDefer } from '../utils/helpers'
import { validateStep } from '../validate/validators'
import { useToken, getUserAndFormId } from '../hooks/useTokenCustomHook'
import { SUBMIT_SUCCESS } from '../server-parameters'

import { CODE_CONFIRM_ENABLED, COUNTRY } from '../server-parameters'

import {
  profileType_Physical,
  profileType_Fiscal,
} from '../state-actions-reducers'

import styles from './IndexPresentationComponent.module.scss'
import BackButtonImg from '../images/black-arrow-left.svg'
import { useQueryContext } from '../pages'

//const customstyles = require('./customstyles.css')
//import * as customstyles from './customstyles.css'
import './customstyles.css'

/* REACT COMPONENT */
export default function Index() {
  const query = useQueryContext()
  const [searchParams, setSearchParams] = useState(new URLSearchParams())
  useEffect(() => {
    setSearchParams(new URLSearchParams(query))
  }, [query])
  const [lang, setLang] = useState('rus')
  const [cartList, setCartList] = useState('[]')
  const confurmButton = accountForm.agreementButtonConfurm[lang]
  const [nextStep, setNextStep] = useState(false)
  // Set state
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const TOTAL_STEPS = useMemo(()=> {
    console.log('state.profileType === profileType_Fiscal', state.profileType === profileType_Fiscal)
    return state.profileType === profileType_Fiscal ? 
      lang === 'rus' ? 5 : 4
    : lang === 'rus' ? 4 : 3
  }, [state.profileType])

  const allCitizenshipsSort = useMemo(() => {
    if (COUNTRY === 'kz')
      return citizenshipsKz
        .sort((a: any, b: any) => {
          if (a.label[lang].toLowerCase() < b.label[lang].toLowerCase()) {
            return -1
          }
          if (a.label[lang].toLowerCase() > b.label[lang].toLowerCase()) {
            return 1
          }
          return 0
        })
        .sort((x: any, y: any) =>
          ['398', '643'].includes(x.value.toString()) ? -1 : 1
        )
        .sort((x: any, y: any) => {
          return x.value.toString() === '398'
            ? -1
            : y.value.toString() == '398'
            ? 1
            : 0
        })
    else {
      return citizenshipsRf
        .sort((a: any, b: any) => {
          if (a.label[lang].toLowerCase() < b.label[lang].toLowerCase()) {
            return -1
          }
          if (a.label[lang].toLowerCase() > b.label[lang].toLowerCase()) {
            return 1
          }
          return 0
        })
        .sort((x: any, y: any) =>
          ['112', '398', '643'].includes(x.value.toString()) ? -1 : 1
        )
        .sort((x: any, y: any) => {
          return x.value.toString() === '643'
            ? -1
            : y.value.toString() == '643'
            ? 1
            : 0
        })
    }
  }, [COUNTRY])

  const allFiscalSort = useMemo(() => {
    if (COUNTRY === 'kz') {
      return allFiscalCountriesKz
        .sort((a: any, b: any) => {
          if (a.label[lang].toLowerCase() < b.label[lang].toLowerCase()) {
            return -1
          }
          if (a.label[lang].toLowerCase() > b.label[lang].toLowerCase()) {
            return 1
          }
          return 0
        })
        .sort((x: any, y: any) =>
          ['398', '643'].includes(x.value.toString()) ? -1 : 1
        )
        .sort((x: any, y: any) => {
          return x.value.toString() === '398'
            ? -1
            : y.value.toString() == '398'
            ? 1
            : 0
        })
    } else {
      return allFiscalCountriesRf
    }
  }, [COUNTRY])

  const allCitizenshipsFiscalSort = useMemo(() => {
    if (COUNTRY === 'kz') {
      return allFiscalCitizenshipCountries
        .sort((a: any, b: any) => {
          if (a.label[lang].toLowerCase() < b.label[lang].toLowerCase()) {
            return -1
          }
          if (a.label[lang].toLowerCase() > b.label[lang].toLowerCase()) {
            return 1
          }
          return 0
        })
        .sort((x: any, y: any) =>
          ['398', '643'].includes(x.value.toString()) ? -1 : 1
        )
        .sort((x: any, y: any) => {
          return x.value.toString() === '398'
            ? -1
            : y.value.toString() == '398'
            ? 1
            : 0
        })
    } else {
      return allFiscalCitizenshipCountries
        .sort((a: any, b: any) => {
          if (a.label[lang].toLowerCase() < b.label[lang].toLowerCase()) {
            return -1
          }
          if (a.label[lang].toLowerCase() > b.label[lang].toLowerCase()) {
            return 1
          }
          return 0
        })
        .sort((x: any, y: any) =>
          ['112', '398', '643'].includes(x.value.toString()) ? -1 : 1
        )
        .sort((x: any, y: any) => {
          return x.value.toString() === '643'
            ? -1
            : y.value.toString() == '643'
            ? 1
            : 0
        })
    }
  }, [COUNTRY])

  const allCitizenshipsAddressSort = useMemo(() => {
    if (COUNTRY === 'kz') {
      return addressCountries
        .sort((a: any, b: any) => {
          if (a.label[lang].toLowerCase() < b.label[lang].toLowerCase()) {
            return -1
          }
          if (a.label[lang].toLowerCase() > b.label[lang].toLowerCase()) {
            return 1
          }
          return 0
        })
        .sort((x: any, y: any) =>
          ['398', '643'].includes(x.value.toString()) ? -1 : 1
        )
        .sort((x: any, y: any) => {
          return x.value.toString() === '398'
            ? -1
            : y.value.toString() == '398'
            ? 1
            : 0
        })
    } else {
      return addressCountries
        .sort((a: any, b: any) => {
          if (a.label[lang].toLowerCase() < b.label[lang].toLowerCase()) {
            return -1
          }
          if (a.label[lang].toLowerCase() > b.label[lang].toLowerCase()) {
            return 1
          }
          return 0
        })
        .sort((x: any, y: any) =>
          ['112', '398', '643'].includes(x.value.toString()) ? -1 : 1
        )
        .sort((x: any, y: any) => {
          return x.value.toString() === '643'
            ? -1
            : y.value.toString() == '643'
            ? 1
            : 0
        })
    }
  }, [COUNTRY])

  const allCitizenshipsFiscalAddressSort = useMemo(() => {
    if (COUNTRY === 'kz') {
      return addressFiscalCountriesKz
        .sort((a: any, b: any) => {
          if (a.label[lang].toLowerCase() < b.label[lang].toLowerCase()) {
            return -1
          }
          if (a.label[lang].toLowerCase() > b.label[lang].toLowerCase()) {
            return 1
          }
          return 0
        })
        .sort((x: any, y: any) =>
          ['398', '643'].includes(x.value.toString()) ? -1 : 1
        )
        .sort((x: any, y: any) => {
          return x.value.toString() === '398'
            ? -1
            : y.value.toString() == '398'
            ? 1
            : 0
        })
    } else {
      return addressFiscalCountriesRf
    }
  }, [COUNTRY])

  useEffect(() => {
    if (searchParams.get('lang') === 'eng') {
      setLang('eng')
      dispatch({ type: actions.LOCALE, data: 'EN' })
    } else if (searchParams.get('lang') === 'kz') {
      setLang('kz')
      dispatch({ type: actions.LOCALE, data: 'KZ' })
    }
  }, [searchParams])


  const RegionCityListLocalesRegistered = useMemo(() => {
    return searchParams.get('lang') === 'kz'
      ? state.addressRegisteredCountry === '643'
        ? RegionCityListKz
        : state.addressRegisteredCountry === '398'
        ? KzRegionCityListKz
        : []
      : searchParams.get('lang') === 'eng'
      ? state.addressRegisteredCountry === '643'
        ? RegionCityListEn
        : state.addressRegisteredCountry === '398'
        ? KzRegionCityListEn
        : []
      : state.addressRegisteredCountry === '643'
      ? RegionCityList
      : state.addressRegisteredCountry === '398'
      ? KzRegionCityList
      : []
  }, [searchParams.get('lang'), state.addressRegisteredCountry])
  const RegionCityListLocalesFactual = useMemo(() => {
    return searchParams.get('lang') === 'kz'
      ? state.addressFactualCountry === '643'
        ? RegionCityListKz
        : state.addressFactualCountry === '398'
        ? KzRegionCityListKz
        : []
      : searchParams.get('lang') === 'eng'
      ? state.addressFactualCountry === '643'
        ? RegionCityListEn
        : state.addressFactualCountry === '398'
        ? KzRegionCityListEn
        : []
      : state.addressFactualCountry === '643'
      ? RegionCityList
      : state.addressFactualCountry === '398'
      ? KzRegionCityList
      : []
  }, [searchParams.get('lang'), state.addressFactualCountry])
  const [initialState, setInitialState] = useState(state)
  // Try to load saved state
  useEffect(() => {
    const loadedState = getInitialState(window.localStorage)
    setInitialState(initialState)
    dispatch({
      type: actions.REPLACE,
      data: loadedState,
    })

    const params = new URLSearchParams(location.search)
    var _step = params.get('step') ? +('0' + params.get('step')) : 0
    if (!_step || !loadedState.agreed || _step > TOTAL_STEPS) _step = 0
    if (_step !== loadedState.currentStep)
      dispatch({
        type: actions.CURRENT_STEP,
        data: _step,
      })
  }, [initialState])

  // On mount do not show 0 step if user agreed
  useEffect(() => {
    if (state.currentStep === 0 && state.agreed) {
      dispatch({
        type: actions.CURRENT_STEP,
        data: 1,
      })
    }
  }, [state.currentStep, state.agreed])

  // Set userId and token
  useToken(({ userId, formId, orderId }) => {
    dispatch({
      type: actions.USER_ID,
      data: userId,
    })
    dispatch({
      type: actions.FORM_ID,
      data: formId,
    })
    dispatch({
      type: actions.ORDER_ID,
      data: orderId,
    })
  }, [])

  // Save state to localStorage on every change
  useDefer(() => saveState(state, localStorage), [state])

  // When trying to change screen, make sure all fields is valid
  const [invalidFields, setInvalidFields] = useState<string[]>([])
  const [strictValidation, setStrictValidation] = useState(false)

  useEffect(() => {
    // Turn on strict validation on step changing
    if (nextStep) {
      // If step is 0, just move
      // if (state.currentStep === 0) {
      //   setStrictValidation(false)
      //   dispatch({
      //     type: actions.CURRENT_STEP,
      //     data: state.currentStep + 1,
      //   })
      //   setNextStep(false)
      //   window.scrollTo(0, 0)
      //   return
      // }
      setStrictValidation(true)
      // If no invalid fields so far, validate
      if (invalidFields.length === 0) {
        const invalidFieldsNames = validateStep(state.currentStep, state)
        // If all fields is valid, change step
        if (invalidFieldsNames.length === 0) {
          setStrictValidation(false)
          dispatch({
            type: actions.CURRENT_STEP,
            data: state.currentStep + 1,
          })
          setNextStep(false)
          window.scrollTo(0, 0)
          return
        }
        // If some fields invalid
        setInvalidFields(invalidFieldsNames)
        console.log(invalidFieldsNames)
      }
      console.log(invalidFields)
      // Some fields invalid, so prevent movement
      setNextStep(false)
    }
  }, [invalidFields.length, nextStep, state])

  // Revalidate fields on change
  useDefer(
    () => {
      if (strictValidation) {
        setInvalidFields(validateStep(state.currentStep, state))
      }
    },
    [state, strictValidation],
    300
  )

  // Reset state funcs
  const [aboutToResetState, setAboutToResetState] = useState(false)
  const [warningWasShown, setWarningWasShown] = useState(false)
  useEffect(() => {
    if (aboutToResetState) {
      setWarningWasShown(true)
      setTimeout(() => {
        setAboutToResetState(false)
      }, 10000)
    }
  }, [aboutToResetState])

  function handleResetState() {
    dispatch({
      type: actions.RESET,
      data: false,
    })
    ResetState()
  }

  function ResetState(withReload: boolean = true) {
    resetLocalStorage(window.localStorage)

    getUserAndFormId(() => {}, window.localStorage)

    // This is for uppy reset to work
    // TODO: create uppy reset.
    if (withReload) location.reload()
  }

  const [CONFIRM_ENABLED, setCONFIRM_ENABLED] = useState(2)
  useEffect(() => {
    if (CONFIRM_ENABLED == 0) {
      fetch(CODE_CONFIRM_ENABLED, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${state.formId}`,
        },
        credentials: 'include',
      })
        .then(response => response.json())
        .then(result => {
          setCONFIRM_ENABLED(result.status ? 2 : 1)
        })
        .catch(error => {
          console.error('Error:', error)
        })
    }
  })

  /* RENDER */
  const step = state.currentStep

  useEffect(() => {
    const url = `?lang=${lang}&country=${COUNTRY}&t=${state.profileType}&step=${step}`
    navigate(url)
  }, [step])

  useEffect(() => {
    let cart = []
    for (var key in searchParams.getAll('id')) {
      cart.push({
        guid: searchParams.getAll('guid')[key],
        id: searchParams.getAll('id')[key],
        img: searchParams.getAll('img')[key],
        name: searchParams.getAll('name')[key],
        count: searchParams.getAll('count')[key],
        startDateIso: new Date(
          searchParams.getAll('startDateIso')[key]
        ).toISOString(),
        startDate: searchParams.getAll('startDate')[key],
        startTime: searchParams.getAll('startTime')[key],
        endDateIso: new Date(
          searchParams.getAll('endDateIso')[key]
        ).toISOString(),
        endDate: searchParams.getAll('endDate')[key],
        endTime: searchParams.getAll('endTime')[key],
        valuta: searchParams.getAll('valuta')[key],
        valutaRate: searchParams.getAll('valutaRate')[key],
        price: searchParams.getAll('price')[key],
        discount: searchParams.getAll('discount')[key],
        shift: searchParams.getAll('shift')[key],
      })
    }
    console.log('cart', cart)
    setCartList(JSON.stringify(cart))
    let products = cart.map((product: any) => {
      return { title: product.name, guid: product.guid }
    })
    console.log(products)
    if (products.length > 0) {
      dispatch({ type: actions.GOODS, data: products })
    }
  }, [searchParams])
  console.log('searchParams',searchParams)
  useEffect(() => {
    if (window.parentIFrame) {
      if (step === 5) {
        window.parentIFrame.sendMessage({
          city: state.mainCity,
          name: state.firstName,
          lastName: state.lastName,
          patronymic: state.patronymic,
          mail: state.emails[0],
          phone: state.mobilePhoneNumbers[0],
          comments: state.feedback,
        })
      } else {
        window.parentIFrame.sendMessage('scrollTop')
      }
    }
  }, [step])

  // to iframe message
  const [origin, setOrigin] = useState('')
  useEffect(() => {
    window.iFrameResizer = {
      onMessage: (event: any) => {
        event ? saveCart(JSON.stringify(event.cart), localStorage) : null
      },
    }
  }, [iframeResizer])

  return (
    <>
      <div className="container">
        {/* AGREEMENT */}
        {(!state.agreed && step !== TOTAL_STEPS) || step == 0 ? (
          <AcceptConditions
            lang={lang}
            onAccept={() => {
              dispatch({ type: actions.AGREED, data: true })
            }}
            accountForm={accountForm}
            state={state}
            dispatch={dispatch}
          />
        ) : state.profileType == profileType_Fiscal ? (
          <>
            <div className={'row'}>
              { ((step < 5 && lang === 'rus') || (step < 4 && lang !== 'rus')) && 
                <div
                  className={styles.backButton + ' ' + 'col-12'}
                  onClick={() => {
                    if (step === 1) {
                      handleResetState()
                    } else {
                      // Turn off strict validation
                      setStrictValidation(false)
                      // Reset invalid fields
                      setInvalidFields([])
                      // Move to prev step
                      dispatch({
                        type: actions.CURRENT_STEP,
                        data: state.currentStep - 1,
                      })
                    }
                  }}
                >
                  <img src={BackButtonImg} alt="back" />
                  <b>{accountForm.prevButton[lang]}</b>
                </div>
              }
            </div>
            <form
              className={styles.form}
              onSubmit={event => event.preventDefault()}
            >
              {/* NAME AND BANK */}
              {step === 1 && (
                <main>
                  <TopSide
                    profileType={state.profileType}
                    accountForm={accountForm}
                    lang={lang}
                    step={step}
                    totalsteps={TOTAL_STEPS}
                  />
                  <FiscalScreenOne
                    lang={lang}
                    state={state}
                    initialState={initialState}
                    dispatch={dispatch}
                    accountForm={accountForm}
                    citizenshipCountriesList={allCitizenshipsFiscalSort}
                    countriesList={allCitizenshipsFiscalSort}
                    allFiscalCountry={allFiscalSort}
                    addressCountries={allCitizenshipsFiscalAddressSort}
                    regionCityRegistered={RegionCityListLocalesRegistered}
                    regionCityFactual={RegionCityListLocalesFactual}
                    invalidFields={invalidFields}
                    regionCity={RegionCityList}
                  />
                </main>
              )}
              {/* UPLOAD DOCS */}
              {step === 2 && (
                <main>
                  <TopSide
                    profileType={state.profileType}
                    accountForm={accountForm}
                    lang={lang}
                    step={step}
                    totalsteps={TOTAL_STEPS}
                  />
                  <FiscalScreenTwo
                    lang={lang}
                    state={state}
                    dispatch={dispatch}
                    accountForm={accountForm}
                    invalidFields={invalidFields}
                  />
                </main>
              )}
              {/* PROXIES NAMES */}
              {step === 3 && (
                <main>
                  <TopSide
                    profileType={state.profileType}
                    accountForm={accountForm}
                    lang={lang}
                    step={step}
                    totalsteps={TOTAL_STEPS}
                  />
                  <FiscalScreenThree
                    lang={lang}
                    state={state}
                    initialState={initialState}
                    dispatch={dispatch}
                    accountForm={accountForm}
                    allCitizenships={citizenshipsKz}
                    invalidFields={invalidFields}
                  />
                </main>
              )}
              {step === 4 && lang === 'rus' && (
                <main>
                  <TopSide
                    profileType={state.profileType}
                    accountForm={accountForm}
                    lang={lang}
                    step={step}
                    totalsteps={TOTAL_STEPS}
                  />
                  <FiscalScreenFour
                    lang={lang}
                    state={state}
                    initialState={initialState}
                    dispatch={dispatch}
                    accountForm={accountForm}
                    invalidFields={invalidFields}
                    allCitizenships={citizenshipsKz}
                  />
                </main>
              )}
              {/* SUBMIT LOADING */}
              {(step === 5 || (step === 4 && lang !== 'rus')) && (
                // This component resets the state on window
                // close if there is no error.
                <main>
                  <TopSide
                    profileType={state.profileType}
                    accountForm={accountForm}
                    lang={lang}
                    step={step}
                    totalsteps={TOTAL_STEPS}
                  />
                  <ScreenFour
                    lang={lang}
                    accountForm={accountForm}
                    form={state}
                    resetState={() => {
                      dispatch({
                        type: actions.RESET,
                        data: false,
                      })
                      ResetState()
                      //resetLocalStorage(window.localStorage)
                    }}
                    nextStep={() => {
                      CONFIRM_ENABLED == 2 &&
                      (!state.Confirm_mail_ok || !state.Confirm_phone_ok)
                        ? dispatch({
                            type: actions.CURRENT_STEP,
                            data: state.currentStep + 1,
                          })
                        : // For the sake of employee whishes
                          // redirect to plain "success.html"
                          // page, where the Subscribe popup
                          // will be shown. The popup is auto
                          // generated html with some remote
                          // scripts so we can't use it here
                          (dispatch({
                            type: actions.REPLACE,
                            data: { ...INITIAL_STATE, currentStep: 8 },
                          }),
                          ResetState(false),
                          //resetLocalStorage(window.localStorage),
                          window.location.replace(SUBMIT_SUCCESS))
                    }}
                    prevStep={() => {
                      // Turn off strict validation
                      setStrictValidation(false)
                      // Reset invalid fields
                      setInvalidFields([])
                      // Move to prev step
                      dispatch({
                        type: actions.CURRENT_STEP,
                        data: state.currentStep - 1,
                      })
                    }}
                  />
                </main>
              )}
              {(step === 6 || (step === 5 && lang !== 'rus')) && (
                <main>
                  <ConfirmRemember
                    lang={lang}
                    onAccept={() => {
                      dispatch({
                        type: actions.RESET,
                        data: false,
                      }),
                        ResetState()
                      //resetLocalStorage(window.localStorage)
                    }}
                    accountForm={accountForm}
                    state={state}
                    dispatch={dispatch}
                  />
                </main>
              )}
            </form>

            {/* ALMOST THERE (messages and buttons) */}
            {/* {step === TOTAL_STEPS  && (
                <p className={styles.almostThereMessage}>
                  {accountForm.almostThereMessage[lang]}
                </p>
              )} */}
          </>
        ) : (
          <>
            <div className={'row'}>
              { ((step < 4 && lang === 'rus') || (step < 3 && lang !== 'rus')) && 
                <div
                  className={styles.backButton + ' ' + 'col-12'}
                  onClick={() => {
                    if (step === 1) {
                      handleResetState()
                    } else {
                      // Turn off strict validation
                      setStrictValidation(false)
                      // Reset invalid fields
                      setInvalidFields([])
                      // Move to prev step
                      dispatch({
                        type: actions.CURRENT_STEP,
                        data: state.currentStep - 1,
                      })
                    }
                  }}
                >
                  <img src={BackButtonImg} alt="back" />
                  <b>{accountForm.prevButton[lang]}</b>
                </div>
              }
            </div>
            <form
              className={styles.form}
              onSubmit={event => event.preventDefault()}
            >
              {/* NAME AND PASSPORT AND ADDRESES */}
              {step === 1 && (
                <main>
                  <TopSide
                    profileType={state.profileType}
                    accountForm={accountForm}
                    lang={lang}
                    step={step}
                    totalsteps={TOTAL_STEPS}
                  />
                  {COUNTRY === 'kz' ? (
                    <ScreenOneKzt
                      lang={lang}
                      state={state}
                      initialState={initialState}
                      dispatch={dispatch}
                      accountForm={accountForm}
                      citizenshipCountriesList={allCitizenshipsSort}
                      countriesList={allCitizenshipsSort}
                      allCitizenships={allCitizenshipsSort}
                      addressCountries={allCitizenshipsAddressSort}
                      regionCityRegistered={RegionCityListLocalesRegistered}
                      regionCityFactual={RegionCityListLocalesFactual}
                      invalidFields={invalidFields}
                    />
                  ) : (
                    <ScreenOneRus
                      lang={lang}
                      state={state}
                      initialState={initialState}
                      dispatch={dispatch}
                      accountForm={accountForm}
                      citizenshipCountriesList={allCitizenshipsSort}
                      countriesList={allCitizenshipsSort}
                      allCitizenships={allCitizenshipsSort}
                      addressCountries={allCitizenshipsAddressSort}
                      regionCityRegistered={RegionCityListLocalesRegistered}
                      regionCityFactual={RegionCityListLocalesFactual}
                      invalidFields={invalidFields}
                    />
                  )}
                </main>
              )}
              {/* ADDITIONAL */}
              {step === 2 && (
                <main>
                  <TopSide
                    profileType={state.profileType}
                    accountForm={accountForm}
                    lang={lang}
                    step={step}
                    totalsteps={TOTAL_STEPS}
                  />
                  <ScreenTwo
                    lang={lang}
                    state={state}
                    initialState={initialState}
                    dispatch={dispatch}
                    accountForm={accountForm}
                    allCitizenships={citizenshipsKz}
                    invalidFields={invalidFields}
                  />
                </main>
              )}
              {/* GOODS */}
              {step === 3 && lang === 'rus' && (
                <main>
                  <TopSide
                    profileType={state.profileType}
                    accountForm={accountForm}
                    lang={lang}
                    step={step}
                    totalsteps={TOTAL_STEPS}
                  />
                  {cartList && JSON.parse(cartList).length > 0 ? (
                    <ScreenThreeCart
                      lang={lang}
                      state={state}
                      initialState={initialState}
                      dispatch={dispatch}
                      accountForm={accountForm}
                      invalidFields={invalidFields}
                      cart={JSON.parse(cartList)}
                    />
                  ) : (
                    <ScreenThree
                      lang={lang}
                      state={state}
                      initialState={initialState}
                      dispatch={dispatch}
                      accountForm={accountForm}
                      invalidFields={invalidFields}
                    />
                  )}
                </main>
              )}
              {/* SUBMIT LOADING */}
              {(step === 4 || (step === 3 && lang !== 'rus')) && (
                <main>
                  <TopSide
                    profileType={state.profileType}
                    accountForm={accountForm}
                    lang={lang}
                    step={step}
                    totalsteps={TOTAL_STEPS}
                  />
                  <ScreenFour
                    lang={lang}
                    accountForm={accountForm}
                    form={state}
                    cart={JSON.parse(cartList)}
                    resetState={() => {
                      dispatch({
                        type: actions.RESET,
                        data: false,
                      })
                      ResetState()
                      //resetLocalStorage(window.localStorage)
                    }}
                    nextStep={() => {
                      CONFIRM_ENABLED == 2 &&
                      (!state.Confirm_mail_ok || !state.Confirm_phone_ok)
                        ? dispatch({
                            type: actions.CURRENT_STEP,
                            data: state.currentStep + 1,
                          })
                        : // For the sake of employee whishes
                          // redirect to plain "success.html"
                          // page, where the Subscribe popup
                          // will be shown. The popup is auto
                          // generated html with some remote
                          // scripts so we can't use it here
                          (dispatch({
                            type: actions.REPLACE,
                            data: { ...INITIAL_STATE, currentStep: 8 },
                          }),
                          ResetState(false),
                          //resetLocalStorage(window.localStorage),
                          window.location.replace(SUBMIT_SUCCESS))
                    }}
                    prevStep={() => {
                      // Turn off strict validation
                      setStrictValidation(false)
                      // Reset invalid fields
                      setInvalidFields([])
                      // Move to prev step
                      dispatch({
                        type: actions.CURRENT_STEP,
                        data: state.currentStep - 1,
                      })
                    }}
                  />
                </main>
              )}

              {(step === 5 || (step === 4 && lang !== 'rus')) && (
                <main>
                  <ConfirmRemember
                    lang={lang}
                    onAccept={() => {
                      dispatch({
                        type: actions.RESET,
                        data: false,
                      }),
                        ResetState()
                      //resetLocalStorage(window.localStorage)
                    }}
                    accountForm={accountForm}
                    state={state}
                    dispatch={dispatch}
                  />
                </main>
              )}

              {/* ALMOST THERE (messages and buttons)
                {step === TOTAL_STEPS  && (
                  <p className={styles.almostThereMessage}>
                    {accountForm.almostThereMessage[lang]}
                  </p>
                )} */}

              {step === TOTAL_STEPS &&
              CONFIRM_ENABLED == 2 &&
              (!state.Confirm_mail_ok || !state.Confirm_phone_ok) ? (
                <></>
              ) : (
                state.agreed &&
                step < TOTAL_STEPS - 2 && (
                  <>
                    <div
                      className={
                        step !== TOTAL_STEPS 
                          ? styles.buttonsContainer
                          : styles.almostThereButtonsContainer
                      }
                    ></div>
                    {/* RESET STATE BUTTON */}
                    {aboutToResetState && (
                      <p className={styles.resetWarning}>
                        {accountForm.resetStateWarning[lang]}
                      </p>
                    )}
                  </>
                )
              )}
            </form>
          </>
        )}
        <div className={styles.AnketaFooter}>
          <div className="row">
            {step == 0 ? (
              <div className={styles.AnketaFooterBox + ' ' + 'col-12'}>
                <div>
                  <RedButton
                    value={accountForm.profileType_PhysicalButton[lang]}
                    className={styles.AnketaFooterButton}
                    onClick={() => {
                      if (state.agreedCheck) {
                        state.agreed = true
                        state.profileType = profileType_Physical
                        dispatch({
                          type: actions.AGREE_ON_AGREEMENT,
                          data: true,
                        })
                      }
                    }}
                    disabled={!state.agreedCheck}
                  ></RedButton>
                  <BlueButton
                    value={accountForm.profileType_FiscalButton[lang]}
                    className={styles.AnketaFooterButton}
                    onClick={() => {
                      if (state.agreedCheck) {
                        state.agreed = true
                        state.profileType = profileType_Fiscal
                        state.countryRegistration === '398'
                          ? (state.F_FiscalType = '6')
                          : (state.F_FiscalType = '2')
                        dispatch({
                          type: actions.AGREE_ON_AGREEMENT,
                          data: true,
                        })
                      }
                    }}
                    disabled={!state.agreedCheck}
                  ></BlueButton>
                  <BlackButton
                    value={accountForm.profileType_FiscalIPButton[lang]}
                    className={styles.AnketaFooterButton}
                    onClick={() => {
                      if (state.agreedCheck) {
                        state.agreed = true
                        state.profileType = profileType_Fiscal
                        state.F_FiscalType = '1'
                        dispatch({
                          type: actions.AGREE_ON_AGREEMENT,
                          data: true,
                        })
                      }
                    }}
                    disabled={!state.agreedCheck}
                  ></BlackButton>
                </div>
              </div>
            ) : null}
            {/* PREV BUTTON */}
            <div className={styles.AnketaFooterBox + ' ' + 'col-12'}>
              {step < TOTAL_STEPS && step !== 0 ? (
                <HollowButton
                  testName={step === 1 ? 'cancelButton' : 'prevButton'}
                  value={
                    step === 1
                      ? accountForm.cancelButton[lang]
                      : accountForm.prevButton[lang]
                  }
                  onClick={() => {
                    if (step === 1) {
                      handleResetState()
                    } else {
                      // Turn off strict validation
                      setStrictValidation(false)
                      // Reset invalid fields
                      setInvalidFields([])
                      // Move to prev step
                      dispatch({
                        type: actions.CURRENT_STEP,
                        data: state.currentStep - 1,
                      })
                    }
                  }}
                ></HollowButton>
              ) : null}
              {/* NEXT BUTTON */}
              {step < TOTAL_STEPS - 1 && step !== 0 ? (
                <div>
                  <span>{accountForm.requiredDesc[lang]}</span>
                  <BlueButton
                    testName="nextButton"
                    value={accountForm.nextButton[lang]}
                    disabled={
                      step > TOTAL_STEPS  ||
                      invalidFields.length !== 0
                    }
                    onClick={() => setNextStep(true)}
                  ></BlueButton>
                </div>
              ) : null}
              {step === TOTAL_STEPS - 1  ? (
                <div>
                  <BlueButton
                    testName="submitButton"
                    value={accountForm.submitButton[lang]}
                    onClick={() => setNextStep(true)}
                  ></BlueButton>
                </div>
              ) : null}
            </div>
          </div>
          <div className="row">
            {step <= TOTAL_STEPS  &&
            step !== 0 &&
            state.profileType == profileType_Fiscal ? (
              <div className="col-12">
                <div className={styles.AnketaFooterDescription}>
                  {lang === 'eng' ? (
                    <>
                      <p className={styles.troubleMessage}>
                        If you have any difficulty completing the form,{' '}
                        <span>contact us by email </span>
                        <a href="mailto:dariya.irent@gmail.com">
                          ala@kino.rent
                        </a>
                        <span> or by phone </span>
                        <a href="tel:+77073571338">+7 (707) 357-13-38</a>
                      </p>
                      <p className={styles.additionalTroubleMessage}>
                        Additional phone number:
                        <a href="tel:+77273467181">+7 (727) 346-71-81</a>
                      </p>
                    </>
                  ) : lang === 'kz' ? (
                    <>
                      <p className={styles.troubleMessage}>
                        Пішінді толтыруда қиындықтар туындаса,{' '}
                        <span>электрондық пошта арқылы хабарласыңыз </span>
                        <a href="mailto:dariya.irent@gmail.com">
                          ala@kino.rent поштасы
                        </a>
                        <span> немесе телефон арқылы </span>
                        <br />
                        <a href="tel:+77073571338">+7 (707) 357-13-38</a>
                      </p>
                      <p className={styles.additionalTroubleMessage}>
                        Қосымша телефон нөмірі:
                        <a href="tel:+77273467181">+7 (727) 346-71-81</a>
                      </p>
                    </>
                  ) : (
                    <>
                      <p className={styles.troubleMessage}>
                        Если у Вас возникли трудности при заполнении анкеты,{' '}
                        <span>свяжитесь по эл. почте </span>
                        <a href="mailto:dariya.irent@gmail.com">
                          ala@kino.rent
                        </a>
                        <span> или по телефону </span>
                        <a href="tel:+77073571338">+7 (707) 357-13-38</a>
                      </p>
                      <p className={styles.additionalTroubleMessage}>
                        Дополнительный номер телефона:
                        <a href="tel:+77273467181">+7 (727) 346-71-81</a>
                      </p>
                    </>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}
