import React, { useEffect, useContext } from 'react'

import ContextCurrentLocale from '../components/ContextCurrentLocale'
import { BASE_URL } from '../server-parameters'
import AccountForm from '../types/account-form.types'

import { Lang } from '../types/common-types'
import { State, Dispatch, actions } from '../state-actions-reducers'

import styles from './ConfirmRemember.module.scss'
import BlueButton from '../components/Buttons/BlueButton'
import HollowButton from '../components/Buttons/HollowButton'

import GreenCheckMarkImage from '../images/greencheckmark.svg'

interface Props {
  lang: Lang
  onAccept: () => void
  accountForm: AccountForm
  state: State
  dispatch: Dispatch
}

export default function ConfirmRemember({
  lang,
  onAccept,
  accountForm,
  state,
  dispatch,
}: Props) {
  useEffect(() => {
    // Reset state user closes tab
    window.addEventListener('unload', onAccept)
    return () => {
      window.removeEventListener('unload', onAccept)
    }
  })

  return (
    <>
      <main className={styles.ConfirmRemember}>
        <div className="row">
          <div className="col-12">
            <div className={styles.ConfirmRemember__imageBox}>
              <img src={GreenCheckMarkImage} alt="GreenCheckMarkImage" />
            </div>
            <h2 className={styles.ConfirmRemember__h2}>
              {accountForm.successSubmit.head[lang]}
            </h2>
            <h3 className={styles.ConfirmRemember__h3}>
              {accountForm.successSubmit.header[lang]}
            </h3>
            <p className={styles.ConfirmRemember__text}>
              {accountForm.successSubmit.label1[lang]}{' '}
              <span>{accountForm.successSubmit.label2[lang]}</span>
              {accountForm.successSubmit.label3[lang]}
              <br />
              {accountForm.successSubmit.label4[lang]}
            </p>
            <div className={styles.buttonBox}>
              <BlueButton
                value={accountForm.gotoMainPage[lang]}
                onClick={() => window.location.reload()}
              ></BlueButton>
            </div>
          </div>
          {/* <div className={styles.accauntDescContainer + ' ' + 'col-12'}>
            <div className={styles.accauntDescBox + ' ' + 'col-5'}>
              <img src={CartImage} alt="cart" />
              <span>Для продолжения формирования<br/> заказа перейдите в личный кабинет.<br/> Первый вход будет выполнен через смс.</span>
            </div>
            <div className={styles.accauntDescBox + ' ' + 'col-5'}>
              <img src={MailImage} alt="mail" />
              <span>На почту придет ссылка на личный<br/> кабинет, в котором можно оследить<br/> статус рассмотрения анкеты. </span>
            </div>
          </div> */}
        </div>
      </main>
    </>
  )
}
