import React, { useRef, useState, useEffect, useMemo } from 'react'
import useDebounce from '../../../../utils/useDebounce'
import TextInput from '../../../../components/Inputs/TextInput'
import SelectInput from '../../../../components/Inputs/SelectInput'
import FileInput from '../../../../components/Inputs/FileInput'
import { UPLOADS_ENDPOINT } from '../../../../server-parameters'
import { copyContactPerson, yearsAgo } from '../../../../utils/helpers'
import {
  validateName,
  validateString,
  validateDate,
  smartValidateString,
} from '../../../../validate/validators'
import { Country, ScreenProps } from '../../../../types/common-types'
import { actions } from '../../../../state-actions-reducers'
import DatePicker from '../../../../components/Inputs/DatePicker'
import { checkPhoneEmail } from '../../../../hooks/useCheckPhoneEmailCustomHook'
import styles from '../../FiscalScreenOne.module.scss'
import warningImage from '../../../../images/warning.svg'
import RussianForm from '../RfInstance/Citizenships/RussianForm'
import KyrgyzstanForm from '../RfInstance/Citizenships/KyrgyzstanForm'
import RomanianForm from '../RfInstance/Citizenships/RomanianForm'
import ChinaForm from '../RfInstance/Citizenships/ChinaForm'
import KazakhstanForm from '../RfInstance/Citizenships/KazakhstanForm'
import BelarusForm from '../RfInstance/Citizenships/BelarusForm'
import UzbekistanForm from '../RfInstance/Citizenships/UzbekistanForm'
import VietnamForm from '../RfInstance/Citizenships/VietnamForm'
import AddressInputs from '../../AddressInputs/AddressInputs'
import {
  passportTypeKz,
  passportTypeRf,
  passportTypeRom,
  passportTypeKgz,
  passportTypeGer,
  passportTypeUsa,
  passportTypeTaj,
  passportTypeTur,
  passportTypeUzb,
  DocumentTypeValuesUdvAbroad,
  DocumentTypeValuesTypePersonalities,
  DocumentTypeValuesTypePassport,
} from '../../../../content/passportTypeFiscal'
import { stringDigitsLength } from '../../../../validate/validators'
import { soleTraderType } from '../../../../content/fiscalType'
import { getFiscalFullName } from '../../../../utils/TranslateFiscalKzt/getFiscalFullName'
import { getFiscalShortName } from '../../../../utils/TranslateFiscalKzt/getFiscalShortName'
import { getCertificateRegistrationNumber } from '../../../../utils/TranslateFiscalKgz/getCertificateRegistrationNumber'
import { getCertificateRegistrationDate } from '../../../../utils/TranslateFiscalKgz/getCertificateRegistrationDate'
import { getCertificateRegistrationImage } from '../../../../utils/TranslateFiscalKgz/getCertificateRegistrationImage'
import AddCitizenships from '../../../../components/AddCitizenships'
import { kzCity } from '../../../../content/cities'

interface Props extends ScreenProps {
  citizenshipCountriesList: Country[]
  allFiscalCountry: Country[]
  countriesList: Country[]
  addressCountries: Country[]
  regionCity: any
  regionCityRegistered: any
  regionCityFactual: any
}

/* REACT COMPONENT */
function IpScreenOne({
  lang,
  state,
  dispatch,
  accountForm,
  allFiscalCountry,
  citizenshipCountriesList,
  addressCountries,
  invalidFields,
  regionCityRegistered,
  regionCityFactual,
}: Props) {
  const getCitizenshipFormat = (citizenship: string) => {
    return citizenshipCountriesList.find((country: Country) => {
      return country.value === citizenship
    })?.label?.rus
  }
  const selectedCitizenship = useMemo(() => {
    return getCitizenshipFormat(state.citizenship)
  }, [state.citizenship])

  const [focus, setFocus] = useState('')
  const debouncedPhone = useDebounce(state.mobilePhoneNumbers[0], 1500)
  const debouncedMail = useDebounce(state.emails[0], 1500)

  async function phoneEmailMessage() {
    let response = await checkPhoneEmail(state, debouncedPhone, debouncedMail)
    if (response.message !== 'Invalid token') {
      dispatch({ type: actions.IS_PHONE_OK, data: response.isPhoneOk })
      dispatch({ type: actions.IS_EMAIL_OK, data: response.isEmailOk })
    } else {
      alert('Произошла ошибка, перезагрузите странницу!')
    }
  }
  useEffect(() => {
    if (debouncedPhone || debouncedMail) phoneEmailMessage()
  }, [debouncedPhone, debouncedMail])

  const fullNameObject = getFiscalFullName(
    state.F_FiscalType,
    state.countryRegistration
  )
  const shortNameObject = getFiscalShortName(
    state.F_FiscalType,
    state.countryRegistration
  )

  const [shortName, setShortName] = useState(shortNameObject)
  const [fullName, setFullName] = useState(fullNameObject)

  useEffect(() => {
    setShortName(shortNameObject)
    setFullName(fullNameObject)
  }, [state.F_FiscalType])
  useEffect(() => {
    dispatch({
      type: actions.F_FiscalType,
      data: '1',
    })
  }, [state.countryRegistration])

  useEffect(() => {
    state.mainCity = kzCity[0].value
  }, [lang])

  useEffect(() => {
    if (state.passportType?.length === 0) {
      dispatch({
        type: actions.PASSPORT_TYPE,
        data:
          selectedCitizenship === 'Казахстан'
            ? passportTypeKz[0].value
            : passportTypeRf[0].value,
      })
    }
  }, [selectedCitizenship, state.F_FiscalType])

  const certificateRegistrationNumber = getCertificateRegistrationNumber(
    state.F_FiscalType
  )
  const certificateRegistrationDate = getCertificateRegistrationDate(
    state.F_FiscalType
  )
  const certificateRegistrationImage = getCertificateRegistrationImage(
    state.F_FiscalType
  )
  const [showAddCitizenships, setShowAddCitizenships] = useState(false)
  console.log('invalidFields', invalidFields)
  return (
    <>
      <div>
        <div className="form-group">
          <div className="row">
            <div className="col-2 col-xs-12">
              <TextInput
                testName={`F_INN`}
                testErrorName={`error-F_INN`}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.F_IIN}
                onChange={F_INN => {
                  dispatch({
                    type: actions.F_INN,
                    data: F_INN.replace(/\D/gim, '').substr(0, 12),
                  })
                }}
                // onChange={F_INN =>
                //   dispatch({ type: actions.F_INN, data: F_INN })
                // }
                value={state.F_INN || ''}
                required
                valid={
                  stringDigitsLength(state.F_INN, 12, false) &&
                  !invalidFields.includes('F_INN')
                }
                mask={'999999999999'}
              />
            </div>
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_INN_Image}
                onChange={F_INN_Image => {
                  dispatch({
                    type: actions.F_INN_IMAGE_FILE,
                    data: F_INN_Image,
                  })
                }}
                value={state.F_INN_Image[state.F_INN_Image.length - 1]}
                valid={!invalidFields.includes('F_INN_Image')}
                documentType="INN"
              />
            </div>
            <div className="col-2 col-xs-12">
              {/* F_OGRN */}
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.F_OGRNIP}
                onChange={F_OGRN => {
                  dispatch({
                    type: actions.F_OGRN,
                    data: F_OGRN.replace(/\D/gim, '').substr(0, 15),
                  })
                }}
                // onChange={F_OGRN =>
                //   dispatch({ type: actions.F_OGRN, data: F_OGRN })
                // }
                value={state.F_OGRN || ''}
                valid={
                  stringDigitsLength(state.F_OGRN, 15, false) &&
                  !invalidFields.includes('F_OGRN')
                }
                mask={'999999999999999'}
              />
            </div>
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_OGRNIP_Image}
                onChange={F_OGRN_Image => {
                  dispatch({
                    type: actions.F_OGRN_IMAGE_FILE,
                    data: F_OGRN_Image,
                  })
                }}
                valid={!invalidFields.includes('F_OGRN_Image')}
                documentType="OGRN"
                value={state.F_OGRN_Image[state.F_OGRN_Image.length - 1]}
              />
            </div>
            <div className="col-2 col-xs-12">
              <DatePicker
                lang={lang}
                accountForm={accountForm}
                accountFormKey="registeredDate"
                onChange={data => {
                  dispatch({
                    type: actions.REGISTERED_DATE,
                    data,
                  })
                }}
                value={state.registeredDate}
                focus={focus === 'registeredDate' || undefined}
                onBlur={() => setFocus('')}
                valid={!invalidFields.includes('registeredDate')}
                required
              />
            </div>
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_EGRIP_Image}
                onChange={F_EGRUL_Image => {
                  dispatch({
                    type: actions.F_EGRUL_IMAGE_FILE,
                    data: F_EGRUL_Image,
                  })
                }}
                value={state.F_EGRUL_Image[state.F_EGRUL_Image.length - 1]}
                valid={!invalidFields.includes('F_EGRUL_Image')}
                documentType="EGRYUL"
              />
            </div>
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_Rent_Contract_Image}
                onChange={F_Rent_Contract_Image => {
                  dispatch({
                    type: actions.F_RENT_CONTRACT_FILE,
                    data: F_Rent_Contract_Image,
                  })
                }}
                value={
                  state.F_Rent_Contract_Image[
                    state.F_Rent_Contract_Image.length - 1
                  ]
                }
                valid={!invalidFields.includes('F_Rent_Contract_Image')}
                documentType="Lease"
              />
            </div>
            <div className={styles.inputBox + ' ' + 'col-2 col-xs-12'}>
              <div>
                <input
                  id="hideContractInput"
                  name="hideContractInput"
                  type="checkBox"
                  onChange={() =>
                    dispatch({
                      type: actions.NO_LEASE_AGREEMENT,
                      data: !state.noLeaseAgreement,
                    })
                  }
                  value={false}
                />
                <label htmlFor="hideContractInput">
                  {accountForm.noRentContractBtnDesc[lang]}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3>{accountForm.stepOneIdentification[lang]}</h3>
            </div>
            <div className="col-4 col-xs-12">
              {/* LAST NAME */}
              <TextInput
                testName={'headLastname'}
                testErrorName={'error-headLastname'}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headLastname}
                onChange={headLastname => {
                  dispatch({ type: actions.HEAD_LASTNAME, data: headLastname })
                  if (state.copyContactPerson)
                    dispatch({
                      type: actions.CONTACT_PERSON_LASTNAME,
                      data: headLastname,
                    })
                  if (lang === 'eng') {
                    dispatch({
                      type: actions.HEAD_LASTNAME_GENITIVE,
                      data: headLastname,
                    })
                  }
                }}
                value={state.headLastname || ''}
                required
                valid={
                  state.headLastname.length > 0
                    ? smartValidateString(state.headLastname) &&
                      !invalidFields.includes('headLastname')
                    : !invalidFields.includes('headLastname')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* FIRST NAME */}
              <TextInput
                testName={'headFirstname'}
                testErrorName={'error-headFirstname'}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headFirstname}
                onChange={headFirstname => {
                  dispatch({
                    type: actions.HEAD_FIRSTNAME,
                    data: headFirstname,
                  })
                  if (state.copyContactPerson)
                    dispatch({
                      type: actions.CONTACT_PERSON_FIRSTNAME,
                      data: headFirstname,
                    })
                  if (lang === 'eng') {
                    dispatch({
                      type: actions.HEAD_FIRSTNAME_GENITIVE,
                      data: headFirstname,
                    })
                  }
                }}
                value={state.headFirstname || ''}
                required
                valid={
                  state.headFirstname.length > 0
                    ? smartValidateString(state.headFirstname) &&
                      !invalidFields.includes('headFirstname')
                    : !invalidFields.includes('headFirstname')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* PATRONYMIC */}
              <TextInput
                testName={'headPatronymic'}
                testErrorName={'error-headPatronymic'}
                testCheckName={'headNoPatronymic'}
                disabled={!state.agreed || state.noPatronymic}
                lang={lang}
                content={accountForm.headPatronymic}
                onChange={headPatronymic => {
                  dispatch({
                    type: actions.HEAD_PATRONYMIC,
                    data: headPatronymic,
                  })
                  if (state.copyContactPerson)
                    dispatch({
                      type: actions.CONTACT_PERSON_PATRONYMIC,
                      data: headPatronymic,
                    })
                  if (lang === 'eng') {
                    dispatch({
                      type: actions.HEAD_PATRONYMIC_GENITIVE,
                      data: headPatronymic,
                    })
                  }
                }}
                checkLabel={accountForm.noPatronymic[lang]}
                checkValue={state.noPatronymic}
                checkChange={() => {
                  dispatch({
                    type: actions.NO_PATRONYMIC,
                    data: !state.noPatronymic,
                  })
                  dispatch({
                    type: actions.HEAD_PATRONYMIC,
                    data: '',
                  })
                  dispatch({
                    type: actions.HEAD_PATRONYMIC_GENITIVE,
                    data: '',
                  })
                }}
                required
                value={state.headPatronymic || ''}
                valid={
                  state.headPatronymic.length > 0
                    ? smartValidateString(state.headPatronymic) &&
                      !invalidFields.includes('headPatronymic')
                    : !invalidFields.includes('headPatronymic')
                }
              />
            </div>
            {lang !== 'eng' ? (
              <>
                <div className="col-4 col-xs-12">
                  <TextInput
                    testName={'headLastnameGenitive'}
                    testErrorName={'error-headLastnameGenitive'}
                    disabled={!state.agreed}
                    lang={lang}
                    content={accountForm.headLastnameGenitive}
                    onChange={(headLastnameGenitive: string) =>
                      dispatch({
                        type: actions.HEAD_LASTNAME_GENITIVE,
                        data: headLastnameGenitive,
                      })
                    }
                    value={state.headLastnameGenitive || ''}
                    valid={
                      state.headLastnameGenitive.length > 0
                        ? smartValidateString(state.headLastnameGenitive) &&
                          !invalidFields.includes('headLastnameGenitive')
                        : !invalidFields.includes('headLastnameGenitive')
                    }
                  />
                </div>
                <div className="col-4 col-xs-12">
                  <TextInput
                    testName={'headFirstnameGenitive'}
                    testErrorName={'error-headFirstnameGenitive'}
                    disabled={!state.agreed}
                    lang={lang}
                    content={accountForm.headFirstnameGenitive}
                    onChange={(headFirstnameGenitive: string) =>
                      dispatch({
                        type: actions.HEAD_FIRSTNAME_GENITIVE,
                        data: headFirstnameGenitive,
                      })
                    }
                    value={state.headFirstnameGenitive || ''}
                    valid={
                      state.headFirstnameGenitive.length > 0
                        ? smartValidateString(state.headFirstnameGenitive) &&
                          !invalidFields.includes('headFirstnameGenitive')
                        : !invalidFields.includes('headFirstnameGenitive')
                    }
                  />
                </div>
                <div className="col-4 col-xs-12">
                  <TextInput
                    testName={'headPatronymicGenitive'}
                    testErrorName={'error-headPatronymicGenitive'}
                    testCheckName={'headNoPatronymic'}
                    disabled={!state.agreed || state.noPatronymic}
                    lang={lang}
                    content={accountForm.headPatronymicGenitive}
                    onChange={(headPatronymicGenitive: string) =>
                      dispatch({
                        type: actions.HEAD_PATRONYMIC_GENITIVE,
                        data: headPatronymicGenitive,
                      })
                    }
                    checkLabel={accountForm.noPatronymic[lang]}
                    checkValue={state.noPatronymic}
                    checkChange={() => {
                      dispatch({
                        type: actions.NO_PATRONYMIC,
                        data: !state.noPatronymic,
                      })
                      dispatch({
                        type: actions.HEAD_PATRONYMIC,
                        data: '',
                      })
                      dispatch({
                        type: actions.HEAD_PATRONYMIC_GENITIVE,
                        data: '',
                      })
                    }}
                    value={state.headPatronymicGenitive || ''}
                    valid={
                      state.headPatronymicGenitive.length > 0
                        ? smartValidateString(state.headPatronymicGenitive) &&
                          !invalidFields.includes('headPatronymicGenitive')
                        : !invalidFields.includes('headPatronymicGenitive')
                    }
                  />
                </div>
              </>
            ) : null}
          </div>
          <div className="row">
            <div className="col-4 col-xs-12">
              {/* CITIZENSHIP */}
              <SelectInput
                testName={'citizenship'}
                testErrorName={'error-citizenship'}
                noOptionsMessage={accountForm.citizenshipInput.placeholder}
                lang={lang}
                disabled={!state.agreed}
                content={accountForm.citizenshipInput}
                options={citizenshipCountriesList}
                onChange={citizenship => {
                  dispatch({
                    type: actions.CITIZENSHIP,
                    data: citizenship,
                  })
                  dispatch({
                    type: actions.PASSPORT_TYPE,
                    data:
                      getCitizenshipFormat(citizenship) === 'Казахстан'
                        ? passportTypeKz[0].value
                        : passportTypeRf[0].value,
                  })
                }}
                valid={!invalidFields.includes('citizenship')}
                value={state.citizenship}
                required
                prefix="CitizenshipsRf"
              />
              <span
                className={styles.addCitizenships}
                onClick={() => setShowAddCitizenships(!showAddCitizenships)}
              >
                {accountForm.noCitizenshipsLink[lang]}
              </span>
            </div>
            <div className="col-3 col-xs-12">
              {/* BIRTH DATE */}
              <DatePicker
                testName={'birthDate'}
                testErrorName={'error-birthDate'}
                disabled={!state.agreed}
                lang={lang}
                accountForm={accountForm}
                accountFormKey="birthDate"
                onChange={birthDate =>
                  dispatch({ type: actions.BIRTH_DATE, data: birthDate })
                }
                value={state.birthDate}
                valid={
                  !invalidFields.includes('birthDate') &&
                  validateDate({
                    current: state.birthDate,
                    min: yearsAgo(130),
                    max: yearsAgo(14),
                  })
                }
                curr={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                }
                min={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 85)
                  )
                }
                max={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                }
                required
              />
            </div>
            <div className="col-5 col-xs-12">
              {/* BIRTH PLACE */}
              <TextInput
                testName={'birthPlace'}
                testErrorName={'error-birthPlace'}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.birthPlace}
                onChange={birthPlace => {
                  dispatch({
                    type: actions.BIRTH_PLACE,
                    data: birthPlace,
                  })
                }}
                value={state.birthPlace}
                required
                valid={
                  state.birthPlace.length > 0
                    ? smartValidateString(state.birthPlace) &&
                      !invalidFields.includes('birthPlace')
                    : !invalidFields.includes('birthPlace')
                }
                onBlur={() => setFocus('')}
              />
            </div>
            {showAddCitizenships ? (
              <div className="col-12">
                <AddCitizenships
                  state={state}
                  accountForm={accountForm}
                  lang={lang}
                  handlerChange={() => setShowAddCitizenships(false)}
                  valid={
                    state.mobilePhoneNumbers.length > 0 &&
                    state.emails.length > 0 &&
                    state.isPhoneOk &&
                    state.isEmailOk
                  }
                  noContacts={true}
                  dispatch={dispatch}
                  invalidFields={invalidFields}
                />
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-3 col-xs-12">
              <SelectInput
                testName={'passportType'}
                testErrorName={'error-passportType'}
                lang={lang}
                noOptionsMessage={accountForm.passportTypeInput.placeholder}
                options={
                  selectedCitizenship === 'Казахстан'
                    ? passportTypeKz
                    : selectedCitizenship === 'Россия'
                    ? passportTypeRf
                    : selectedCitizenship === 'Беларусь'
                    ? passportTypeRf
                    : selectedCitizenship === 'Кыргызстан'
                    ? passportTypeKgz
                    : selectedCitizenship === 'Румыния'
                    ? passportTypeRom
                    : selectedCitizenship === 'Германия'
                    ? passportTypeGer
                    : selectedCitizenship === 'Соединенные Штаты'
                    ? passportTypeUsa
                    : selectedCitizenship === 'Таджикистан'
                    ? passportTypeTaj
                    : selectedCitizenship === 'Турция'
                    ? passportTypeTur
                    : selectedCitizenship === 'Узбекистан'
                    ? passportTypeUzb
                    : DocumentTypeValuesTypePassport
                }
                content={accountForm.passportTypeInput}
                onChange={data => {
                  dispatch({
                    type: actions.PASSPORT_TYPE,
                    data,
                  })
                }}
                value={state.passportType}
                valid={
                  !invalidFields.includes('passportType') &&
                  validateString(state.passportType, 300, true)
                }
                required
              />
            </div>
            {selectedCitizenship === 'Россия' ? (
              <RussianForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Китай' ? (
              <ChinaForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Казахстан' ? (
              <KazakhstanForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Кыргызстан' ? (
              <KyrgyzstanForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Румыния' ? (
              <RomanianForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Беларусь' ? (
              <BelarusForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Бразилия' ||
            selectedCitizenship === 'Италия' ? (
              <BelarusForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Австралия' ||
            selectedCitizenship === 'Азербайджан' ||
            selectedCitizenship === 'Армения' ||
            selectedCitizenship === 'Соединенное Королевство' ||
            selectedCitizenship === 'Германия' ||
            selectedCitizenship === 'Испания' ||
            selectedCitizenship === 'Сирийская Арабская Республика' ||
            selectedCitizenship === 'Соединенные Штаты' ||
            selectedCitizenship === 'Таджикистан' ||
            selectedCitizenship === 'Узбекистан' ||
            selectedCitizenship === 'Франция' ||
            selectedCitizenship === 'Швейцария' ? (
              <UzbekistanForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Болгария' ||
            selectedCitizenship === 'Израиль' ||
            selectedCitizenship === 'Исландия' ||
            selectedCitizenship === 'Молдова, Республика' ||
            selectedCitizenship === 'Нидерланды' ||
            selectedCitizenship === 'Норвегия' ||
            selectedCitizenship === 'Швеция' ||
            selectedCitizenship === 'Южная Африка' ||
            selectedCitizenship === 'Вьетнам' ||
            selectedCitizenship === 'Грузия' ||
            selectedCitizenship === 'Индонезия' ||
            selectedCitizenship === 'Индия' ||
            selectedCitizenship === 'Корея, Республика' ||
            selectedCitizenship === 'Финляндия' ? (
              <VietnamForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            <div className="col-12 col-xs-12">
              <p className={styles.warningMessage}>
                <img src={warningImage} alt="warningImage"></img>
                {accountForm.Fiscal_Warning_Scan_Directors_Passport_KZT[lang]}
              </p>
            </div>
          </div>
        </div>
        <AddressInputs
          lang={lang}
          initialState={state}
          state={state}
          dispatch={dispatch}
          accountForm={accountForm}
          invalidFields={invalidFields}
          addressCountries={addressCountries}
          regionCityRegistered={regionCityRegistered}
          regionCityFactual={regionCityFactual}
        />
      </div>
    </>
  )
}

export default IpScreenOne
