import React, { useState, useEffect, useRef, useMemo } from 'react'
import useDebounce from '../../../utils/useDebounce'
import TextInput from '../../../components/Inputs/TextInput'
import DatePicker from '../../../components/Inputs/DatePicker'
import { yearsAgo } from '../../../utils/helpers'
import {
  validateName,
  validateDate,
  validateString,
  smartValidateString,
} from '../../../validate/validators'
import { checkPhoneEmail } from '../../../hooks/useCheckPhoneEmailCustomHook'
import { Country, ScreenProps } from '../../../types/common-types'
import { actions } from '../../../state-actions-reducers'
import SelectInput from '../../../components/Inputs/SelectInput'
import PhoneInput from '../../../components/Inputs/PhoneInput'
import EmailInput from '../../../components/Inputs/EmailInput'
import styles from '../ScreenOne.module.scss'
import RussianForm from './Citizenships/RussianForm'
import KyrgyzstanForm from './Citizenships/KyrgyzstanForm'
import RomanianForm from './Citizenships/RomanianForm'
import ChinaForm from './Citizenships/ChinaForm'
import KazakhstanForm from './Citizenships/KazakhstanForm'
import BelarusForm from './Citizenships/BelarusForm'
import UkraineForm from './Citizenships/UkraineForm'
import UzbekistanForm from './Citizenships/UzbekistanForm'
import VietnamForm from './Citizenships/VietnamForm'
import GermanyForm from './Citizenships/GermanyForm'
import UsaForm from './Citizenships/UsaForm'
import TajikistanForm from './Citizenships/TajikistanForm'
import TurkishForm from './Citizenships/TurkishForm'
import NigeriaForm from './Citizenships/NigeriaForm'
import LitvaForm from './Citizenships/LitvaForm'
import IndiaForm from './Citizenships/IndiaForm'
import SwedenForm from './Citizenships/SwedenForm'
import AddressInputs from '../AddressInputs/AddressInputs'
import AdditionalDocuments from '../AdditionalDocuments/AdditionalDocuments'
import { SexSelect } from '../../../components/Inputs/SexSelect'
import {
  passportTypeKz,
  passportTypeRfForKz,
  passportTypeRom,
  passportTypeKgz,
  passportTypeGbr,
  passportTypeBel,
  passportTypeUkr,
  passportTypeLitva,
  passportTypeGer,
  passportTypeUsa,
  passportTypeTaj,
  passportTypeTur,
  passportTypeUzb,
  DocumentTypeValuesUdvAbroad,
  DocumentTypeValuesTypePersonalities,
  DocumentTypeValuesTypePassport,
  passportTypeIndia,
  passportTypeSwe,
  passportTypeSKorea,
  passportTypeNga,
  passportTypeIrn,
  passportTypeChn,
} from '../../../content/passportType'
import {
  additionalDocumentTypeValues,
  additionalDocumentTypeValuesKz,
  additionalDocumentTypeValuesRfForKz,
  additionalDocumentTypeValuesKgz,
  additionalDocumentTypeValuesOthers,
  additionalDocumentTypeValuesGbr,
} from '../../../content/additionalDocumentType'
import { kzCity } from '../../../content/cities'
import AddCitizenships from '../../../components/AddCitizenships'
import BritishForm from './Citizenships/BritishForm'
import { familyStatusMaleOptions, familyStatusFemaleOptions } from '../../../content/familyStatusType'
import SouthKoreaForm from './Citizenships/SouthKoreaForm'
import IranForm from './Citizenships/IranForm'

interface Props extends ScreenProps {
  citizenshipCountriesList: Country[]
  allCitizenships: Country[]
  addressCountries: Country[]
  countriesList: Country[]
  regionCityRegistered: any
  regionCityFactual: any
}

/* REACT COMPONENT */
function ScreenOne({
  lang,
  state,
  dispatch,
  accountForm,
  citizenshipCountriesList,
  allCitizenships,
  addressCountries,
  invalidFields,
  regionCityRegistered,
  regionCityFactual,
}: Props) {
  // There is a side effect in onChange listener in PassportType.
  // This side effect dispatch an action to make value for passport number
  // adn passport series valid for russian passport.
  // Search for "PASSPORT SIDE EFFECT".
  const countriesRef = useRef(
    allCitizenships?.reduce(
      (object, country) => ({
        ...object,
        [country.alpha2]: country[lang],
      }),
      {}
    )
  )
  const countries = countriesRef.current
  const [focus, setFocus] = useState('')
  const getCitizenshipFormat = (citizenship: string) => {
    return citizenshipCountriesList.find((country: Country) => {
      return country.value === citizenship
    })?.label?.rus
  }
  const selectedCitizenship = useMemo(() => {
    return getCitizenshipFormat(state.citizenship)
  }, [state.citizenship])

  console.log('state.citizenship', state.citizenship)

  const debouncedPhone = useDebounce(state.mobilePhoneNumbers[0], 1500)
  const debouncedMail = useDebounce(state.emails[0], 1500)

  async function phoneMessage() {
    let response = await checkPhoneEmail(state, debouncedPhone, debouncedMail)
    if (response.message !== 'Invalid token') {
      dispatch({ type: actions.IS_PHONE_OK, data: response.isPhoneOk })
    } else {
      alert('Произошла ошибка, перезагрузите странницу!')
    }
  }
  async function emailMessage() {
    let response = await checkPhoneEmail(state, debouncedPhone, debouncedMail)
    if (response.message !== 'Invalid token') {
      dispatch({ type: actions.IS_EMAIL_OK, data: response.isEmailOk })
    } else {
      alert('Произошла ошибка, перезагрузите странницу!')
    }
  }
  useEffect(() => {
    if (debouncedPhone) phoneMessage()
    if (debouncedMail) emailMessage()
  }, [debouncedPhone, debouncedMail])

  useEffect(() => {
    state.mainCity = kzCity[0].value
  }, [lang])

  useEffect(() => {
    if (state.passportType?.length === 0) {
      dispatch({
        type: actions.PASSPORT_TYPE,
        data:
          selectedCitizenship === 'Казахстан'
            ? passportTypeKz[0].value
            : selectedCitizenship === 'Россия' ||
              selectedCitizenship === 'Кыргызстан'
            ? passportTypeRfForKz[0].value
            : selectedCitizenship === 'Румыния' ||
              selectedCitizenship === 'Соединенные Штаты' ||
              selectedCitizenship === 'Турция' || selectedCitizenship === 'Великобритания' || selectedCitizenship === 'Нигерия' || selectedCitizenship === 'Иран, Исламская Республика' || selectedCitizenship === 'Китай'
            ? passportTypeRom[0].value
            : selectedCitizenship === 'Германия' ||
              selectedCitizenship === 'Узбекистан' ||
              selectedCitizenship === 'Украина' ||
              selectedCitizenship === 'Таджикистан'
            ? DocumentTypeValuesUdvAbroad[0].value
            : DocumentTypeValuesTypePassport[0].value,
      })
    }
  }, [selectedCitizenship])
  const [showAddCitizenships, setShowAddCitizenships] = useState(false)
  return (
    <>
      <main className={styles.main}>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3>{accountForm.stepOnePersonalTitle[lang]}</h3>
            </div>
            {lang !== 'eng' ? (
              <>
                <div className="col-4 col-xs-12">
                  {/* LAST NAME */}
                  <TextInput
                    testName="lastname"
                    testErrorName="error-lastname"
                    disabled={!state.agreed}
                    lang={lang}
                    content={accountForm.lastName}
                    onChange={lastName =>
                      dispatch({ type: actions.LAST_NAME, data: lastName })
                    }
                    value={state.lastName || ''}
                    required
                    valid={
                      state.lastName.length > 0
                        ? smartValidateString(state.lastName) &&
                          !invalidFields.includes('lastName')
                        : !invalidFields.includes('lastName')
                    }
                  />
                </div>
                <div className="col-4 col-xs-12">
                  {/* FIRST NAME */}
                  <TextInput
                    testName="firstname"
                    testErrorName="error-firstname"
                    disabled={!state.agreed}
                    lang={lang}
                    content={accountForm.firstName}
                    onChange={firstName =>
                      dispatch({ type: actions.FIRST_NAME, data: firstName })
                    }
                    value={state.firstName || ''}
                    required
                    valid={
                      state.firstName.length > 0
                        ? smartValidateString(state.firstName) &&
                          !invalidFields.includes('firstName')
                        : !invalidFields.includes('firstName')
                    }
                  />
                </div>
                <div className="col-4 col-xs-12">
                  {/* PATRONYMIC */}
                  <TextInput
                    testName="patronymic"
                    testErrorName="error-patronymic"
                    testCheckName={'noPatronymic'}
                    disabled={!state.agreed || state.noPatronymic}
                    lang={lang}
                    content={accountForm.patronymic}
                    onChange={patronymic =>
                      dispatch({ type: actions.PATRONYMIC, data: patronymic })
                    }
                    checkLabel={accountForm.noPatronymic[lang]}
                    checkValue={state.noPatronymic}
                    checkChange={() => {
                      dispatch({
                        type: actions.NO_PATRONYMIC,
                        data: !state.noPatronymic,
                      })
                      dispatch({ type: actions.PATRONYMIC, data: '' })
                      dispatch({
                        type: actions.LATIN_PATRONYMIC,
                        data: '',
                      })
                    }}
                    required
                    value={state.patronymic || ''}
                    valid={
                      state.patronymic.length > 0
                        ? smartValidateString(state.patronymic) &&
                          !invalidFields.includes('patronymic')
                        : !invalidFields.includes('patronymic')
                    }
                  />
                </div>
              </>
            ) : null}
            <div className="col-4 col-xs-12">
              {/* LATIN LAST NAME */}
              <TextInput
                testName="latinLastname"
                testErrorName="error-latinLastname"
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.latinLastname}
                onChange={latinLastname => {
                  latinLastname = latinLastname.replace(
                    /[^A-Za-z0-9\<\>\@\!\#\$\%\^\&\*\(\)\_\+\[\]\{\}\?\:\;\|\'\"\,\.\/\~\`\\\-\=\'\ ]/gi,
                    ''
                  )
                  dispatch({
                    type: actions.LATIN_LAST_NAME,
                    data: latinLastname,
                  })
                  if (lang === 'eng')
                    dispatch({ type: actions.LAST_NAME, data: latinLastname })
                }}
                value={state.latinLastname || ''}
                required
                valid={
                  state.latinLastname.length > 0
                    ? smartValidateString(state.latinLastname) &&
                      !invalidFields.includes('latinLastname')
                    : !invalidFields.includes('latinLastname')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* LATIN FIRST NAME */}
              <TextInput
                testName="latinFirstname"
                testErrorName="error-latinFirstname"
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.latinFirstname}
                onChange={latinFirstname => {
                  latinFirstname = latinFirstname.replace(
                    /[^A-Za-z0-9\<\>\@\!\#\$\%\^\&\*\(\)\_\+\[\]\{\}\?\:\;\|\'\"\,\.\/\~\`\\\-\=\'\ ]/gi,
                    ''
                  )
                  dispatch({
                    type: actions.LATIN_FIRST_NAME,
                    data: latinFirstname,
                  })
                  if (lang === 'eng')
                    dispatch({ type: actions.FIRST_NAME, data: latinFirstname })
                }}
                value={state.latinFirstname || ''}
                required
                valid={
                  state.latinFirstname.length > 0
                    ? smartValidateString(state.latinFirstname) &&
                      !invalidFields.includes('latinFirstname')
                    : !invalidFields.includes('latinFirstname')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* LATIN PATRONYMIC */}
              <TextInput
                testName="latinPatronymic"
                testErrorName="error-latinPatronymic"
                testCheckName={'noPatronymic'}
                disabled={!state.agreed || state.noPatronymic}
                lang={lang}
                content={accountForm.latinPatronymic}
                checkLabel={
                  lang === 'eng' ? accountForm.noPatronymic[lang] : false
                }
                checkValue={lang === 'eng' ? state.noPatronymic : false}
                checkChange={() => {
                  if (lang === 'eng') {
                    dispatch({
                      type: actions.NO_PATRONYMIC,
                      data: !state.noPatronymic,
                    })
                    dispatch({
                      type: actions.LATIN_PATRONYMIC,
                      data: '',
                    })
                    dispatch({
                      type: actions.PATRONYMIC,
                      data: '',
                    })
                  }
                }}
                onChange={latinPatronymic => {
                  latinPatronymic = latinPatronymic.replace(
                    /[^A-Za-z0-9\<\>\@\!\#\$\%\^\&\*\(\)\_\+\[\]\{\}\?\:\;\|\'\"\,\.\/\~\`\\\-\=\'\ ]/gi,
                    ''
                  )
                  dispatch({
                    type: actions.LATIN_PATRONYMIC,
                    data: latinPatronymic,
                  })
                  if (lang === 'eng')
                    dispatch({
                      type: actions.PATRONYMIC,
                      data: latinPatronymic,
                    })
                }}
                required
                value={state.latinPatronymic || ''}
                valid={
                  state.latinPatronymic.length > 0
                    ? smartValidateString(state.latinPatronymic) &&
                      !invalidFields.includes('latinPatronymic')
                    : !invalidFields.includes('latinPatronymic')
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-xs-12">
              {/* CITIZENSHIP */}
              <SelectInput
                testName={'citizenship'}
                testErrorName={'error-citizenship'}
                noOptionsMessage={accountForm.citizenshipInput.placeholder}
                lang={lang}
                disabled={!state.agreed}
                content={accountForm.citizenshipInput}
                options={citizenshipCountriesList}
                onChange={citizenship => {
                  dispatch({
                    type: actions.CITIZENSHIP,
                    data: citizenship,
                  })
                  dispatch({
                    type: actions.PASSPORT_TYPE,
                    data:
                      getCitizenshipFormat(citizenship) === 'Казахстан'
                        ? passportTypeKz[0].value
                        : getCitizenshipFormat(citizenship) === 'Россия'
                        ? passportTypeRfForKz[0].value
                        : getCitizenshipFormat(citizenship) === 'Румыния' ||
                          getCitizenshipFormat(citizenship) ===
                            'Соединенные Штаты' ||
                          getCitizenshipFormat(citizenship) === 'Турция' ||
                          getCitizenshipFormat(citizenship) === 'Нигерия' ||
                          getCitizenshipFormat(citizenship) === 'Кыргызстан' ||
                          getCitizenshipFormat(citizenship) === 'Иран, Исламская Республика' ||
                          getCitizenshipFormat(citizenship) === 'Китай'
                        ? passportTypeRom[0].value :
                          getCitizenshipFormat(citizenship) === 'Великобритания'
                        ? passportTypeGbr[0].value
                        : getCitizenshipFormat(citizenship) === 'Германия' ||
                          getCitizenshipFormat(citizenship) === 'Узбекистан' ||
                          getCitizenshipFormat(citizenship) === 'Украина' ||
                          getCitizenshipFormat(citizenship) === 'Таджикистан'
                        ? DocumentTypeValuesUdvAbroad[0].value
                        : DocumentTypeValuesTypePassport[0].value,
                  })
                }}
                valid={!invalidFields.includes('citizenship')}
                value={state.citizenship}
                required
                prefix="Citizenships"
              />
              <span
                className={styles.addCitizenships}
                onClick={() => setShowAddCitizenships(!showAddCitizenships)}
              >
                {accountForm.noCitizenshipsLink[lang]}
              </span>
            </div>
            <div className="col-3 col-xs-12">
              {/* BIRTH DATE */}
              <DatePicker
                testName="birthDate"
                testErrorName="error-birthDate"
                disabled={!state.agreed}
                lang={lang}
                accountForm={accountForm}
                accountFormKey="birthDate"
                onChange={birthDate =>
                  dispatch({ type: actions.BIRTH_DATE, data: birthDate })
                }
                value={state.birthDate}
                valid={
                  !invalidFields.includes('birthDate') &&
                  validateDate({
                    current: state.birthDate,
                    min: yearsAgo(130),
                    max: yearsAgo(14),
                  })
                }
                curr={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                }
                min={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 85)
                  )
                }
                max={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                }
                required
              />
            </div>
            <div className="col-5 col-xs-12">
              {/* BIRTH PLACE */}
              <TextInput
                testName="birthPlace"
                testErrorName="error-birthPlace"
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.birthPlace}
                onChange={birthPlace => {
                  dispatch({
                    type: actions.BIRTH_PLACE,
                    data: birthPlace,
                  })
                }}
                value={state.birthPlace}
                required
                valid={
                  state.birthPlace.length > 0
                    ? smartValidateString(state.birthPlace) &&
                      !invalidFields.includes('birthPlace')
                    : !invalidFields.includes('birthPlace')
                }
                onBlur={() => setFocus('')}
              />
            </div>
            {showAddCitizenships ? (
              <div className="col-12">
                <AddCitizenships
                  state={state}
                  accountForm={accountForm}
                  lang={lang}
                  handlerChange={() => setShowAddCitizenships(false)}
                  valid={
                    state.mobilePhoneNumbers.length > 0 &&
                    state.emails.length > 0 &&
                    state.isPhoneOk &&
                    state.isEmailOk
                  }
                  dispatch={dispatch}
                />
              </div>
            ) : null}
            <div className="col-4 col-xs-12">
              {/* MOBILE PHONE NUMBER */}
              <PhoneInput
                testName="mobilePhoneNumbers"
                testErrorName="error-mobilePhoneNumbers"
                label={accountForm.mobilePhoneNumber.label[lang]}
                lang={lang}
                numbers={
                  state.mobilePhoneNumbers.length === 0
                    ? ['']
                    : state.mobilePhoneNumbers
                }
                setNumbers={data => {
                  dispatch({
                    type: actions.MOBILE_PHONE_NUMBERS,
                    data,
                  })
                  if (!state.Confirm_phone_ok)
                    //&& !accountForm.Confirm_phone)
                    dispatch({
                      type: actions.Confirm_phone,
                      data: data[0] || '',
                    })
                }}
                invalidText={accountForm.mobilePhoneNumber.invalidText[lang]}
                countries={countries}
                required
                firstlocked={state.Confirm_phone_ok}
                valid={
                  !invalidFields.includes('mobilePhoneNumbers') &&
                  state.isPhoneOk
                }
                phoneCheck={!state.isPhoneOk}
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* EMAIL */}
              <EmailInput
                testName="email"
                testErrorName="error-email"
                emails={state.emails.length === 0 ? [''] : state.emails}
                setEmails={data => {
                  dispatch({
                    type: actions.EMAILS,
                    data,
                  })
                  if (!state.Confirm_mail_ok)
                    //&& !accountForm.Confirm_mail)
                    dispatch({
                      type: actions.Confirm_mail,
                      data: data[0] || '',
                    })
                }}
                placeholder={accountForm.email.placeholder[lang]}
                invalidText={accountForm.email.invalidText[lang]}
                invalidTextCorrect={accountForm.email.invalidTextCorrect[lang]}
                accountForm={accountForm}
                lang={lang}
                required
                firstlocked={state.Confirm_mail_ok}
                valid={!invalidFields.includes('emails')}
                mailCheck={!state.isEmailOk}
              />
            </div>
            {/* MAIN_CITY */}
            {/* <div className="col-4 col-xs-12">
              <SelectInput
                testName={'mainCity'}
                testErrorName={'error-mainCity'}
                lang={lang}
                noOptionsMessage={accountForm.mainCity}
                content={accountForm.mainCity}
                value={state.mainCity}
                help={accountForm.mainCity.help}
                options={kzCity}
                valid={
                  !invalidFields.includes('mainCity') &&
                  validateString(state.mainCity, 100)
                }
                onChange={data =>
                  dispatch({
                    type: actions.MAIN_CITY,
                    data: data,
                  })
                }
                className={styles.input}
              />
            </div> */}
            <SexSelect
              initialState={state}
              state={state}
              dispatch={dispatch}
              invalidFields={invalidFields}
              lang={lang}
              accountForm={accountForm}
            />
            {/* FAMILY_STATUS */}
            <div className="col-4 col-xs-12">
              <SelectInput
                lang={lang}
                noOptionsMessage={accountForm.familyStatus.placeholder}
                content={accountForm.familyStatus}
                value={state.familyStatus}
                options={state.sex === '2' ? familyStatusMaleOptions : familyStatusFemaleOptions}
                valid={
                  !invalidFields.includes('.familyStatus')
                }
                onChange={data =>
                  dispatch({
                    type: actions.FAMILY_STATUS,
                    data: data,
                  })
                }
                disabled={state.sex === '0'}
                className={styles.input}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-4 col-xs-12">
              <SelectInput
                testName={'passportType'}
                testErrorName={'error-passportType'}
                lang={lang}
                noOptionsMessage={accountForm.passportTypeInput.placeholder}
                options={
                  selectedCitizenship === 'Казахстан'
                    ? passportTypeKz
                    : selectedCitizenship === 'Россия'
                    ? passportTypeRfForKz
                    : selectedCitizenship === 'Кыргызстан'
                    ? passportTypeKgz
                    : selectedCitizenship === 'Великобритания'
                    ? passportTypeGbr
                    : selectedCitizenship === 'Беларусь'
                    ? passportTypeBel
                    : selectedCitizenship === 'Украина'
                    ? passportTypeUkr
                    : selectedCitizenship === 'Румыния'
                    ? passportTypeRom
                    : selectedCitizenship === 'Германия'
                    ? passportTypeGer
                    : selectedCitizenship === 'Соединенные Штаты'
                    ? passportTypeUsa
                    : selectedCitizenship === 'Таджикистан'
                    ? passportTypeTaj
                    : selectedCitizenship === 'Турция'
                    ? passportTypeTur
                    : selectedCitizenship === 'Иран, Исламская Республика'
                    ? passportTypeIrn
                    : selectedCitizenship === 'Нигерия'
                    ? passportTypeNga
                    : selectedCitizenship === 'Китай'
                    ? passportTypeChn
                    : selectedCitizenship === 'Узбекистан'
                    ? passportTypeUzb
                    : selectedCitizenship === 'Литва'
                    ? passportTypeLitva
                    : selectedCitizenship === 'Индия'
                    ? passportTypeIndia
                    : selectedCitizenship === 'Швеция'
                    ? passportTypeSwe
                    : selectedCitizenship === 'Корея, Республика'
                    ? passportTypeSKorea
                    : DocumentTypeValuesTypePassport
                }
                content={accountForm.passportTypeInput}
                onChange={data => {
                  dispatch({
                    type: actions.PASSPORT_TYPE,
                    data,
                  })
                }}
                value={state.passportType}
                valid={
                  !invalidFields.includes('passportType') &&
                  validateString(state.passportType, 300, true)
                }
                required
              />
            </div>
            {selectedCitizenship === 'Россия' ? (
              <RussianForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Беларусь' ? (
              <BelarusForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Украина' ? (
              <UkraineForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Кыргызстан' ? (
              <KyrgyzstanForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Великобритания' ? (
              <BritishForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Румыния' ? (
              <RomanianForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Китай' ? (
              <ChinaForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Казахстан' ? (
              <KazakhstanForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Германия' ? (
              <GermanyForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Соединенные Штаты' ? (
              <UsaForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Таджикистан' ? (
              <TajikistanForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Турция' ? (
              <TurkishForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Иран, Исламская Республика' ? (
              <IranForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Нигерия' ? (
              <NigeriaForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Литва' ? (
              <LitvaForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Индия' ? (
              <IndiaForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Швеция' ? (
              <SwedenForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Корея, Республика' ? (
              <SouthKoreaForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Бразилия' ||
            selectedCitizenship === 'Италия' ? (
              <BelarusForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Австралия' ||
            selectedCitizenship === 'Азербайджан' ||
            selectedCitizenship === 'Армения' ||
            selectedCitizenship === 'Соединенное Королевство' ||
            selectedCitizenship === 'Испания' ||
            selectedCitizenship === 'Сирийская Арабская Республика' ||
            selectedCitizenship === 'Узбекистан' ||
            selectedCitizenship === 'Франция' ||
            selectedCitizenship === 'Швейцария' ? (
              <UzbekistanForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Болгария' ||
            selectedCitizenship === 'Израиль' ||
            selectedCitizenship === 'Исландия' ||
            selectedCitizenship === 'Молдова, Республика' ||
            selectedCitizenship === 'Нидерланды' ||
            selectedCitizenship === 'Норвегия' ||
            selectedCitizenship === 'Южная Африка' ||
            selectedCitizenship === 'Вьетнам' ||
            selectedCitizenship === 'Грузия' ||
            selectedCitizenship === 'Индонезия' ||
            selectedCitizenship === 'Финляндия' ? (
              <VietnamForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
          </div>
        </div>
        <AddressInputs
          lang={lang}
          initialState={state}
          state={state}
          dispatch={dispatch}
          accountForm={accountForm}
          invalidFields={invalidFields}
          addressCountries={addressCountries}
          regionCityRegistered={regionCityRegistered}
          regionCityFactual={regionCityFactual}
        />
        <AdditionalDocuments
          lang={lang}
          initialState={state}
          state={state}
          dispatch={dispatch}
          accountForm={accountForm}
          invalidFields={invalidFields}
          selectedCitizenship={selectedCitizenship}
          documentsTypeValues={
            selectedCitizenship === 'Казахстан'
                ? additionalDocumentTypeValuesKz
                : selectedCitizenship === 'Россия'
                ? additionalDocumentTypeValuesRfForKz
                : selectedCitizenship === 'Кыргызстан'
                ? additionalDocumentTypeValuesKgz
                : selectedCitizenship === 'Великобритания'
                ? additionalDocumentTypeValuesGbr
                : additionalDocumentTypeValuesOthers
          }
        />
      </main>
    </>
  )
}

export default ScreenOne
