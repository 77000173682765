export const getFiscalShortName = (fiscalType: string) => {
  return {
    label: {
      eng: 'Name abbreviated*',
      rus: 'Наименование сокращенное*',
      kz: 'Аты қысқартылған*',
    },
    placeholder: {
      eng: `${
        fiscalType === '1'
          ? 'IP «MPK»'
          : fiscalType === '3'
          ? 'JSC «MPK»'
          : fiscalType === '4'
          ? 'PJSC «MPK»'
          : fiscalType === '5'
          ? 'CJSC «MPK»'
          : fiscalType === '6'
          ? 'LLP «MPK»'
          : 'LLC «MPK'
      }`,
      rus: `${
        fiscalType === '1'
          ? 'ИП «MПK»'
          : fiscalType === '3'
          ? 'АО «MПK»'
          : fiscalType === '4'
          ? 'ПАО «MПK»'
          : fiscalType === '5'
          ? 'ЗАО «MПK»»'
          : fiscalType === '6'
          ? 'ТОО «MПK»'
          : 'ООО «MПK»'
      }`,
      kz: `${
        fiscalType === '1'
          ? 'IP «MPK»'
          : fiscalType === '3'
          ? 'АҚ «MPK»'
          : fiscalType === '4'
          ? 'ЖАҚ «MPK»'
          : fiscalType === '5'
          ? 'ЖАҚ «MPK»'
          : fiscalType === '6'
          ? '(ЖШС «MPK»'
          : 'ЖШС «MPK»'
      }`,
    },
  }
}
