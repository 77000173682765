import React, { useState, useEffect } from 'react'
import TextInput from '../../../components/Inputs/TextInput'
import SelectInput from '../../../components/Inputs/SelectInput'
import FileInput from '../../../components/Inputs/FileInput'
import DatePicker from '../../../components/Inputs/DatePicker'
import { yearsAgo } from '../../../utils/helpers'
import {
  validateString,
  validateDate,
  smartValidateString,
} from '../../../validate/validators'
import { ScreenProps } from '../../../types/common-types'
import { COUNTRY, UPLOADS_ENDPOINT } from '../../../server-parameters'
import styles from '../ScreenOne.module.scss'
import { actions } from '../../../state-actions-reducers'
import { L10n } from '../../../types/common-types'

interface Props extends ScreenProps {
  selectedCitizenship: string
  documentsTypeValues: {
    value: string
    label: L10n
  }[]
}

export default function AdditionalDocuments({
  lang,
  state,
  dispatch,
  accountForm,
  invalidFields,
  selectedCitizenship,
  documentsTypeValues,
}: Props) {
  const [documentTypeOptions, setDocumentTypeOptions] = useState(
    documentsTypeValues?.filter(e => {
      if (
        state.passportType !== e?.value &&
        !(+state.passportType === 4 && +e?.value === 12)
      )
        return {
          label: e.label,
          value: e.value,
        }
    })
  )
  const [secondDocumentTypeOptions, setSecondDocumentTypeOptions] = useState(
    documentsTypeValues?.filter(e => {
      if (
        state.passportType !== e.value &&
        !(+state.passportType === 4 && +e?.value === 12)
      )
        return {
          label: e.label,
          value: e.value,
        }
    })
  )
  useEffect(() => {
    setSecondDocumentTypeOptions(
      documentsTypeValues?.filter((item: any) => {
        if (
          state.additionalDocumentType !== item.value &&
          state.passportType !== item.value &&
          !(+state.passportType === 4 && +item?.value === 12)
        )
          return item
      })
    )
    setDocumentTypeOptions(
      documentsTypeValues?.filter((item: any) => {
        if (
          state.secondAdditionalDocumentType !== item.value &&
          state.passportType !== item.value &&
          !(+state.passportType === 4 && +item?.value === 12)
        )
          return item
      })
    )
  }, [
    state.additionalDocumentType,
    state.secondAdditionalDocumentType,
    selectedCitizenship,
    state.passportType,
    documentsTypeValues,
  ])

  useEffect(() => {
    const documentTwo = documentsTypeValues?.filter((item: any) => {
      if (
        state.additionalDocumentType !== item.value &&
        state.passportType !== item.value &&
        !(+state.passportType === 4 && +item?.value === 12)
      )
        return item
    })
    const documentOne = documentsTypeValues?.filter((item: any) => {
      if (
        state.secondAdditionalDocumentType !== item.value &&
        state.passportType !== item.value &&
        !(+state.passportType === 4 && +item?.value === 12)
      )
        return item
    })
    console.log(
      'test state.additionalDocumentType',
      state.passportType,
      state.additionalDocumentType
    )
    if (
      (!documentOne.find(e => e.value === state.additionalDocumentType) &&
        state.additionalDocumentType) ||
      state.passportType === state.additionalDocumentType
    ) {
      dispatch({
        type: actions.ADDITIONAL_DOCUMENT_ONE,
        data: documentOne?.[0]?.value,
      })
    }
    // if (
    //   (!documentTwo.find(e => e.value === state.secondAdditionalDocumentType) &&
    //     state.secondAdditionalDocumentType) ||
    //   state.passportType === state.secondAdditionalDocumentType
    // ) {
    //   dispatch({
    //     type: actions.SECOND_ADDITIONAL_DOCUMENT_TYPE,
    //     data: documentTwo?.[1]?.value,
    //   })
    // }
  }, [documentsTypeValues, state.passportType])

  return (
    <div className="form-group">
      <div className="row">
        <div className="col-12 col-xs-12">
          <h3>{accountForm.stepOneAdditional[lang]}</h3>
        </div>
        <div className="col-4 col-xs-12">
          <SelectInput
            testName={'additionalDocumentType'}
            testErrorName={'error-additionalDocumentType'}
            lang={lang}
            noOptionsMessage={accountForm.additionalDocumentType.placeholder}
            content={accountForm.additionalDocumentType}
            value={state.additionalDocumentType}
            options={documentTypeOptions}
            onChange={data =>
              dispatch({
                type: actions.ADDITIONAL_DOCUMENT_ONE,
                data: data,
              })
            }
            valid={
              !invalidFields.includes('additionalDocumentType') &&
              validateString(state.additionalDocumentType, 50)
            }
            className={styles.input}
          />
        </div>
        {state.additionalDocumentType === '12' ||
        state.additionalDocumentType === '11' ? (
          <div className="col-2 col-xs-12">
            {/* ADDITIONAL DOCUMENT EXPIRY DATE */}
            <DatePicker
              testName="additionalDocumentDateExpiry"
              testErrorName="error-additionalDocumentDateExpiry"
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="countryResidencePermitDate"
              onChange={data =>
                dispatch({
                  type: actions.ADDITIONAL_DOCUMENT_DATE_EXPIRY,
                  data,
                })
              }
              value={state.additionalDocumentDateExpiry}
              required
              valid={
                !invalidFields.includes('additionalDocumentDateExpiry') &&
                validateDate({
                  current: state.additionalDocumentDateExpiry,
                  min: new Date(),
                  max: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 10)
                  ),
                })
              }
              onBlur={() => setFocus('')}
              min={new Date()}
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() + 10))
              }
            />
          </div>
        ) : null}
        <div className="col-4 col-xs-12">
          <FileInput
            testName="documentsPhotoOne"
            testErrorName="error-documentsPhotoOne"
            disabled={!state.agreed}
            lang={lang}
            url={UPLOADS_ENDPOINT}
            autorization={state.formId}
            target={state.documentsImages}
            documentType="additionalDocument"
            content={accountForm.documentsPhoto}
            onChange={documentsPhotoOne => {
              dispatch({
                type: actions.DOCUMENTS_PHOTO_ONE,
                data: documentsPhotoOne,
              })
            }}
            valid={!invalidFields.includes('documentsPhotoOne')}
            value={state.documentsPhotoOne[state.documentsPhotoOne.length - 1]}
          />
        </div>
        <div className="col-4 col-xs-12">
          <TextInput
            testName="additionalDocumentComment"
            testErrorName="error-additionalDocumentComment"
            disabled={!state.agreed}
            lang={lang}
            content={
              state.additionalDocumentType === '12'
                ? accountForm.countryResidencePermitNumber
                : accountForm.comments
            }
            onChange={comments =>
              dispatch({
                type: actions.ADDITIONAL_DOCUMENT_COMMENT,
                data: comments,
              })
            }
            value={state.additionalDocumentComment || ''}
            valid={
              state.additionalDocumentType === '12'
                ? state.additionalDocumentComment.length > 0
                  ? smartValidateString(state.additionalDocumentComment) &&
                    !invalidFields.includes('additionalDocumentComment')
                  : !invalidFields.includes('additionalDocumentComment')
                : true
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4 col-xs-12">
          <SelectInput
            testName={'secondAdditionalDocumentType'}
            testErrorName={'error-secondAdditionalDocumentType'}
            lang={lang}
            noOptionsMessage={
              COUNTRY === 'kz' ? accountForm.secondAdditionalDocumentType.placeholder : accountForm.secondAdditionalDocumentTypeNotRequired.placeholder
            }
            content={COUNTRY === 'kz' ? accountForm.secondAdditionalDocumentType : accountForm.secondAdditionalDocumentTypeNotRequired}
            value={state.secondAdditionalDocumentType}
            options={secondDocumentTypeOptions}
            onChange={data =>
              dispatch({
                type: actions.SECOND_ADDITIONAL_DOCUMENT_TYPE,
                data: data,
              })
            }
            className={styles.input}
            valid={
              !invalidFields.includes('secondAdditionalDocumentType')
              // && validateString(state.secondAdditionalDocumentType??'', 50)
            }
          />
        </div>
        {state.secondAdditionalDocumentType === '12' ||
        state.secondAdditionalDocumentType === '11' ? (
          <div className="col-2 col-xs-12">
            {/* ADDITIONAL DOCUMENT EXPIRY DATE */}
            <DatePicker
              testName="secondAdditionalDocumentDateExpiry"
              testErrorName="error-secondAdditionalDocumentDateExpiry"
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="countryResidencePermitDate"
              onChange={data =>
                dispatch({
                  type: actions.SECOND_ADDITIONAL_DOCUMENT_DATE_EXPIRY,
                  data,
                })
              }
              value={state.secondAdditionalDocumentDateExpiry}
              required
              valid={
                !invalidFields.includes('secondAdditionalDocumentDateExpiry') &&
                validateDate({
                  current: state.secondAdditionalDocumentDateExpiry,
                  min: new Date(),
                  max: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 10)
                  ),
                })
              }
              onBlur={() => setFocus('')}
              min={new Date()}
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() + 10))
              }
            />
          </div>
        ) : null}
        <div className="col-4 col-xs-12">
          <FileInput
            testName="documentsPhotoTwo"
            testErrorName="error-documentsPhotoTwo"
            disabled={!state.agreed}
            lang={lang}
            url={UPLOADS_ENDPOINT}
            autorization={state.formId}
            target={state.documentsImages}
            content={COUNTRY === "kz" ? accountForm.documentsPhoto : accountForm.documentsPhotoNotRequired}
            documentType="secondDocument"
            onChange={documentsPhotoTwo => {
              dispatch({
                type: actions.DOCUMENTS_PHOTO_TWO,
                data: documentsPhotoTwo,
              })
            }}
            value={state.documentsPhotoTwo[state.documentsPhotoTwo.length - 1]}
            valid={!invalidFields.includes('documentsPhotoTwo')}
          />
        </div>
        <div className="col-4 col-xs-12">
          <TextInput
            testName="secondAdditionalDocumentComment"
            testErrorName="error-secondAdditionalDocumentComment"
            disabled={!state.agreed}
            lang={lang}
            content={
              state.secondAdditionalDocumentType === '12'
                ? accountForm.countryResidencePermitNumber
                : accountForm.comments
            }
            onChange={comments =>
              dispatch({
                type: actions.SECOND_ADDITIONAL_DOCUMENT_COMMENT,
                data: comments,
              })
            }
            value={state.secondAdditionalDocumentComment || ''}
            valid={
              state.secondAdditionalDocumentType === '12'
                ? state.secondAdditionalDocumentComment.length > 0
                  ? smartValidateString(
                      state.secondAdditionalDocumentComment
                    ) &&
                    !invalidFields.includes('secondAdditionalDocumentComment')
                  : !invalidFields.includes('secondAdditionalDocumentComment')
                : true
            }
          />
        </div>
      </div>
    </div>
  )
}
