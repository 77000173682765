// Here we define the state and it's validator function

import {
  daysAgo,
  isoDate,
  copyFromFactual,
  copyFromRegistered,
  copyContactPerson,
  copyContactPersonEmailPhone,
} from './utils/helpers'
import { loadState, resetLocalStorage } from './local-storage'
import { Recommendation, Proxies } from './types/common-types'
import { COUNTRY } from './server-parameters'

export const STATE_REVISION = '1'
export const RUSSIAN_PASSPORT = 'Паспорт'

export const profileType_Physical = 'Physical'
export const profileType_Fiscal = 'Fiscal'

/*
  In order to add new state value we need to do four things:
  1) Add type definition to the State interface
  2) Add initial value to the INITIAL_STATE
  3) Add new action to the actions
  4) Add new case handling to the reducer
*/

// Define state for all inputs
/* STATE INTERFACE */
export interface State {
  [key: string]:
    | string
    | string[]
    | boolean
    | Recommendation[]
    | Proxies[]
    | number
    | null
    | object[]
  agreedCheck: boolean
  iframeCountry: string
  currentStep: number
  revision: string
  createdAt: string
  formId: string
  userId: string
  orderId: string
  agreed: boolean
  passportType: string
  citizenship: string
  mainCity: string
  locale: string
  sex: string
  familyStatus: string
  firstName: string
  lastName: string
  patronymic: string
  latinFirstname: string
  latinLastname: string
  latinPatronymic: string
  noPatronymic: boolean
  noPatronymicContact: boolean
  isPhoneOk: boolean
  isEmailOk: boolean
  birthDate: string
  birthPlace: string
  passportIAt: string
  passportExpiryDate: string
  passportSeries: string
  passportNumber: string
  headProxyNumber: string
  headProxyStart: string
  headProxyEnd: string
  passportPersonNumber: string
  passportCardNumber: string
  passportFirstNationality: string
  passportIssuedBy: string
  passportAuthorityCode: string
  passportInternationalNumber: string
  passportPermanentResidencyNumber: string
  passportDateBoardCrossing: string
  passportDateBoardCheck: boolean | null
  passportDateIssuePermanentResidency: string
  passportIsPermanentResidency: boolean | null
  addressRegisteredDate: string
  addressRegisteredCountry: string
  addressRegisteredRegion: string
  addressRegisteredCity: string
  addressRegisteredStreet: string
  addressRegisteredAddressInRow: string
  addressRegisteredBuilding: string
  addressRegisteredApartments: string
  addressCopyFromRegistered: boolean
  copyContactPerson: boolean
  copyContactPersonEmailPhone: boolean
  addressFactualCountry: string
  addressFactualRegion: string
  addressFactualCity: string
  addressFactualStreet: string
  addressFactualBuilding: string
  addressFactualApartments: string
  passportImages: string[]
  documentsImages: []
  mainImageFile: string[]
  personImageFile: string[]
  residenceImageFile: string[]
  additionalDocumentType: string
  secondAdditionalDocumentType: string
  additionalDocumentDateExpiry: string
  secondAdditionalDocumentDateExpiry: string
  documentsPhotoOne: string[]
  documentsPhotoTwo: string[]
  addressFactualAddressInRow: string
  addressImageFile: string[]
  visaImageFile: string[]
  portfolioImages: string[]
  mobilePhoneNumbers: string[]
  addressRegisteredPhones: string[]
  addressFactualPhones: string[]
  workPhoneNumbers: string[]
  emails: string[]
  socialLink: string
  additionalLinksDescription: string[]
  jobOrStudy: string
  occupationInstitution: string
  occupationPosition: string
  monthlyIncome: string
  isStudent: boolean
  collegeName: string
  collegeProfession: string
  collegeStartedAt: string
  additionalDocumentComment: string
  secondAdditionalDocumentComment: string
  collegeEndedAt: string
  recommendations: string
  references: string[]
  rentStartedAt: string
  rentEndedAt: string
  goods: []
  vk: string[]
  whatsapp: string[]
  odnoklassniki: string[]
  facebook: string[]
  telegram: string[]
  youtube: string[]
  viber: string[]
  vimeo: string[]
  twitter: string[]
  skype: string[]
  instagram: string[]
  workWebsite: string[]
  personalWebsite: string[]
  additionalLinks: string[]
  subscribe: boolean
  feedback: string
  agreedOn: string[]
  profileType: string

  //Fiscal
  countryRegistration: string
  vatCertificate: string
  certificateRegistrationNumber: string
  reregistrationCertificate: string
  reregisteredDate: string
  VatCertificateDate: string
  oKED: string
  F_INN: string
  F_KBe: string
  F_KPP: string
  F_FiscalType: string
  soleTraderType: string
  registrationTicket: string
  registrationNotice: string
  scanSoleRegistrationTicket: string[]
  scanSoleRegistrationTicketSecond: string[]
  scanOfNotificationFirst: string[]
  scanOfNotificationSecond: string[]
  F_FullName: string
  noFullName: boolean
  F_ShortName: string
  F_OGRN: string
  F_OGRNIP: string
  oKPO: string
  F_OKATO: string
  F_OKOPF: string
  F_addressRegistered: string
  F_addressFactual: string
  BankDetails: object[]
  // F_BankRS: string
  // F_BankBIK: string
  // F_BankName: string
  // F_BankKS: string
  F_TaxType: string
  F_Website: string
  F_Contact: string
  contactPersonPhones: string[]
  contactPersonEmails: string[]
  contactPersonPosition: string
  contactPersonFirstname: string
  contactPersonLastname: string
  contactPersonPatronymic: string
  // F_WorkPhoneNumber: string[]
  F_ContactPhoneNumber: string[]
  // F_Email: string[]
  // F_Contact_Email: string[]
  F_DocImages: string[]
  F_OGRN_Image: string[]
  F_OGRNIP_Image: string[]
  F_INN_Image: string[]
  F_Charter_Image: string[]
  F_Protocol_Image: string[]
  F_EGRUL_Image: string[]
  F_Order_Image: string[]
  F_Passport_Director_Image: string[]
  F_Rent_Contract_Image: string[]
  noLeaseAgreement: boolean
  addressRegisteredNoApartments: boolean
  addressFactualNoApartments: boolean
  F_ContactsProxie: string

  F_Directors_Phone: string[]
  contactPhones: string[]
  contactPhoneSeconds: string[]
  contactEmails: string[]
  purposeOfLease: string
  F_Proxies_Phone: string[]
  F_Proxies_FullName: string
  F_Directors_FullName: string
  headFirstname: string
  headLastname: string
  headPatronymic: string
  headFirstnameGenitive: string
  headLastnameGenitive: string
  headPatronymicGenitive: string
  F_Scan_Directors_Passport: string[]
  F_Scan_Directors_Passport_Address: string[]
  registeredDate: string
  registrationNoticeDate: string
  receptionNoticeDate: string
  stateRegistrationCertificate: string[]
  headPosition: string
  headPositionGenitive: string
  foundingDocument: string
  foundingDocument_Image: string[]
  F_Ip_FullName: string
  F_Booker: string
  F_Directors_Email: string[]
  F_Proxies_Passport_ImageFile: string[]
  F_Proxies_Order_ImageFile: string[]
  addressFactualPostCode: string
  addressRegisteredPostCode: string
  F_Proxies: Proxies[]
  F_ProxiesOn: boolean

  Confirm_phone: string
  Confirm_phone_ok: boolean
  Confirm_mail: string
  Confirm_mail_ok: boolean
}

export const INITIAL_STATE: State = {
  iframeCountry: COUNTRY,
  currentStep: 0,
  createdAt: isoDate(new Date()),
  revision: STATE_REVISION,
  formId: '',
  userId: '',
  orderId: '',
  agreedCheck: false,
  agreed: false,
  passportType: '',
  citizenship: COUNTRY === 'kz' ? '398' : '643',
  mainCity: '',
  locale: 'RU',
  sex: '0',
  familyStatus: '0',
  firstName: '',
  lastName: '',
  patronymic: '',
  latinFirstname: '',
  latinLastname: '',
  latinPatronymic: '',
  noPatronymic: false,
  noPatronymicContact: false,
  isPhoneOk: true,
  isEmailOk: true,
  passportSeries: '',
  passportNumber: '',
  headProxyNumber: '',
  headProxyStart: '',
  headProxyEnd: '',
  passportPersonNumber: '',
  passportCardNumber: '',
  passportFirstNationality: '',
  passportIAt: '',
  passportExpiryDate: '',
  birthDate: '',
  birthPlace: '',
  passportIssuedBy: '',
  passportAuthorityCode: '',
  passportInternationalNumber: '',
  passportPermanentResidencyNumber: '',
  passportDateBoardCrossing: '',
  passportDateBoardCheck: false,
  passportDateIssuePermanentResidency: '',
  passportIsPermanentResidency: null,
  addressRegisteredDate: '',
  stateRegistrationCertificate: [],
  addressRegisteredCountry: COUNTRY === 'kz' ? '398' : '643',
  addressRegisteredRegion: '',
  addressRegisteredCity: '',
  addressRegisteredStreet: '',
  addressRegisteredAddressInRow: '',
  addressRegisteredBuilding: '',
  addressRegisteredApartments: '',
  addressCopyFromRegistered: false,
  copyContactPerson: false,
  copyContactPersonEmailPhone: false,
  addressFactualCountry: COUNTRY === 'kz' ? '398' : '643',
  addressFactualRegion: '',
  addressFactualCity: '',
  addressFactualStreet: '',
  addressFactualBuilding: '',
  addressFactualApartments: '',
  passportImages: [],
  documentsImages: [],
  mainImageFile: [],
  personImageFile: [],
  residenceImageFile: [],
  additionalDocumentType: '',
  secondAdditionalDocumentType: '',
  additionalDocumentDateExpiry: '',
  secondAdditionalDocumentDateExpiry: '',
  documentsPhotoOne: [],
  documentsPhotoTwo: [],
  addressFactualAddressInRow: '',
  addressImageFile: [],
  visaImageFile: [],
  portfolioImages: [],
  mobilePhoneNumbers: [],
  addressRegisteredPhones: [],
  addressFactualPhones: [],
  workPhoneNumbers: [],
  emails: [],
  socialLink: '',
  additionalLinksDescription: [],
  jobOrStudy: '',
  occupationInstitution: '',
  occupationPosition: '',
  monthlyIncome: '',
  isStudent: false,
  collegeName: '',
  collegeProfession: '',
  collegeStartedAt: '',
  additionalDocumentComment: '',
  secondAdditionalDocumentComment: '',
  collegeEndedAt: '',
  recommendations: '',
  references: [],
  rentStartedAt: '',
  rentEndedAt: '',
  goods: [],
  vk: [],
  whatsapp: [],
  odnoklassniki: [],
  facebook: [],
  telegram: [],
  youtube: [],
  vimeo: [],
  viber: [],
  twitter: [],
  skype: [],
  instagram: [],
  workWebsite: [],
  personalWebsite: [],
  additionalLinks: [],
  subscribe: false,
  feedback: '',
  agreedOn: [],
  profileType: '',

  //Fiscal
  countryRegistration: (COUNTRY === 'kz') ? '398' : '643',
  vatCertificate: '',
  certificateRegistrationNumber: '',
  reregistrationCertificate: '',
  reregisteredDate: '',
  VatCertificateDate: '',
  oKED: '',
  F_INN: '',
  F_KBe: '',
  F_KPP: '',
  F_FiscalType: '',
  soleTraderType: '2',
  registrationTicket: '',
  scanSoleRegistrationTicket: [],
  scanSoleRegistrationTicketSecond: [],
  registrationNotice: '',
  scanOfNotificationFirst: [],
  scanOfNotificationSecond: [],
  F_FullName: '',
  noFullName: false,
  F_ShortName: '',
  F_OGRN: '',
  F_OGRNIP: '',
  oKPO: '',
  F_OKATO: '',
  F_OKOPF: '',
  F_addressRegistered: '',
  F_addressFactual: '',
  BankDetails: [
    {
      F_BankBIK: '',
      F_BankName: '',
      F_BankRS: '',
      F_BankKS: '',
      F_BankRNN: '',
      F_BankBIN: '',
      F_BankKBe: '',
    },
  ],
  // F_BankRS: '',
  // F_BankBIK: '',
  // F_BankName: '',
  // F_BankKS: '',
  F_TaxType: '0',
  F_Website: '',
  F_Contact: '',
  contactPersonPhones: [],
  contactPersonEmails: [],
  contactPersonPosition: '',
  contactPersonFirstname: '',
  contactPersonLastname: '',
  contactPersonPatronymic: '',
  // F_WorkPhoneNumber: [],
  F_ContactPhoneNumber: [],
  // F_Email: [],
  // F_Contact_Email: [],
  F_DocImages: [],
  F_OGRN_Image: [],
  F_OGRNIP_Image: [],
  F_INN_Image: [],
  F_Charter_Image: [],
  F_Protocol_Image: [],
  F_EGRUL_Image: [],
  F_Order_Image: [],
  F_Passport_Director_Image: [],
  F_Rent_Contract_Image: [],
  noLeaseAgreement: false,
  addressRegisteredNoApartments: false,
  addressFactualNoApartments: false,
  F_ContactsProxie: '',
  F_Directors_Phone: [],
  contactPhones: [],
  contactPhoneSeconds: [],
  contactEmails: [],
  purposeOfLease: '',
  F_Directors_FullName: '',
  headFirstname: '',
  headLastname: '',
  headPatronymic: '',
  headFirstnameGenitive: '',
  headLastnameGenitive: '',
  headPatronymicGenitive: '',
  headPosition: '',
  headPositionGenitive: '',
  foundingDocument: '0',
  F_Scan_Directors_Passport: [],
  F_Scan_Directors_Passport_Address: [],
  registeredDate: '',
  registrationNoticeDate: '',
  receptionNoticeDate: '',
  foundingDocument_Image: [],
  F_Booker: '',
  F_Proxies_Phone: [],
  F_Proxies_FullName: '',
  F_Ip_FullName: '',
  F_Directors_Email: [],
  F_Proxies_Passport_ImageFile: [],
  F_Proxies_Order_ImageFile: [],
  addressFactualPostCode: '',
  addressRegisteredPostCode: '',

  F_Proxies: [],
  F_ProxiesOn: true,

  Confirm_phone: '',
  Confirm_phone_ok: false,
  Confirm_mail: '',
  Confirm_mail_ok: false,
}

// Actions
export const actions = {
  AGREED_CHECK: Symbol('AGREED_CHECK'),
  IFRAME_COUNTRY: Symbol('IFRAME_COUNTRY'),
  CURRENT_STEP: Symbol('CURRENT_STEP'),
  FORM_ID: Symbol('FORM_ID'),
  USER_ID: Symbol('USER_ID'),
  ORDER_ID: Symbol('ORDER_ID'),
  AGREED: Symbol('AGREED'),
  PASSPORT_TYPE: Symbol('PASSPORT_TYPE'),
  CITIZENSHIP: Symbol('CITIZENSHIP'),
  MAIN_CITY: Symbol('MAIN_CITY'),
  LOCALE: Symbol('LOCALE'),
  SEX: Symbol('SEX'),
  FAMILY_STATUS: Symbol('FAMILY_STATUS'),
  REPLACE: Symbol('REPLACE'),
  RESET: Symbol('RESET'),
  FIRST_NAME: Symbol('FIRST_NAME'),
  LAST_NAME: Symbol('LAST_NAME'),
  PATRONYMIC: Symbol('PATRONYMIC'),
  LATIN_FIRST_NAME: Symbol('LATIN_FIRST_NAME'),
  LATIN_LAST_NAME: Symbol('LATIN_LAST_NAME'),
  LATIN_PATRONYMIC: Symbol('LATIN_PATRONYMIC'),
  NO_PATRONYMIC: Symbol('NO_PATRONYMIC'),
  NO_PATRONYMIC_CONTACT: Symbol('NO_PATRONYMIC_CONTACT'),
  IS_PHONE_OK: Symbol('IS_PHONE_OK'),
  IS_EMAIL_OK: Symbol('IS_EMAIL_OK'),
  BIRTH_DATE: Symbol('BIRTH_DATE'),
  BIRTH_PLACE: Symbol('BIRTH_PLACE'),
  PASSPORT_IAT: Symbol('PASSPORT_IAT'),
  PASSPORT_EXPIRY_DATE: Symbol('PASSPORT_EXPIRY_DATE'),
  PASSPORT_SERIES: Symbol('PASSPORT_SERIES'),
  PASSPORT_NUMBER: Symbol('PASSPORT_NUMBER'),
  HEAD_PROXY_NUMBER: Symbol('HEAD_PROXY_NUMBER'),
  HEAD_PROXY_START: Symbol('HEAD_PROXY_START'),
  HEAD_PROXY_END: Symbol('HEAD_PROXY_END'),
  PASSPORT_PERSON_NUMBER: Symbol('PASSPORT_PERSON_NUMBER'),
  PASSPORT_CARD_NUMBER: Symbol('PASSPORT_CARD_NUMBER'),
  PASSPORT_FIRST_NATIONALITY: Symbol('PASSPORT_FIRST_NATIONALITY'),
  PASSPORT_ISSUED_BY: Symbol('PASSPORT_ISSUED_BY'),
  PASSPORT_AUTHORITY_CODE: Symbol('PASSPORT_AUTHORITY_CODE'),
  PASSPORT_INTERNATIONAL_NUMBER: Symbol('PASSPORT_INTERNATIONAL_NUMBER'),
  PASSPORT_PERMANENT_RESIDENCY_NUMBER: Symbol('PASSPORT_PERMANENT_RESIDENCY_NUMBER'),
  PASSPORT_DATE_BOARD_CROSSING: Symbol('PASSPORT_DATE_BOARD_CROSSING'),
  PASSPORT_DATE_BOARD_CROSSING_CHECK: Symbol(
    'PASSPORT_DATE_BOARD_CROSSING_CHECK'
  ),
  PASSPORT_DATE_ISSUE_PERMANENT_RESIDENCY: Symbol(
    'PASSPORT_DATE_ISSUE_PERMANENT_RESIDENCY'
  ),
  PASSPORT_PERMANENT_RESIDENCY: Symbol('PASSPORT_PERMANENT_RESIDENCY'),
  MAIN_IMAGE_FILE: Symbol('MAIN_IMAGE_FILE'),
  PERSON_IMAGE_FILE: Symbol('PERSON_IMAGE_FILE'),
  RESIDENCE_IMAGE_FILE: Symbol('RESIDENCE_IMAGE_FILE'),
  ADDRESS_IMAGE_FILE: Symbol('ADDRESS_IMAGE_FILE'),
  VISA_IMAGE_FILE: Symbol('VISA_IMAGE_FILE'),
  ADDITIONAL_DOCUMENT_ONE: Symbol('ADDITIONAL_DOCUMENT_ONE'),
  SECOND_ADDITIONAL_DOCUMENT_TYPE: Symbol('SECOND_ADDITIONAL_DOCUMENT_TYPE'),
  ADDITIONAL_DOCUMENT_DATE_EXPIRY: Symbol('ADDITIONAL_DOCUMENT_DATE_EXPIRY'),
  SECOND_ADDITIONAL_DOCUMENT_DATE_EXPIRY: Symbol(
    'SECOND_ADDITIONAL_DOCUMENT_DATE_EXPIRY'
  ),
  DOCUMENTS_PHOTO_ONE: Symbol('DOCUMENTS_PHOTO_ONE'),
  DOCUMENTS_PHOTO_TWO: Symbol('DOCUMENTS_PHOTO_TWO'),
  COMMENTS: Symbol('COMMENTS'),
  ADDITIONAL_DOCUMENT_COMMENT: Symbol('ADDITIONAL_DOCUMENT_COMMENT'),
  SECOND_ADDITIONAL_DOCUMENT_COMMENT: Symbol('ADDITIONAL_DOCUMENT_COMMENT'),
  ACTUAL_ADDRESS: Symbol(''),
  ADDRESS_REGISTERED_DATE: Symbol('ADDRESS_REGISTERED_DATE'),
  ADDRESS_REGISTERED_COUNTRY: Symbol('ADDRESS_REGISTERED_COUNTRY'),
  ADDRESS_REGISTERED_REGION: Symbol('ADDRESS_REGISTERED_REGION'),
  ADDRESS_REGISTERED_CITY: Symbol('ADDRESS_REGISTERED_CITY'),
  ADDRESS_REGISTERED_STREET: Symbol('ADDRESS_REGISTERED_STREET'),
  ADDRESS_IN_ROW: Symbol('ADDRESS_IN_ROW'),
  ADDRESS_REGISTERED_BUILDING: Symbol('ADDRESS_REGISTERED_BUILDING'),
  ADDRESS_REGISTERED_APARTMENTS: Symbol('ADDRESS_REGISTERED_APARTMENTS'),
  ADDRESS_FACTUAL_COUNTRY: Symbol('ADDRESS_FACTUAL_COUNTRY'),
  ADDRESS_FACTUAL_REGION: Symbol('ADDRESS_FACTUAL_REGION'),
  ADDRESS_FACTUAL_CITY: Symbol('ADDRESS_FACTUAL_CITY'),
  ADDRESS_FACTUAL_STREET: Symbol('ADDRESS_FACTUAL_STREET'),
  ADDRESS_FACTUAL_BUILDING: Symbol('ADDRESS_FACTUAL_BUILDING'),
  ADDRESS_FACTUAL_APARTMENTS: Symbol('ADDRESS_FACTUAL_APARTMENTS'),
  ADDRESS_COPY_FROM_REGISTERED: Symbol('ADDRESS_COPY_FROM_REGISTERED'),
  COPY_CONTACT_PERSON: Symbol('COPY_CONTACT_PERSON'),
  COPY_CONTACT_EMAIL_PHONE: Symbol('COPY_CONTACT_EMAIL_PHONE'),
  ADDRESS_COPY_FROM_FACTUAL: Symbol('ADDRESS_COPY_FROM_FACTUAL'),
  PASSPORT_IMAGE: Symbol('PASSPORT_IMAGE'),
  PASSPORT_IMAGE_RESET: Symbol('PASSPORT_IMAGE_RESET'),
  PORTFOLIO_IMAGE: Symbol('PORTFOLIO_IMAGE'),
  PORTFOLIO_IMAGE_RESET: Symbol('PORTFOLIO_IMAGE_RESET'),
  MOBILE_PHONE_NUMBERS: Symbol('MOBILE_PHONE_NUMBERS'),
  ADDITIONAL_PHONE_NUMBERS: Symbol('ADDITIONAL_PHONE_NUMBERS'),
  ADDRESS_REGISTERED_PHONES: Symbol('ADDRESS_REGISTERED_PHONES'),
  ADDITIONAL_PHONE: Symbol('ADDITIONAL_PHONE'),
  ADDRESS_FACTUAL_PHONES: Symbol('ADDRESS_FACTUAL_PHONES'),
  WORK_PHONE_NUMBERS: Symbol('WORK_PHONE_NUMBERS'),
  EMAILS: Symbol('EMAILS'),
  SOCIAL_LINK: Symbol('SOCIAL_LINK'),
  ADDITIONAL_LINKS_DESCRIPTION: Symbol('ADDITIONAL_LINKS_DESCRIPTION'),
  JOB_OR_STUDY: Symbol('JOB_OR_STUDY'),
  OCCUPATION_INSTITUTION: Symbol('OCCUPATION_INSTITUTION'),
  OCCUPATION_POSITION: Symbol('OCCUPATION_POSITION'),
  MONTHLY_INCOME: Symbol('MONTHLY_INCOME'),
  IS_STUDENT: Symbol('IS_STUDENT'),
  COLLEGE_NAME: Symbol('COLLEGE_NAME'),
  COLLEGE_PROFESSION: Symbol('COLLEGE_PROFESSION'),
  COLLEGE_STARTED_AT: Symbol('COLLEGE_STARTED_AT'),
  COLLEGE_ENDED_AT: Symbol('COLLEGE_ENDED_AT'),
  RECOMMENDATIONS: Symbol('RECOMMENDATIONS'),
  REFERENCES: Symbol('REFERENCES'),
  RENT_START_DATE: Symbol('RENT_START_DATE'),
  RENT_END_DATE: Symbol('RENT_START_DATE'),
  GOODS: Symbol('GOODS'),
  VK: Symbol('VK'),
  WHATSAPP: Symbol('WHATSAPP'),
  ODNOKLASSNIKI: Symbol('ODNOKLASSNIKI'),
  FACEBOOK: Symbol('FACEBOOK'),
  TELEGRAM: Symbol('TELEGRAM'),
  YOUTUBE: Symbol('YOUTUBE'),
  VIMEO: Symbol('VIMEO'),
  VIBER: Symbol('VIBER'),
  TWITTER: Symbol('TWITTER'),
  SKYPE: Symbol('SKYPE'),
  INSTAGRAM: Symbol('INSTAGRAM'),
  WORK_WEBSITE: Symbol('WORK_WEBSITE'),
  PERSONAL_WEBSITE: Symbol('PERSONAL_WEBSITE'),
  ADDITIONAL_LINKS: Symbol('ADDITIONAL_LINKS'),
  SUBSCRIBE: Symbol('SUBSCRIBE'),
  FEEDBACK: Symbol('FEEDBACK'),
  AGREE_ON_AGREEMENT: Symbol('AGREE_ON_AGREEMENT'),

  PROFILE_TYPE: Symbol('PROFILE_TYPE'),

  //Fiscal
  COUNTRY_REGISTRATION: Symbol('COUNTRY_REGISTRATION'),
  VAT_CERTIFICATE: Symbol('VAT_CERTIFICATE'),
  CERTIFICATE_REGISTRATION_NUMBER: Symbol('CERTIFICATE_REGISTRATION_NUMBER'),
  REREGISTRATION_CERTIFICATE: Symbol('REREGISTRATION_CERTIFICATE'),
  REREGISTERED_DATE: Symbol('REREGISTERED_DATE'),
  VAT_CERTIFICATE_DATE: Symbol('VAT_CERTIFICATE_DATE'),
  OKED: Symbol('OKED'),
  F_INN: Symbol('F_INN'),
  F_KBe: Symbol('F_KBe'),
  F_KPP: Symbol('F_KPP'),
  F_FiscalType: Symbol('F_FiscalType'),
  SOLE_TRADER_TYPE: Symbol('SOLE_TRADER_TYPE'),
  REGISTRATION_TICKET: Symbol('REGISTRATION_TICKET'),
  SCAN_SOLE_REGISTRATION_TICKET: Symbol('SCAN_SOLE_REGISTRATION_TICKET'),
  SCAN_SOLE_REGISTRATION_TICKET_SECOND: Symbol(
    'SCAN_SOLE_REGISTRATION_TICKET_SECOND'
  ),
  REGISTRATION_NOTICE: Symbol('REGISTRATION_NOTICE'),
  F_FullName: Symbol('F_FullName'),
  NO_FULLNAME: Symbol('NO_FULLNAME'),
  F_ShortName: Symbol('F_ShortName'),
  F_OGRN: Symbol('F_OGRN'),
  F_OGRNIP: Symbol('F_OGRNIP'),
  OKPO: Symbol('OKPO'),
  F_OKATO: Symbol('F_OKATO'),
  F_OKOPF: Symbol('F_OKOPF'),
  F_addressRegistered: Symbol('F_addressRegistered'),
  F_addressFactual: Symbol('F_addressFactual'),
  BANK_DETAILS: Symbol('BANK_DETAILS'),
  // F_BankRS: Symbol('F_BankRS'),
  // F_BankBIK: Symbol('F_BankBIK'),
  // F_BankName: Symbol('F_BankName'),
  // F_BankKS: Symbol('F_BankKS'),
  F_TaxType: Symbol('F_TaxType'),
  F_Website: Symbol('F_Website'),
  F_Contact: Symbol('F_Contact'),
  CONTACT_PERSON_PHONE: Symbol('CONTACT_PERSON_PHONE'),
  CONTACT_PERSON_EMAIL: Symbol('CONTACT_PERSON_EMAIL'),
  CONTACT_PERSON_FIRSTNAME: Symbol('CONTACT_PERSON_FIRSTNAME'),
  CONTACT_PERSON_POSITION: Symbol('CONTACT_PERSON_POSITION'),
  CONTACT_PERSON_LASTNAME: Symbol('CONTACT_PERSON_LASTNAME'),
  CONTACT_PERSON_PATRONYMIC: Symbol('CONTACT_PERSON_PATRONYMIC'),
  // F_WorkPhoneNumber: Symbol('F_WorkPhoneNumber'),
  F_ContactPhoneNumber: Symbol('F_ContactPhoneNumber'),
  // F_Email: Symbol('F_Email'),
  // F_Contact_Email: Symbol('F_Contact_Email'),
  F_ContactsProxie: Symbol('F_ContactsProxie'),

  F_DOC_IMAGE: Symbol('F_DOC_IMAGE'),
  F_OGRN_IMAGE_FILE: Symbol('F_OGRN_IMAGE_FILE'),
  F_OGRNIP_IMAGE_FILE: Symbol('F_OGRNIP_IMAGE_FILE'),
  F_INN_IMAGE_FILE: Symbol('F_INN_IMAGE_FILE'),
  F_CHARTER_IMAGE_FILE: Symbol('F_CHARTER_IMAGE_FILE'),
  F_PROTOCOL_IMAGE_FILE: Symbol('F_PROTOCOL_IMAGE_FILE'),
  F_EGRUL_IMAGE_FILE: Symbol('F_EGRUL_IMAGE_FILE'),
  F_ORDER_IMAGE_FILE: Symbol('F_ORDER_IMAGE_FILE'),
  F_PASSPORT_DIRECTOR_FILE: Symbol('F_PASSPORT_DIRECTOR_FILE'),
  F_RENT_CONTRACT_FILE: Symbol('F_RENT_CONTRACT_FILE'),
  NO_LEASE_AGREEMENT: Symbol('NO_LEASE_AGREEMENT'),
  ADDRESS_REGISTERED_NO_APARTMENTS: Symbol('ADDRESS_REGISTERED_NO_APARTMENTS'),
  ADDRESS_FACTUAL_NO_APARTMENTS: Symbol('ADDRESS_FACTUAL_NO_APARTMENTS'),
  F_DIRECTORS_PHONE: Symbol('F_DIRECTORS_PHONE'),
  CONTACT_PHONE: Symbol('CONTACT_PHONE'),
  CONTACT_PHONE_SECOND: Symbol('CONTACT_PHONE_SECOND'),
  CONTACT_EMAIL: Symbol('CONTACT_EMAIL'),
  PURPOSE_OF_LEASE: Symbol('PURPOSE_OF_LEASE'),
  F_PROXIES_PHONE: Symbol('F_PROXIES_PHONE'),
  F_PROXIES_FULLNAME: Symbol('F_PROXIES_FULLNAME'),
  F_DIRECTORS_FULLNAME: Symbol('F_DIRECTORS_FULLNAME'),
  HEAD_FIRSTNAME: Symbol('HEAD_FIRSTNAME'),
  HEAD_LASTNAME: Symbol('HEAD_LASTNAME'),
  HEAD_PATRONYMIC: Symbol('HEAD_PATRONYMIC'),
  HEAD_FIRSTNAME_GENITIVE: Symbol('HEAD_FIRSTNAME_GENITIVE'),
  HEAD_LASTNAME_GENITIVE: Symbol('HEAD_LASTNAME_GENITIVE'),
  HEAD_PATRONYMIC_GENITIVE: Symbol('HEAD_PATRONYMIC_GENITIVE'),
  FOUNDING_DOCUMENT_IMAGE_FILE: Symbol('FOUNDING_DOCUMENT_IMAGE_FILE'),
  HEADPOSITION: Symbol('HEADPOSITION'),
  HEADPOSITION_GENITIVE: Symbol('HEADPOSITION_GENITIVE'),
  FOUNDING_DOCUMENT: Symbol('FOUNDING_DOCUMENT'),
  F_SCAN_DIRECTORS_PASSPORT: Symbol('F_SCAN_DIRECTORS_PASSPORT'),
  F_SCAN_DIRECTORS_PASSPORT_ADDRESS: Symbol(
    'F_SCAN_DIRECTORS_PASSPORT_ADDRESS'
  ),
  REGISTERED_DATE: Symbol('REGISTERED_DATE'),
  RECEPTION_NOTICE_DATE: Symbol('RECEPTION_NOTICE_DATE'),
  REGISTRATION_NOTICE_DATE: Symbol('REGISTRATION_NOTICE_DATE'),
  STATE_REGISTRATION_CERTIFICATE: Symbol('STATE_REGISTRATION_CERTIFICATE'),
  SCAN_NOTIFICATION_FIRST: Symbol('SCAN_NOTIFICATION_FIRST'),
  SCAN_NOTIFICATION_SECOND: Symbol('SCAN_NOTIFICATION_SECOND'),
  F_IP_FULLNAME: Symbol('F_IP_FULLNAME'),
  F_BOOKER: Symbol('F_BOOKER'),
  F_DIRECTORS_EMAIL: Symbol('F_DIRECTORS_EMAIL'),
  F_DOC_IMAGE_RESET: Symbol('F_DOC_IMAGE_RESET'),
  F_PROXIES_PASSPORT_IMAGEFILE: Symbol('F_PROXIES_PASSPORT_IMAGEFILE'),
  F_PROXIES_ORDER_IMAGEFILE: Symbol('F_PROXIES_ORDER_IMAGEFILE'),
  ADDRESS_FACTUAL_POSTCODE: Symbol('ADDRESS_FACTUAL_POSTCODE'),
  ADDRESS_REGISTERED_POSTCODE: Symbol('ADDRESS_REGISTERED_POSTCODE'),

  F_Directors_Email: Symbol('F_Directors_Email'),

  F_PROXIES: Symbol('F_PROXIES'),
  F_ProxiesOn: Symbol('F_ProxiesOn'),

  Confirm_phone: Symbol('Confirm_phone'),
  Confirm_phone_ok: Symbol('Confirm_phone_ok'),
  Confirm_mail: Symbol('Confirm_mail'),
  Confirm_mail_ok: Symbol('Confirm_mail_ok'),
}

/* THE REDUCER */
/* Action interface definition is just next to this reducer */
export function reducer(state = INITIAL_STATE, action: Action): State {
  switch (action.type) {
    case actions.AGREED_CHECK:
      return { ...state, agreedCheck: action.data as boolean }
    case actions.REPLACE:
      return action.data as State
    case actions.RESET:
      return INITIAL_STATE
    case actions.IFRAME_COUNTRY:
      return { ...state, iframeCountry: action.data as string }
    case actions.CURRENT_STEP:
      return { ...state, currentStep: action.data as number }
    case actions.FORM_ID:
      return { ...state, formId: action.data as string }
    case actions.USER_ID:
      return { ...state, userId: action.data as string }
    case actions.ORDER_ID:
      return { ...state, orderId: action.data as string }
    case actions.AGREED:
      return { ...state, agreed: action.data as boolean }
    case actions.PASSPORT_TYPE:
      return { ...state, passportType: action.data as string }
    case actions.CITIZENSHIP:
      return { ...state, citizenship: action.data as string }
    case actions.MAIN_CITY:
      return { ...state, mainCity: action.data as string }
    case actions.LOCALE:
      return { ...state, locale: action.data as string }
    case actions.SEX:
      return { ...state, sex: action.data as string }
    case actions.FAMILY_STATUS:
      return { ...state, familyStatus: action.data as string }
    case actions.FIRST_NAME:
      return { ...state, firstName: action.data as string }
    case actions.LAST_NAME:
      return { ...state, lastName: action.data as string }
    case actions.PATRONYMIC:
      return { ...state, patronymic: action.data as string }
    case actions.LATIN_FIRST_NAME:
      return { ...state, latinFirstname: action.data as string }
    case actions.LATIN_LAST_NAME:
      return { ...state, latinLastname: action.data as string }
    case actions.LATIN_PATRONYMIC:
      return { ...state, latinPatronymic: action.data as string }
    case actions.NO_PATRONYMIC:
      return { ...state, noPatronymic: action.data as boolean }
    case actions.NO_PATRONYMIC_CONTACT:
      return { ...state, noPatronymicContact: action.data as boolean }
    case actions.IS_PHONE_OK:
      return { ...state, isPhoneOk: action.data as boolean }
    case actions.IS_EMAIL_OK:
      return { ...state, isEmailOk: action.data as boolean }
    case actions.BIRTH_DATE:
      return { ...state, birthDate: action.data as string }
    case actions.BIRTH_PLACE:
      return { ...state, birthPlace: action.data as string }
    case actions.PASSPORT_IAT:
      return { ...state, passportIAt: action.data as string }
    case actions.PASSPORT_EXPIRY_DATE:
      return { ...state, passportExpiryDate: action.data as string }
    case actions.PASSPORT_SERIES:
      return { ...state, passportSeries: action.data as string }
    case actions.PASSPORT_NUMBER:
      return { ...state, passportNumber: action.data as string }
    case actions.HEAD_PROXY_NUMBER:
      return { ...state, headProxyNumber: action.data as string }
    case actions.HEAD_PROXY_START:
      return { ...state, headProxyStart: action.data as string }
    case actions.HEAD_PROXY_END:
      return { ...state, headProxyEnd: action.data as string }
    case actions.PASSPORT_PERSON_NUMBER:
      return { ...state, passportPersonNumber: action.data as string }
    case actions.PASSPORT_CARD_NUMBER:
      return { ...state, passportCardNumber: action.data as string }
    case actions.PASSPORT_FIRST_NATIONALITY:
      return { ...state, passportFirstNationality: action.data as string }
    case actions.PASSPORT_ISSUED_BY:
      return { ...state, passportIssuedBy: action.data as string }
    case actions.PASSPORT_AUTHORITY_CODE:
      return { ...state, passportAuthorityCode: action.data as string }
    case actions.PASSPORT_INTERNATIONAL_NUMBER:
      return { ...state, passportInternationalNumber: action.data as string }
    case actions.PASSPORT_PERMANENT_RESIDENCY_NUMBER:
      return {...state, passportPermanentResidencyNumber: action.data as string }
    case actions.PASSPORT_DATE_BOARD_CROSSING:
      return { ...state, passportDateBoardCrossing: action.data as string }
    case actions.PASSPORT_DATE_BOARD_CROSSING_CHECK:
      return { ...state, passportDateBoardCheck: action.data as boolean }
    case actions.PASSPORT_DATE_ISSUE_PERMANENT_RESIDENCY:
      return {
        ...state,
        passportDateIssuePermanentResidency: action.data as string,
      }
    case actions.PASSPORT_PERMANENT_RESIDENCY:
      return { ...state, passportIsPermanentResidency: action.data as boolean }
    case actions.MAIN_IMAGE_FILE:
      return {
        ...state,
        mainImageFile: [action.data as string],
      }
    case actions.PERSON_IMAGE_FILE:
      return {
        ...state,
        personImageFile: [...state.personImageFile, action.data as string],
      }
    case actions.RESIDENCE_IMAGE_FILE:
      return {
        ...state,
        residenceImageFile: [
          ...state.residenceImageFile,
          action.data as string,
        ],
      }
    case actions.DOCUMENTS_PHOTO_ONE:
      return {
        ...state,
        documentsPhotoOne: [...state.documentsPhotoOne, action.data as string],
      }
    case actions.DOCUMENTS_PHOTO_TWO:
      return {
        ...state,
        documentsPhotoTwo: [...state.documentsPhotoTwo, action.data as string],
      }
    case actions.ADDRESS_IMAGE_FILE:
      return {
        ...state,
        addressImageFile: [action.data as string],
      }
    case actions.VISA_IMAGE_FILE:
      return {
        ...state,
        visaImageFile: [action.data as string],
      }
    case actions.ADDITIONAL_DOCUMENT_ONE:
      return { ...state, additionalDocumentType: action.data as string }
    case actions.SECOND_ADDITIONAL_DOCUMENT_TYPE:
      return { ...state, secondAdditionalDocumentType: action.data as string }
    case actions.ADDITIONAL_DOCUMENT_DATE_EXPIRY:
      return { ...state, additionalDocumentDateExpiry: action.data as string }
    case actions.SECOND_ADDITIONAL_DOCUMENT_DATE_EXPIRY:
      return {
        ...state,
        secondAdditionalDocumentDateExpiry: action.data as string,
      }
    case actions.COMMENTS:
      return { ...state, comments: action.data as string }
    case actions.ADDITIONAL_DOCUMENT_COMMENT:
      return { ...state, additionalDocumentComment: action.data as string }
    case actions.SECOND_ADDITIONAL_DOCUMENT_COMMENT:
      return {
        ...state,
        secondAdditionalDocumentComment: action.data as string,
      }
    case actions.ACTUAL_ADDRESS:
      return { ...state, addressFactualAddressInRow: action.data as string }
    case actions.ADDRESS_REGISTERED_DATE:
      return { ...state, addressRegisteredDate: action.data as string }
    case actions.STATE_REGISTRATION_CERTIFICATE:
      return {
        ...state,
        stateRegistrationCertificate: [
          ...state.stateRegistrationCertificate,
          action.data as string,
        ],
      }
    case actions.SCAN_NOTIFICATION_FIRST:
      return {
        ...state,
        scanOfNotificationFirst: [
          ...state.scanOfNotificationFirst,
          action.data as string,
        ],
      }
    case actions.SCAN_NOTIFICATION_SECOND:
      return {
        ...state,
        scanOfNotificationSecond: [
          ...state.scanOfNotificationSecond,
          action.data as string,
        ],
      }
    case actions.SCAN_SOLE_REGISTRATION_TICKET:
      return {
        ...state,
        scanSoleRegistrationTicket: [
          ...state.scanSoleRegistrationTicket,
          action.data as string,
        ],
      }
    case actions.SCAN_SOLE_REGISTRATION_TICKET_SECOND:
      return {
        ...state,
        scanSoleRegistrationTicketSecond: [
          ...state.scanSoleRegistrationTicketSecond,
          action.data as string,
        ],
      }
    case actions.ADDRESS_REGISTERED_COUNTRY:
      return { ...state, addressRegisteredCountry: action.data as string }
    case actions.ADDRESS_REGISTERED_REGION:
      return { ...state, addressRegisteredRegion: action.data as string }
    case actions.ADDRESS_REGISTERED_CITY:
      return { ...state, addressRegisteredCity: action.data as string }
    case actions.ADDRESS_REGISTERED_STREET:
      return { ...state, addressRegisteredStreet: action.data as string }
    case actions.ADDRESS_IN_ROW:
      return { ...state, addressRegisteredAddressInRow: action.data as string }
    case actions.ADDRESS_REGISTERED_BUILDING:
      return { ...state, addressRegisteredBuilding: action.data as string }
    case actions.ADDRESS_REGISTERED_APARTMENTS:
      return { ...state, addressRegisteredApartments: action.data as string }
    case actions.ADDRESS_FACTUAL_COUNTRY:
      return { ...state, addressFactualCountry: action.data as string }
    case actions.ADDRESS_FACTUAL_REGION:
      return { ...state, addressFactualRegion: action.data as string }
    case actions.ADDRESS_FACTUAL_CITY:
      return { ...state, addressFactualCity: action.data as string }
    case actions.ADDRESS_FACTUAL_STREET:
      return { ...state, addressFactualStreet: action.data as string }
    case actions.ADDRESS_FACTUAL_BUILDING:
      return { ...state, addressFactualBuilding: action.data as string }
    case actions.ADDRESS_FACTUAL_APARTMENTS:
      return { ...state, addressFactualApartments: action.data as string }
    case actions.PASSPORT_IMAGE:
      return {
        ...state,
        passportImages: [action.data as string],
      }
    case actions.PASSPORT_IMAGE_RESET:
      return {
        ...state,
        passportImages: [...INITIAL_STATE.passportImages],
      }
    case actions.PORTFOLIO_IMAGE:
      return {
        ...state,
        portfolioImages: [...state.portfolioImages, action.data as string],
      }
    case actions.PORTFOLIO_IMAGE_RESET:
      return {
        ...state,
        portfolioImages: [...INITIAL_STATE.portfolioImages],
      }
    case actions.ADDRESS_COPY_FROM_FACTUAL:
      return copyFromFactual(state)
    case actions.ADDRESS_COPY_FROM_REGISTERED:
      return copyFromRegistered(state)
    case actions.COPY_CONTACT_PERSON:
      return copyContactPerson(state)
    case actions.COPY_CONTACT_EMAIL_PHONE:
      return copyContactPersonEmailPhone(state)
    case actions.ADDRESS_REGISTERED_PHONES:
      return { ...state, addressRegisteredPhones: action.data as string[] }
    case actions.ADDITIONAL_PHONE:
      return { ...state, additionalPhones: action.data as string[] }
    case actions.ADDRESS_FACTUAL_PHONES:
      return { ...state, addressFactualPhones: action.data as string[] }
    case actions.MOBILE_PHONE_NUMBERS:
      return { ...state, mobilePhoneNumbers: action.data as string[] }
    case actions.WORK_PHONE_NUMBERS:
      return { ...state, workPhoneNumbers: action.data as string[] }
    case actions.EMAILS:
      return { ...state, emails: action.data as string[] }
    case actions.SOCIAL_LINK:
      return { ...state, socialLink: action.data as string }
    case actions.ADDITIONAL_LINKS_DESCRIPTION:
      return { ...state, additionalLinksDescription: action.data as string[] }
    case actions.JOB_OR_STUDY:
      return { ...state, jobOrStudy: action.data as string }
    case actions.OCCUPATION_INSTITUTION:
      return { ...state, occupationInstitution: action.data as string }
    case actions.OCCUPATION_POSITION:
      return { ...state, occupationPosition: action.data as string }
    case actions.MONTHLY_INCOME:
      return { ...state, monthlyIncome: action.data as string }
    case actions.IS_STUDENT:
      return { ...state, isStudent: action.data as boolean }
    case actions.COLLEGE_NAME:
      return { ...state, collegeName: action.data as string }
    case actions.COLLEGE_PROFESSION:
      return { ...state, collegeProfession: action.data as string }
    case actions.COLLEGE_STARTED_AT:
      return { ...state, collegeStartedAt: action.data as string }
    case actions.COLLEGE_ENDED_AT:
      return { ...state, collegeEndedAt: action.data as string }
    case actions.RECOMMENDATIONS:
      return { ...state, recommendations: action.data as string }
    case actions.REFERENCES:
      return { ...state, references: action.data as string[] }
    case actions.RENT_START_DATE:
      return { ...state, rentStartedAt: action.data as string }
    case actions.RENT_END_DATE:
      return { ...state, rentEndedAt: action.data as string }
    case actions.GOODS:
      return { ...state, goods: action.data as [] }
    case actions.VK:
      return { ...state, vk: action.data as string[] }
    case actions.FACEBOOK:
      return { ...state, facebook: action.data as string[] }
    case actions.ODNOKLASSNIKI:
      return { ...state, odnoklassniki: action.data as string[] }
    case actions.WHATSAPP:
      return { ...state, whatsapp: action.data as string[] }
    case actions.TELEGRAM:
      return { ...state, telegram: action.data as string[] }
    case actions.YOUTUBE:
      return { ...state, youtube: action.data as string[] }
    case actions.VIMEO:
      return { ...state, vimeo: action.data as string[] }
    case actions.VIBER:
      return { ...state, viber: action.data as string[] }
    case actions.TWITTER:
      return { ...state, twitter: action.data as string[] }
    case actions.SKYPE:
      return { ...state, skype: action.data as string[] }
    case actions.INSTAGRAM:
      return { ...state, instagram: action.data as string[] }
    case actions.WORK_WEBSITE:
      return { ...state, workWebsite: action.data as string[] }
    case actions.PERSONAL_WEBSITE:
      return { ...state, personalWebsite: action.data as string[] }
    case actions.ADDITIONAL_LINKS:
      return { ...state, additionalLinks: action.data as string[] }
    case actions.SUBSCRIBE:
      return { ...state, subscribe: action.data as boolean }
    case actions.FEEDBACK:
      return { ...state, feedback: action.data as string }
    case actions.AGREE_ON_AGREEMENT:
      return { ...state, agreedOn: [...state.agreedOn, action.data as string] }
    case actions.PROFILE_TYPE:
      return { ...state, profileType: action.data as string }

    //Fiscal
    case actions.COUNTRY_REGISTRATION:
      return { ...state, countryRegistration: action.data as string }
    case actions.VAT_CERTIFICATE:
      return { ...state, vatCertificate: action.data as string }
    case actions.CERTIFICATE_REGISTRATION_NUMBER:
      return { ...state, certificateRegistrationNumber: action.data as string }
    case actions.REREGISTRATION_CERTIFICATE:
      return { ...state, reregistrationCertificate: action.data as string }
    case actions.REREGISTERED_DATE:
      return { ...state, reregisteredDate: action.data as string }
    case actions.VAT_CERTIFICATE_DATE:
      return { ...state, VatCertificateDate: action.data as string }
    case actions.OKED:
      return { ...state, oKED: action.data as string }
    case actions.F_INN:
      return { ...state, F_INN: action.data as string }
    case actions.F_KBe:
      return { ...state, F_KBe: action.data as string }
    case actions.F_KPP:
      return { ...state, F_KPP: action.data as string }
    case actions.F_FiscalType:
      return { ...state, F_FiscalType: action.data as string }
    case actions.SOLE_TRADER_TYPE:
      return { ...state, soleTraderType: action.data as string }
    case actions.REGISTRATION_TICKET:
      return { ...state, registrationTicket: action.data as string }
    case actions.REGISTRATION_NOTICE:
      return { ...state, registrationNotice: action.data as string }
    case actions.F_FullName:
      return { ...state, F_FullName: action.data as string }
    case actions.NO_FULLNAME:
      return { ...state, noFullName: action.data as boolean }
    case actions.F_ShortName:
      return { ...state, F_ShortName: action.data as string }
    case actions.F_OGRN:
      return { ...state, F_OGRN: action.data as string }
    case actions.F_OGRNIP:
      return { ...state, F_OGRNIP: action.data as string }
    case actions.OKPO:
      return { ...state, oKPO: action.data as string }
    case actions.F_OKATO:
      return { ...state, F_OKATO: action.data as string }
    case actions.F_OKOPF:
      return { ...state, F_OKOPF: action.data as string }
    case actions.F_addressRegistered:
      return { ...state, F_addressRegistered: action.data as string }
    case actions.F_addressFactual:
      return { ...state, F_addressFactual: action.data as string }
    case actions.BANK_DETAILS:
      return { ...state, BankDetails: action.data as [] }
    // case actions.F_BankRS:
    // return { ...state, F_BankRS: action.data as string }
    // case actions.F_BankBIK:
    // return { ...state, F_BankBIK: action.data as string }
    // case actions.F_BankName:
    // return { ...state, F_BankName: action.data as string }
    // case actions.F_BankKS:
    // return { ...state, F_BankKS: action.data as string }
    case actions.F_TaxType:
      return { ...state, F_TaxType: action.data as string }
    case actions.F_Website:
      return { ...state, F_Website: action.data as string }
    case actions.F_Contact:
      return { ...state, F_Contact: action.data as string }
    case actions.CONTACT_PERSON_PHONE:
      return { ...state, contactPersonPhones: action.data as string[] }
    case actions.CONTACT_PERSON_EMAIL:
      return { ...state, contactPersonEmails: action.data as string[] }
    case actions.CONTACT_PERSON_POSITION:
      return { ...state, contactPersonPosition: action.data as string }
    case actions.CONTACT_PERSON_FIRSTNAME:
      return { ...state, contactPersonFirstname: action.data as string }
    case actions.CONTACT_PERSON_LASTNAME:
      return { ...state, contactPersonLastname: action.data as string }
    case actions.CONTACT_PERSON_PATRONYMIC:
      return { ...state, contactPersonPatronymic: action.data as string }
    // case actions.F_WorkPhoneNumber:
    //   return { ...state, F_WorkPhoneNumber: action.data as string[] }
    case actions.F_ContactPhoneNumber:
      return { ...state, F_ContactPhoneNumber: action.data as string[] }
    // case actions.F_Email:
    // return { ...state, F_Email: action.data as string[] }
    // case actions.F_Contact_Email:
    // return { ...state, F_Contact_Email: action.data as string[] }

    case actions.F_ContactsProxie:
      return { ...state, F_ContactsProxie: action.data as string }

    case actions.F_DOC_IMAGE:
      return {
        ...state,
        F_DocImages: [...state.F_DocImages, action.data as string],
      }
    case actions.F_OGRN_IMAGE_FILE:
      return {
        ...state,
        F_OGRN_Image: [...state.F_OGRN_Image, action.data as string],
      }
    case actions.F_OGRNIP_IMAGE_FILE:
      return {
        ...state,
        F_OGRNIP_Image: [...state.F_OGRNIP_Image, action.data as string],
      }
    case actions.F_INN_IMAGE_FILE:
      return {
        ...state,
        F_INN_Image: [...state.F_INN_Image, action.data as string],
      }
    case actions.F_CHARTER_IMAGE_FILE:
      return {
        ...state,
        F_Charter_Image: [...state.F_Charter_Image, action.data as string],
      }
    case actions.F_PROTOCOL_IMAGE_FILE:
      return {
        ...state,
        F_Protocol_Image: [...state.F_Protocol_Image, action.data as string],
      }
    case actions.F_EGRUL_IMAGE_FILE:
      return {
        ...state,
        F_EGRUL_Image: [...state.F_EGRUL_Image, action.data as string],
      }
    case actions.F_ORDER_IMAGE_FILE:
      return {
        ...state,
        F_Order_Image: [...state.F_Order_Image, action.data as string],
      }
    case actions.F_PASSPORT_DIRECTOR_FILE:
      return {
        ...state,
        F_Passport_Director_Image: [
          ...state.F_Passport_Director_Image,
          action.data as string,
        ],
      }
    case actions.F_RENT_CONTRACT_FILE:
      return {
        ...state,
        F_Rent_Contract_Image: [
          ...state.F_Rent_Contract_Image,
          action.data as string,
        ],
      }
    case actions.NO_LEASE_AGREEMENT:
      return { ...state, noLeaseAgreement: action.data as boolean }
    case actions.ADDRESS_REGISTERED_NO_APARTMENTS:
      return { ...state, addressRegisteredNoApartments: action.data as boolean }
    case actions.ADDRESS_FACTUAL_NO_APARTMENTS:
      return { ...state, addressFactualNoApartments: action.data as boolean }
    case actions.F_DIRECTORS_EMAIL:
      return { ...state, F_Directors_Email: action.data as string[] }
    case actions.F_DIRECTORS_PHONE:
      return { ...state, F_Directors_Phone: action.data as string[] }
    case actions.CONTACT_PHONE:
      return { ...state, contactPhones: action.data as string[] }
    case actions.CONTACT_PHONE_SECOND:
      return { ...state, contactPhoneSeconds: action.data as string[] }
    case actions.CONTACT_EMAIL:
      return { ...state, contactEmails: action.data as string[] }
    case actions.PURPOSE_OF_LEASE:
      return { ...state, purposeOfLease: action.data as string }
    case actions.F_PROXIES_PHONE:
      return { ...state, F_Proxies_Phone: action.data as string[] }
    case actions.F_PROXIES_PASSPORT_IMAGEFILE:
      return { ...state, F_Proxies_Passport_ImageFile: action.data as string[] }
    case actions.F_PROXIES_ORDER_IMAGEFILE:
      return { ...state, F_Proxies_Order_ImageFile: action.data as string[] }
    case actions.ADDRESS_REGISTERED_POSTCODE:
      return { ...state, addressRegisteredPostCode: action.data as string }
    case actions.ADDRESS_FACTUAL_POSTCODE:
      return { ...state, addressFactualPostCode: action.data as string }
    case actions.F_PROXIES_FULLNAME:
      return { ...state, F_Proxies_FullName: action.data as string }
    case actions.F_IP_FULLNAME:
      return { ...state, F_Ip_FullName: action.data as string }
    case actions.F_DIRECTORS_FULLNAME:
      return { ...state, F_Directors_FullName: action.data as string }
    case actions.HEAD_FIRSTNAME:
      return { ...state, headFirstname: action.data as string }
    case actions.HEAD_LASTNAME:
      return { ...state, headLastname: action.data as string }
    case actions.HEAD_PATRONYMIC:
      return { ...state, headPatronymic: action.data as string }
    case actions.HEAD_FIRSTNAME_GENITIVE:
      return { ...state, headFirstnameGenitive: action.data as string }
    case actions.HEAD_LASTNAME_GENITIVE:
      return { ...state, headLastnameGenitive: action.data as string }
    case actions.HEAD_PATRONYMIC_GENITIVE:
      return { ...state, headPatronymicGenitive: action.data as string }
    case actions.F_SCAN_DIRECTORS_PASSPORT:
      return {
        ...state,
        F_Scan_Directors_Passport: [
          ...state.F_Scan_Directors_Passport,
          action.data as string,
        ],
      }
    case actions.F_SCAN_DIRECTORS_PASSPORT_ADDRESS:
      return {
        ...state,
        F_Scan_Directors_Passport_Address: [
          ...state.F_Scan_Directors_Passport_Address,
          action.data as string,
        ],
      }
    case actions.REGISTERED_DATE:
      return { ...state, registeredDate: action.data as string }
    case actions.REGISTRATION_NOTICE_DATE:
      return {
        ...state,
        registrationNoticeDate: action.data as string,
      }
    case actions.RECEPTION_NOTICE_DATE:
      return {
        ...state,
        receptionNoticeDate: action.data as string,
      }
    case actions.HEADPOSITION:
      return { ...state, headPosition: action.data as string }
    case actions.HEADPOSITION_GENITIVE:
      return { ...state, headPositionGenitive: action.data as string }
    case actions.FOUNDING_DOCUMENT:
      return { ...state, foundingDocument: action.data as string }
    case actions.FOUNDING_DOCUMENT_IMAGE_FILE:
      return {
        ...state,
        foundingDocument_Image: [
          ...state.foundingDocument_Image,
          action.data as string,
        ],
      }
    case actions.F_BOOKER:
      return { ...state, F_Booker: action.data as string }

    case actions.F_DOC_IMAGE_RESET:
      return {
        ...state,
        F_DocImages: [...INITIAL_STATE.F_DocImages],
      }

    case actions.F_PROXIES:
      return { ...state, F_Proxies: action.data as Proxies[] }

    case actions.F_ProxiesOn:
      return { ...state, F_ProxiesOn: action.data as boolean }

    case actions.Confirm_phone:
      return { ...state, Confirm_phone: action.data as string }
    case actions.Confirm_phone_ok:
      return { ...state, Confirm_phone_ok: action.data as boolean }
    case actions.Confirm_mail:
      return { ...state, Confirm_mail: action.data as string }
    case actions.Confirm_mail_ok:
      return { ...state, Confirm_mail_ok: action.data as boolean }

    default:
      return state
  }
}

export interface Action {
  type: symbol
  data:
    | boolean
    | string
    | string[]
    | State
    | Recommendation[]
    | Proxies[]
    | number
    | object[]
}

export interface Dispatch {
  (action: Action): void
}

export function getInitialState(localStorage: Storage): State {
  const savedState = loadState(INITIAL_STATE, localStorage)
  const weekAgo = daysAgo(7)
  // Earlier than
  if (new Date(savedState.createdAt) < weekAgo) {
    resetLocalStorage(localStorage)
    return INITIAL_STATE
  }
  return {
    ...savedState,
    currentStep: 0,
  }
}
