import React, { useEffect, useRef, useState, useMemo } from 'react'

import TextInput from '../../../../components/Inputs/TextInput'
import SelectInput from '../../../../components/Inputs/SelectInput'
import FileInput from '../../../../components/Inputs/FileInput'
import { UPLOADS_ENDPOINT } from '../../../../server-parameters'
import {
  validateName,
  stringDigitsLength,
  validateString,
  smartValidateString,
  validateDate,
} from '../../../../validate/validators'
import { Country, ScreenProps } from '../../../../types/common-types'
import { actions } from '../../../../state-actions-reducers'
import warningImage from '../../../../images/warning.svg'
import DatePicker from '../../../../components/Inputs/DatePicker'
import { getCompany } from '../../../../hooks/useCompanyCustomHook'
import styles from '../../FiscalScreenOne.module.scss'
import AddressInputs from '../../AddressInputs/AddressInputs'
import { getFiscalFullName } from '../../../../utils/TranslateFiscalKzt/getFiscalFullName'
import { getFiscalShortName } from '../../../../utils/TranslateFiscalKzt/getFiscalShortName'
import { getCertificateRegistrationDate } from '../../../../utils/TranslateFiscalKzt/getCertificateRegistrationDate'
import { getCertificateRegistrationImage } from '../../../../utils/TranslateFiscalKzt/getCertificateRegistrationImage'
import { foundingDocumentOptions } from '../../../../content/foundingDocument'
import { kzCity } from '../../../../content/cities'
import { yearsAgo } from '../../../../utils/helpers'

interface Props extends ScreenProps {
  citizenshipCountriesList: Country[]
  allFiscalCountry: Country[]
  countriesList: Country[]
  addressCountries: Country[]
  regionCity: any
  regionCityRegistered: any
  regionCityFactual: any
}

/* REACT COMPONENT */
function CompanyScreenOne({
  lang,
  state,
  dispatch,
  accountForm,
  allFiscalCountry,
  citizenshipCountriesList,
  addressCountries,
  invalidFields,
  regionCityRegistered,
  regionCityFactual,
}: Props) {
  const [focus, setFocus] = useState('')

  async function autoFill() {
    let response = await getCompany(state.F_INN),
      fullName = response.suggestions[0]?.data.name.full_with_opf,
      shortName = response.suggestions[0]?.data.name.short_with_opf,
      ogrn = response.suggestions[0]?.data.ogrn
    if (
      (fullName && !state.F_FullName) ||
      (fullName && state.F_FullName && !validateName(state.F_FullName, false))
    )
      dispatch({ type: actions.F_FullName, data: fullName })
    if (
      (shortName && !state.F_ShortName) ||
      (shortName &&
        state.F_ShortName &&
        !validateName(state.F_ShortName, false))
    )
      dispatch({ type: actions.F_ShortName, data: shortName })
    if (
      (ogrn && !state.F_OGRN) ||
      (ogrn &&
        state.F_OGRN &&
        !stringDigitsLength(
          state.F_OGRN,
          state.F_FiscalType === '1' ? 15 : 13,
          false
        ))
    )
      dispatch({ type: actions.F_OGRN, data: ogrn })
  }
  useEffect(() => {
    if (state.F_INN && stringDigitsLength(state.F_INN, 10, false)) autoFill()
  }, [state.F_INN])

  const fullNameObject = getFiscalFullName(
    state.F_FiscalType,
    state.countryRegistration
  )
  const shortNameObject = getFiscalShortName(
    state.F_FiscalType,
    state.countryRegistration
  )

  useEffect(() => {
    state.mainCity = kzCity[0].value
  }, [lang])

  const certificateRegistrationDate = getCertificateRegistrationDate(
    state.F_FiscalType
  )
  const certificateRegistrationImage = getCertificateRegistrationImage(
    state.F_FiscalType
  )
  const [shortName, setShortName] = useState(shortNameObject)
  const [fullName, setFullName] = useState(fullNameObject)

  useEffect(() => {
    setShortName(shortNameObject)
    setFullName(fullNameObject)
  }, [state.F_FiscalType])
  useEffect(() => {
    dispatch({
      type: actions.F_FiscalType,
      data: '6',
    })
  }, [state.countryRegistration])
  return (
    <>
      <div>
        <div className="form-group">
          <div className="row">
            <div className="col-4 col-xs-12">
              <TextInput
                testName="F_INN"
                testErrorName="error-F_INN"
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.F_BankBIN}
                onChange={F_INN => {
                  dispatch({
                    type: actions.F_INN,
                    data:
                      state.countryRegistration === '398'
                        ? F_INN.replace(/\D/gim, '').substr(0, 12)
                        : F_INN.replace(/\D/gim, '').substr(0, 10),
                  })
                }}
                // onChange={F_INN =>
                //   dispatch({ type: actions.F_INN, data: F_INN })
                // }
                value={state.F_INN || ''}
                required
                valid={
                  stringDigitsLength(
                    state.F_INN,
                    state.countryRegistration === '398' ? 12 : 10,
                    false
                  ) && !invalidFields.includes('F_INN')
                }
                mask={
                  state.countryRegistration === '398'
                    ? '999999999999'
                    : '9999999999'
                }
              />
            </div>
            <div className="col-8 col-xs-12">
              {/* F_FullName */}
              <TextInput
                testName="F_FullName"
                testErrorName="error-F_FullName"
                disabled={!state.agreed}
                lang={lang}
                content={fullName}
                onChange={F_FullName =>
                  dispatch({ type: actions.F_FullName, data: F_FullName })
                }
                value={state.F_FullName || ''}
                valid={
                  state.F_FullName.length > 0
                    ? smartValidateString(state.F_FullName) &&
                      !invalidFields.includes('F_FullName')
                    : !invalidFields.includes('F_FullName')
                }
                required
                onBlur={() => setFocus('')}
                focus={focus === 'F_FullName' || undefined}
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* F_ShortName */}
              <TextInput
                testName="F_ShortName"
                testErrorName="error-F_ShortName"
                disabled={!state.agreed}
                lang={lang}
                content={shortName}
                onChange={F_ShortName =>
                  dispatch({ type: actions.F_ShortName, data: F_ShortName })
                }
                value={state.F_ShortName || ''}
                valid={
                  state.F_ShortName.length > 0
                    ? smartValidateString(state.F_ShortName) &&
                      !invalidFields.includes('F_ShortName')
                    : !invalidFields.includes('F_ShortName')
                }
                required
                onBlur={() => setFocus('')}
                focus={focus === 'F_ShortName' || undefined}
              />
            </div>
            <div className="col-3 col-xs-12">
              <DatePicker
                testName="registeredDate"
                testErrorName="error-registeredDate"
                lang={lang}
                accountForm={certificateRegistrationDate}
                accountFormKey="date"
                onChange={data => {
                  dispatch({
                    type: actions.REGISTERED_DATE,
                    data,
                  })
                }}
                value={state.registeredDate}
                valid={!invalidFields.includes('registeredDate')}
                required
                min={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 67)
                  )
                }
                max={new Date()}
              />
            </div>
            {state.F_FiscalType === '6' || state.F_FiscalType === '3' ? (
              <div className="col-4 col-xs-12">
                <FileInput
                  testName="stateRegistrationCertificate"
                  testErrorName="error-stateRegistrationCertificate"
                  disabled={!state.agreed}
                  lang={lang}
                  url={UPLOADS_ENDPOINT}
                  autorization={state.formId}
                  target={state.documentsImages}
                  content={accountForm.F_RegisteredDocumentScan}
                  onChange={stateRegistrationCertificate => {
                    dispatch({
                      type: actions.STATE_REGISTRATION_CERTIFICATE,
                      data: stateRegistrationCertificate,
                    })
                  }}
                  valid={
                    !invalidFields.includes('stateRegistrationCertificate')
                  }
                  documentType="stateRegistrationCertificate"
                  value={state.stateRegistrationCertificate[0]}
                />
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-6 col-xs-12">
              {/* oKED */}
              <TextInput
                testName="oKED"
                testErrorName="error-oKED"
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.oKED}
                onChange={oKED => {
                  dispatch({
                    type: actions.OKED,
                    data: oKED,
                  })
                }}
                required
                value={state.oKED || ''}
                valid={!invalidFields.includes('oKED')}
                mask="99999"
              />
            </div>
            <div className="col-5 col-xs-12">
              {/* oKPO */}
              <TextInput
                testName="oKPO"
                testErrorName="error-oKPO"
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.oKPO}
                onChange={oKPO => {
                  dispatch({
                    type: actions.OKPO,
                    data: oKPO,
                  })
                }}
                required
                value={state.oKPO || ''}
                valid={!invalidFields.includes('oKPO')}
                mask="99999999"
              />
            </div>
            <div className="col-1 col-xs-12">
              {/* F_KBe */}
              <TextInput
                testName="F_KBe"
                testErrorName="error-F_KBe"
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.F_KBe}
                onKeyDown={(event: any) => {
                  if (
                    state.F_KBe.replace(/_/gim, '').length === 0 &&
                    event.key > 2
                  ) {
                    event.preventDefault()
                  }
                }}
                onChange={F_KBe => {
                  dispatch({
                    type: actions.F_KBe,
                    data: F_KBe,
                  })
                }}
                mask="99"
                value={state.F_KBe}
                valid={!invalidFields.includes('F_KBe')}
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* vatCertificate */}
              <TextInput
                testName="vatCertificate"
                testErrorName="error-vatCertificate"
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.vatCertificate}
                onChange={vatCertificate => {
                  dispatch({
                    type: actions.VAT_CERTIFICATE,
                    data: vatCertificate,
                  })
                }}
                required
                value={state.vatCertificate || ''}
                valid={!invalidFields.includes('vatCertificate')}
                mask="999999999999"
              />
            </div>
            <div className="col-2 col-xs-12">
              <DatePicker
                testName="vatCertificateDate"
                testErrorName="error-vatCertificateDate"
                lang={lang}
                accountForm={accountForm}
                accountFormKey="vatCertificateDate"
                onChange={data => {
                  dispatch({
                    type: actions.VAT_CERTIFICATE_DATE,
                    data,
                  })
                }}
                value={state.vatCertificateDate}
                valid={!invalidFields.includes('vatCertificateDate')}
                required
                min={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 67)
                  )
                }
                max={new Date()}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3 className={styles.header}>
                {accountForm.Fiscal_Current_Leader_Header[lang]}
              </h3>
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                testName="headLastname"
                testErrorName="error-headLastname"
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headLastname}
                onChange={(headLastname: string) => {
                  dispatch({
                    type: actions.HEAD_LASTNAME,
                    data: headLastname,
                  })
                  if (state.copyContactPerson)
                    dispatch({
                      type: actions.CONTACT_PERSON_LASTNAME,
                      data: headLastname,
                    })
                  if (lang === 'eng') {
                    dispatch({
                      type: actions.HEAD_LASTNAME_GENITIVE,
                      data: headLastname,
                    })
                  }
                }}
                value={state.headLastname || ''}
                valid={
                  state.headLastname.length > 0
                    ? smartValidateString(state.headLastname) &&
                      !invalidFields.includes('headLastname')
                    : !invalidFields.includes('headLastname')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                testName="headFirstname"
                testErrorName="error-headFirstname"
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headFirstname}
                onChange={(headFirstname: string) => {
                  dispatch({
                    type: actions.HEAD_FIRSTNAME,
                    data: headFirstname,
                  })
                  if (state.copyContactPerson)
                    dispatch({
                      type: actions.CONTACT_PERSON_FIRSTNAME,
                      data: headFirstname,
                    })
                  if (lang === 'eng') {
                    dispatch({
                      type: actions.HEAD_FIRSTNAME_GENITIVE,
                      data: headFirstname,
                    })
                  }
                }}
                value={state.headFirstname || ''}
                valid={
                  state.headFirstname.length > 0
                    ? smartValidateString(state.headFirstname) &&
                      !invalidFields.includes('headFirstname')
                    : !invalidFields.includes('headFirstname')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                testName="headPatronymic"
                testErrorName="error-headPatronymic"
                testCheckName={'headNoPatronymic'}
                disabled={!state.agreed || state.noPatronymic}
                lang={lang}
                content={accountForm.headPatronymic}
                onChange={(headPatronymic: string) => {
                  dispatch({
                    type: actions.HEAD_PATRONYMIC,
                    data: headPatronymic,
                  })
                  if (state.copyContactPerson)
                    dispatch({
                      type: actions.CONTACT_PERSON_PATRONYMIC,
                      data: headPatronymic,
                    })
                  if (lang === 'eng') {
                    dispatch({
                      type: actions.HEAD_PATRONYMIC_GENITIVE,
                      data: headPatronymic,
                    })
                  }
                }}
                checkLabel={accountForm.noPatronymic[lang]}
                checkValue={state.noPatronymic}
                checkChange={() => {
                  dispatch({
                    type: actions.NO_PATRONYMIC,
                    data: !state.noPatronymic,
                  })
                  dispatch({
                    type: actions.HEAD_PATRONYMIC,
                    data: '',
                  })
                  dispatch({
                    type: actions.HEAD_PATRONYMIC_GENITIVE,
                    data: '',
                  })
                }}
                value={state.headPatronymic || ''}
                valid={
                  state.headPatronymic.length > 0
                    ? smartValidateString(state.headPatronymic) &&
                      !invalidFields.includes('headPatronymic')
                    : !invalidFields.includes('headPatronymic')
                }
              />
            </div>
            {lang !== 'eng' ? (
              <>
                <div className="col-4 col-xs-12">
                  <TextInput
                    testName="headLastnameGenitive"
                    testErrorName="error-headLastnameGenitive"
                    disabled={!state.agreed}
                    lang={lang}
                    content={accountForm.headLastnameGenitive}
                    onChange={(headLastnameGenitive: string) =>
                      dispatch({
                        type: actions.HEAD_LASTNAME_GENITIVE,
                        data: headLastnameGenitive,
                      })
                    }
                    value={state.headLastnameGenitive || ''}
                    valid={
                      state.headLastnameGenitive.length > 0
                        ? smartValidateString(state.headLastnameGenitive) &&
                          !invalidFields.includes('headLastnameGenitive')
                        : !invalidFields.includes('headLastnameGenitive')
                    }
                  />
                </div>
                <div className="col-4 col-xs-12">
                  <TextInput
                    testName="headFirstnameGenitive"
                    testErrorName="error-headFirstnameGenitive"
                    disabled={!state.agreed}
                    lang={lang}
                    content={accountForm.headFirstnameGenitive}
                    onChange={(headFirstnameGenitive: string) =>
                      dispatch({
                        type: actions.HEAD_FIRSTNAME_GENITIVE,
                        data: headFirstnameGenitive,
                      })
                    }
                    value={state.headFirstnameGenitive || ''}
                    valid={
                      state.headFirstnameGenitive.length > 0
                        ? smartValidateString(state.headFirstnameGenitive) &&
                          !invalidFields.includes('headFirstnameGenitive')
                        : !invalidFields.includes('headFirstnameGenitive')
                    }
                  />
                </div>
                <div className="col-4 col-xs-12">
                  <TextInput
                    testName="headPatronymicGenitive"
                    testErrorName="error-headPatronymicGenitive"
                    testCheckName={'headNoPatronymic'}
                    disabled={!state.agreed || state.noPatronymic}
                    lang={lang}
                    content={accountForm.headPatronymicGenitive}
                    onChange={(headPatronymicGenitive: string) =>
                      dispatch({
                        type: actions.HEAD_PATRONYMIC_GENITIVE,
                        data: headPatronymicGenitive,
                      })
                    }
                    checkLabel={accountForm.noPatronymic[lang]}
                    checkValue={state.noPatronymic}
                    checkChange={() => {
                      dispatch({
                        type: actions.NO_PATRONYMIC,
                        data: !state.noPatronymic,
                      })
                      dispatch({
                        type: actions.HEAD_PATRONYMIC,
                        data: '',
                      })
                      dispatch({
                        type: actions.HEAD_PATRONYMIC_GENITIVE,
                        data: '',
                      })
                    }}
                    value={state.headPatronymicGenitive || ''}
                    valid={
                      state.headPatronymicGenitive.length > 0
                        ? smartValidateString(state.headPatronymicGenitive) &&
                          !invalidFields.includes('headPatronymicGenitive')
                        : !invalidFields.includes('headPatronymicGenitive')
                    }
                  />
                </div>
              </>
            ) : null}
            {lang !== 'eng' ? (
              <div className="col-12 col-xs-12">
                <p className={styles.warningMessage}>
                  <img src={warningImage} alt="warningImage"></img>
                  {accountForm.Fiscal_Warning_Genitive[lang]}
                  <span>
                    {' '}
                    {accountForm.Fiscal_Warning_Genitive_Example[lang]}
                  </span>
                </p>
              </div>
            ) : null}
            <div className="col-4 col-xs-12">
              <SelectInput
                testName={'foundingDocument'}
                testErrorName={'error-foundingDocument'}
                lang={lang}
                noOptionsMessage={accountForm.foundingDocument.placeholder}
                content={accountForm.foundingDocument}
                onChange={data => {
                  dispatch({
                    type: actions.FOUNDING_DOCUMENT,
                    data,
                  })
                }}
                value={state.foundingDocument}
                options={foundingDocumentOptions}
                valid={
                  !invalidFields.includes('foundingDocument') &&
                  validateString(state.foundingDocument, 100)
                }
                className={styles.inputl}
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                testName="headPosition"
                testErrorName="error-headPosition"
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headPosition}
                onChange={(headPosition: string) => {
                  dispatch({
                    type: actions.HEADPOSITION,
                    data: headPosition,
                  })
                  if (lang === 'eng') {
                    dispatch({
                      type: actions.HEADPOSITION_GENITIVE,
                      data: headPosition,
                    })
                  }
                }}
                value={state.headPosition || ''}
                valid={
                  state.headPosition.length > 0
                    ? smartValidateString(state.headPosition) &&
                      !invalidFields.includes('headPosition')
                    : !invalidFields.includes('headPosition')
                }
              />
            </div>
            {lang !== 'eng' && (
              <div className="col-4 col-xs-12">
                <TextInput
                  disabled={!state.agreed}
                  lang={lang}
                  content={accountForm.headPositionGenitive}
                  onChange={(headPositionGenitive: string) =>
                    dispatch({
                      type: actions.HEADPOSITION_GENITIVE,
                      data: headPositionGenitive,
                    })
                  }
                  value={state.headPositionGenitive || ''}
                  valid={
                    state.headPositionGenitive.length > 0
                      ? smartValidateString(state.headPositionGenitive) &&
                        !invalidFields.includes('headPositionGenitive')
                      : !invalidFields.includes('headPositionGenitive')
                  }
                />
              </div>
            )}
            { state.foundingDocument === "4" && <>
              <div className="col-4 col-xs-12">
                <TextInput
                  disabled={!state.agreed}
                  lang={lang}
                  content={accountForm.headProxyNumber}
                  onChange={(headProxyNumber: string) =>
                    dispatch({
                      type: actions.HEAD_PROXY_NUMBER,
                      data: headProxyNumber,
                    })
                  }
                  value={state.headProxyNumber || ''}
                  valid={!invalidFields.includes('headProxyNumber')}
                />
              </div>
              <div className="col-4 col-xs-12">
              <DatePicker
                disabled={!state.agreed}
                lang={lang}
                accountForm={accountForm}
                accountFormKey="headProxyStart"
                onChange={headProxyStart =>
                  dispatch({ type: actions.HEAD_PROXY_START, data: headProxyStart })
                }
                value={state.headProxyStart}
                valid={
                  !invalidFields.includes('headProxyStart')
                }
                curr={new Date()}
                min={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 20)
                  )
                }
                max={new Date()}
                required
              />
              </div>
              <div className="col-4 col-xs-12">
                <DatePicker
                  disabled={!state.agreed}
                  lang={lang}
                  accountForm={accountForm}
                  accountFormKey="headProxyEnd"
                  onChange={headProxyEnd =>
                    dispatch({ type: actions.HEAD_PROXY_END, data: headProxyEnd })
                  }
                  value={state.headProxyEnd}
                  valid={
                    !invalidFields.includes('headProxyEnd')
                  }
                  curr={new Date(
                    new Date().setDate(new Date().getDate() + 1)
                  )}
                  min={new Date(
                    new Date().setDate(new Date().getDate() + 1)
                  )}
                  max={new Date(
                    new Date().setFullYear(new Date().getFullYear() + 20)
                  )}
                  required
                />
              </div>
            </> }
            <div className="col-4 col-xs-12">
              <FileInput
                testName="F_Scan_Directors_Passport"
                testErrorName="error-F_Scan_Directors_Passport"
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_Scan_Directors_Passport}
                onChange={data => {
                  dispatch({
                    type: actions.F_SCAN_DIRECTORS_PASSPORT,
                    data: data,
                  })
                }}
                valid={!invalidFields.includes('F_Scan_Directors_Passport')}
                documentType="Passport1"
                value={
                  state.F_Scan_Directors_Passport[
                    state.F_Scan_Directors_Passport.length - 1
                  ]
                }
              />
            </div>
            <div className="col-8 col-xs-12">
              <FileInput
                testName="foundingDocument_Image"
                testErrorName="error-foundingDocument_Image"
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.foundingDocument_Image}
                onChange={data => {
                  dispatch({
                    type: actions.FOUNDING_DOCUMENT_IMAGE_FILE,
                    data: data,
                  })
                }}
                valid={!invalidFields.includes('foundingDocument_Image')}
                documentType="FoundingDocument"
                value={
                  state.foundingDocument_Image[
                    state.foundingDocument_Image.length - 1
                  ]
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <FileInput
                testName="F_Scan_Directors_Passport_Address"
                testErrorName="error-F_Scan_Directors_Passport_Address"
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_Scan_Directors_Passport_Address}
                onChange={data => {
                  dispatch({
                    type: actions.F_SCAN_DIRECTORS_PASSPORT_ADDRESS,
                    data: data,
                  })
                }}
                valid={
                  !invalidFields.includes('F_Scan_Directors_Passport_Address')
                }
                documentType="Passport2"
                value={
                  state.F_Scan_Directors_Passport_Address[
                    state.F_Scan_Directors_Passport_Address.length - 1
                  ]
                }
              />
            </div>
            <div className="col-12 col-xs-12">
              <p className={styles.warningMessage}>
                <img src={warningImage} alt="warningImage"></img>
                {accountForm.Fiscal_Warning_Scan_Directors_Passport[lang]}
              </p>
            </div>
          </div>
        </div>
        <AddressInputs
          lang={lang}
          initialState={state}
          state={state}
          dispatch={dispatch}
          accountForm={accountForm}
          invalidFields={invalidFields}
          addressCountries={addressCountries}
          regionCityRegistered={regionCityRegistered}
          regionCityFactual={regionCityFactual}
        />
      </div>
    </>
  )
}

export default CompanyScreenOne
