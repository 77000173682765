import React from 'react'

import styles from './ProgressBar.module.scss'
import accountForm from '../../content/account-form'
import AccountForm from '../../types/account-form.types'
import { Lang } from '../../types/common-types'
import {
  profileType_Physical,
  profileType_Fiscal,
} from '../../state-actions-reducers'

interface Props {
  step: number
  totalsteps: number
  stepsText: string
  stepsAriaLabel: string
  className?: string
  accountForm: AccountForm
  lang: Lang
  profileType: any
}

const activeColor = '#222222'
const defaultColor = '#B3B3B3'
const activeIndicator = '#FB3B3B'
const defaultIndicator = '#E4E5E7'

const ProgressBar = ({
  step,
  totalsteps,
  stepsText,
  stepsAriaLabel,
  className,
  accountForm,
  lang,
  profileType,
}: Props) => {
  return (
    <div className={styles.ProgressBarBox}>
      <div className={styles.ProgressBar}>
        {profileType == profileType_Fiscal ? (
          <div className={styles.ProgressBarTitles}>
            <h4
              style={
                step >= 1 ? { color: activeColor } : { color: defaultColor }
              }
            >
              {accountForm.stepFiscalTitleOne[lang]}
            </h4>
            <h4
              style={
                step >= 2 ? { color: activeColor } : { color: defaultColor }
              }
            >
              {accountForm.stepFiscalTitleTwo[lang]}
            </h4>
            <h4
              style={
                step >= 3 ? { color: activeColor } : { color: defaultColor }
              }
            >
              {accountForm.stepFiscalTitleThree[lang]}
            </h4>
            {lang === 'rus' && (
              <h4
                style={
                  step >= 4 ? { color: activeColor } : { color: defaultColor }
                }
              >
                {accountForm.stepFiscalTitleFour[lang]}
              </h4>
            )}
          </div>
        ) : (
          <div className={styles.ProgressBarTitles}>
            <h4
              style={
                step >= 1 ? { color: activeColor } : { color: defaultColor }
              }
            >
              {accountForm.stepPassportTitle[lang]}
            </h4>
            <h4
              style={
                step >= 2 ? { color: activeColor } : { color: defaultColor }
              }
            >
              {accountForm.stepContactTitle[lang]}
            </h4>
            {lang === 'rus' && (
              <h4
                style={
                  step >= 3 ? { color: activeColor } : { color: defaultColor }
                }
              >
                {accountForm.stepRentTitle[lang]}
              </h4>
            )}
          </div>
        )}
        {profileType === profileType_Fiscal ? (
          <div className={styles.ProgressBarIndicators}>
            <div>
              <span
                style={
                  step >= 1
                    ? { color: activeIndicator }
                    : { color: defaultIndicator }
                }
              >
                {step >= 2 ? <div></div> : null}
              </span>
              <div
                style={
                  step > 1
                    ? { background: activeIndicator }
                    : { color: defaultIndicator }
                }
              ></div>
            </div>
            <div>
              <span
                style={
                  step >= 2
                    ? { color: activeIndicator }
                    : { color: defaultIndicator }
                }
              >
                {step >= 3 ? <div></div> : null}
              </span>
            </div>
            <div>
              <div
                style={
                  step > 2
                    ? { background: activeIndicator }
                    : { color: defaultIndicator }
                }
              ></div>
            </div>
            <div>
              <span
                style={
                  step >= 3
                    ? { color: activeIndicator }
                    : { color: defaultIndicator }
                }
              >
                {step >= 4 ? <div></div> : null}
              </span>
            </div>
            {lang === 'rus' && (
              <div>
                <div
                  style={
                    step > 3
                      ? { background: activeIndicator }
                      : { color: defaultIndicator }
                  }
                ></div>
                <span
                  style={
                    step >= 4
                      ? { color: activeIndicator }
                      : { color: defaultIndicator }
                  }
                >
                  {step > 4 ? <div></div> : null}
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.ProgressBarIndicators}>
            <div>
              <span
                style={
                  step >= 1
                    ? { color: activeIndicator }
                    : { color: defaultIndicator }
                }
              >
                {step >= 2 ? <div></div> : null}
              </span>
              <div
                style={
                  step > 1
                    ? { background: activeIndicator }
                    : { color: defaultIndicator }
                }
              ></div>
            </div>
            <div>
              <span
                style={
                  step >= 2
                    ? { color: activeIndicator }
                    : { color: defaultIndicator }
                }
              >
                {step >= 3 ? <div></div> : null}
              </span>
            </div>
            {lang === 'rus' && (
              <div>
                <div
                  style={
                    step > 2
                      ? { background: activeIndicator }
                      : { color: defaultIndicator }
                  }
                ></div>
                <span
                  style={
                    step >= 3
                      ? { color: activeIndicator }
                      : { color: defaultIndicator }
                  }
                ></span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProgressBar
