import { useEffect } from 'react'
import nanoid from 'nanoid'

import {
  getSavedUserId,
  getSavedFormId,
  getSavedOrderId,
  saveUserId,
  saveFormId,
  saveOrderId,
} from '../local-storage'
import { request } from '../utils/helpers'
import { IdKind } from '../types/common-types'
import {
  ORDER_TOKEN_ENDPOINT,
  TOKEN_ENDPOINT,
} from '../server-parameters'

// Reques userId or formId from server
// Must provide saved id (as token) if
// it exists. For formId must provide userId
export async function getId(
  kind: IdKind,
  token: string | null,
  userId?: string
): Promise<string> {
  const id = await request(
    TOKEN_ENDPOINT,
    {
      kind,
      userId: userId,
      token,
    },
    'Error while token generation'
  )
  if (!id) {
    return nanoid(64)
  }
  return id
}

export async function getOrderId(): Promise<string> {
  const id = await fetch(ORDER_TOKEN_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
  })
    .then(response => {
      if (response.status === 200) return response
    })
    .catch(e => {
      console.log('errorid', id)
    })
  const { access_token } = await id?.json()
  if (!access_token) {
    return nanoid(64)
  }
  return access_token
}

interface UseTokenCallback {
  ({
    userId,
    formId,
    orderId,
  }: {
    userId: string
    formId: string
    orderId: string
  }): void
}

// Fetch api to get userId and formId.
// Expected callback funtion with will recieve
export async function getUserAndFormId(
  callback: UseTokenCallback,
  localStorage: Storage
) {
  const userId = await getId('userId', getSavedUserId(localStorage))
  const formId = await getId('formId', getSavedFormId(localStorage), userId)
  const orderId = await getOrderId()
  saveUserId(userId, localStorage)
  saveFormId(formId, localStorage)
  saveOrderId(orderId, localStorage)
  callback({ userId, formId, orderId })
}

export function useToken(callback: UseTokenCallback, deps: unknown[] = []) {
  useEffect(() => {
    getUserAndFormId(callback, localStorage)
  }, deps)
}
